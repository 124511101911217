import React,{useState,useEffect} from 'react';
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { toastAlert } from '../../../core/lib/toastAlert';

import { createReserveAuction_cont, updateReserveAuction_cont, chk_setapprovalforall_cont } from '../../../core/contract/index';
import { postMethod } from "../../../core/service/api/common.api";
import apiService from "../../../core/service/v1/";

import ConnectWalletAlert from './ConnectWalletAlert';
import StepsBtn from 'components/separate/StepsBtn';
import * as  Constants from 'core/constant/';

const initialFormValue = {
    price:""
}
const PriceSchema = Yup.object().shape({
    price: Yup
        .string()
        .min(0, 'Price must be greater than zero')
        .required('Price is a required field')
        .test(
            'consent',
            'Price must be greater than zero',
            value => {
                if(value > 0) {
                    return true
                }
                else {
                    return false
                }
            })
});

const CreateReserveModal = (props) => {
    const { item, userSelOwner } = props;

    const dispatch = useDispatch();

    const accountData = useSelector(state => state.account);
    const commonData = useSelector(state => state.common);
    const chainData = useSelector(state => state.chain);

    const [step, step_set] = useState(1);
    const [stepSection, stepSection_set] = useState(false);

    const [valueSubmit, valueSubmit_set] = useState({});
    const [loader, loader_set] = useState(false);

    const approve_click = async () => {
        try{
            let chkData = await chk_setapprovalforall_cont({
                networkVersion: accountData.networkVersion,
                userAddress: accountData.address,
                operator: 'trade',
                approved: true,

                contractSymbol: item.type,

                accountData,
                chainData
            });
    
            if(chkData && chkData.status) {
                step_set(2);
            }
        }catch(err){
            console.log('err : ', err);
        }
    }

    const auction_click = async (values) => {
        try{
            let contPassData = {
                collectionAddr: item.collectionAddr,
                price: values.price,
                tokenId: item.tokenId,
                userAddress: accountData.address,
                contractSymbol: 'trade',
            }

            let transData = {};
            let ReserveAuctionCreated = {};
            let ReserveAuctionUpdated = {};

            loader_set(true);
            if(ownerOfAuction.auctionId) {
                contPassData.auctionId = ownerOfAuction.auctionId;
                transData = await updateReserveAuction_cont(contPassData);
                const {
                    events = {}
                } = transData;
                ReserveAuctionUpdated = (events?.ReserveAuctionUpdated?.returnValues?.auctionId) ? events.ReserveAuctionUpdated.returnValues : {};
            }
            else {
                transData = await createReserveAuction_cont(contPassData);
                const {
                    events = {}
                } = transData;
                ReserveAuctionCreated = (events?.ReserveAuctionCreated?.returnValues?.auctionId) ? events.ReserveAuctionCreated.returnValues : {};
            }
            loader_set(false);

            if(transData && transData.status) {
                if(ownerOfAuction.auctionId || ReserveAuctionUpdated.auctionId) {
                    stepSection_set(false);
                    props.onHide({reloadData:true});
                    props.getInit();
                }
                else {
                    const postData = {
                        apiUrl: apiService.createReserveAuction,
                        payload: {
                            tokenId: item.tokenId,
                            price: values.price,
                            trxId: transData.transactionHash,
                            networkVersion: accountData.networkVersion,
                            receiverAddr: item.collection.address,
                            collectionAddr: item.collection.address,
                            ReserveAuctionCreated: ReserveAuctionCreated,
                            ReserveAuctionUpdated: ReserveAuctionUpdated
                        }
                    };
                    loader_set(true);
                    const resp = await postMethod(postData);
                    loader_set(false);
                    const { status, message } = resp;
                    if(status === 'success') {
                        stepSection_set(false);
                        props.onHide({reloadData:true});
                        props.getInit();
                    }
                    if(message) {
                        toastAlert(status, message, 'userForm');
                    }
                }
            }
            else {
                toastAlert('error', 'Error occured, Please try again.', 'userForm');
            }
        }
        catch(err){
            console.log('createReserveAuction : ', err);
            loader_set(false);
        }
    }

    const formSubmit = async (payload) => {
        try{
            if(accountData.balance === undefined || accountData.balance === 0){
                toastAlert(false,"Insufficient Balance", 'balance');
                return false;
            }
            valueSubmit_set(payload);
            stepSection_set(true);
            step_set(1);
        }catch(err){}
    }

    const {
        blockchain,
        ownerOfAuction = {}
    } = item;

    const {
        auctionDetail = {}
    } = ownerOfAuction;

    useEffect(() => {
        stepSection && approve_click();
    }, [stepSection]);

    useEffect(() => {
        console.log('step : ', step);
        switch (step) {
            case 2:
                auction_click(valueSubmit);
                break;
            case 3:
                props.onHide({reloadData:true});
                props.getInit();
                break;
            default:
                break;
        }
    }, [step]);

    return (
        <Modal
            show={props.show}
            onHide={() => {
                stepSection_set(false);
                props.onHide();
            }}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                {
                !accountData || !accountData.address
                ?
                <ConnectWalletAlert />
                :
                <Formik
                    initialValues={{
                        price: 0,
                        currencySymbol: blockchain && blockchain.nativeCurrencySymbol ? blockchain.nativeCurrencySymbol : ''
                    }}
                    validationSchema={PriceSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        formSubmit(values);
                    }}
                    render ={({
                        values,
                        isSubmitting,
                        handleChange
                    }) =>
                    (
                        stepSection === false ?
                        <Form>
                            <div className="modal-body space-y-20 pd-40">
                                {auctionDetail && auctionDetail.amount ?
                                <>
                                    <h3>Create Reserve Auction</h3>
                                    <p>Set a reserve price for your existing item. Price must be higher than zero.</p>
                                </> :
                                <>
                                    <h3>Update Reserve Auction</h3>
                                    <p>Set a reserve price for your existing auction item. Price must be higher than zero.</p>
                                </>
                                }
                                <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    onChange={handleChange}
                                    autoComplete="off"
                                    placeholder={"00.00 "+values.currencySymbol}
                                />
                                <ErrorMessage name="price" className="error" component="div" />
                                <div className="hr"></div>
                                {auctionDetail.amount &&
                                <div className="d-flex justify-content-between">
                                    <p>Reserve Price :</p>
                                    <p className="text-right price color-popup"> {auctionDetail.amount} {values.currencySymbol}</p>
                                </div>}
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                SUBMIT
                                </button>
                            </div>
                        </Form>
                        :
                        <>
                            <p>{Constants.APPROVE_DESC}</p>
                            <StepsBtn
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => approve_click()}
                                disabled={step!=1 || loader}
                                loader={step===1 && loader}
                                label={Constants.APPROVE_LABEL}
                            />
                            <p>{Constants.AUCTION_DESC}</p>
                            <StepsBtn
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => auction_click(values)}
                                disabled={step!=2 || loader}
                                loader={step===2 && loader}
                                label={Constants.AUCTION_LABEL}
                            />
                        </>
                    )}
                    >
                </Formik>
                }
            </div>
        </Modal>
    );
};

export default CreateReserveModal;

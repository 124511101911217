import React , {useState,useEffect} from 'react';
import { Link } from 'react-router-dom';

import apiService from "../../core/service/v1/";
import { postMethod } from "../../core/service/api/common.api";

import { dateDiff, midEllipsis } from '../../core/lib/JSHelper';

import FileCom from 'components/separate/FileCom';

const ActivityTab2 = (props) => {

    const {
        curTab = '',
        userId = ''
    } = props;

    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [activityList, setActivityList] = useState([]);
    const [activityCount, setActivityCount] = useState([]);

    const [clearAction, setClearAction] = useState(false);
    const [type, setType] = useState(null);

    useEffect(() => {
        if(curTab == 'activity') {
            getActivityList();
        }
    }, [page, curTab, type]);

    async function getActivityList() {
        try {
            const target = curTab === 'activity' ? 'user' : '';
            const targetId = userId;
            const postData = {
                apiUrl: apiService.activitylist,
                payload: type === null ? { page, perPage, target, targetId } : { type, page, perPage, target, targetId }
            };
            const resp = await postMethod(postData);
            if(resp && resp.status && resp.data) {
                if(activityList.length > 0) {
                    let newArr = [...activityList, ...resp.data]; // copying the old datas array
                    setActivityList(newArr);
                } else {
                    setActivityList(resp.data);
                }
                setActivityCount(resp.count);
            }
        } catch(err) {
            console.log('err::', err);
        }
    }

    const [visible, setVisible] = useState(5);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 5);
        setPage(page + 1);
        getActivityList();
    }

    const getMessage = (type, fromAddrObj, toAddrObj) => {
        const { nameShow: fName } = fromAddrObj;
        const fUserLink = "/user/" + fName + "/created";

        let tName = '', tUserLink = '';
        if(toAddrObj) {
            const {nameShow: tNameVal} = toAddrObj;
            tName = tNameVal;
            tUserLink = "/user/" + tName + "/created";
        }

        let msg, icon;
        switch (type) {
            case 'mintNft':
                icon = 'icon-1';
                msg = (
                    <>
                        Token minted by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'burn':
                icon = 'icon-1';
                msg = (
                    <>
                        Token burned by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'like':
                icon = 'icon-1';
                msg = (
                    <>
                        Token like by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'unLike':
                icon = 'icon-1';
                msg = (
                    <>
                        Token unlike by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'putOnSale':
                icon = 'icon-1';
                msg = (
                    <>
                        Token listed by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'changePrice':
                icon = 'icon-2';
                msg = (
                    <>
                        Token relisted by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'cancelOrder':
                icon = 'icon-3';
                msg = (
                    <>
                        Token unlisted by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'transfer':
                icon = 'icon-4';
                msg = (
                    <>
                        Token transfered from <Link to={fUserLink}>{midEllipsis(fName)}</Link> to <Link to={tUserLink}>{midEllipsis(tName)}</Link>
                    </>
                );
                break;
            case 'purchase':
                icon = 'icon-4';
                msg = (
                    <>
                        Token purchased from <Link to={fUserLink}>{midEllipsis(fName)}</Link> to <Link to={tUserLink}>{midEllipsis(tName)}</Link>
                    </>
                );
                break;  
            case 'createReserveAuction':
                icon = 'icon-5';
                msg = (
                    <>
                        Token put in auction by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'updateReserveAuction':
                icon = 'icon-5';
                msg = (
                    <>
                        Auction price updated by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'cancelReserveAuction':
                icon = 'icon-5';
                msg = (
                    <>
                        Auction cancelled by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'finalizeReserveAuction':
                icon = 'icon-5';
                msg = (
                    <>
                        Auction completed by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
        
            default:
                icon = 'icon-5';
                msg = (
                    <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                );
                break;
        }

        return { msg, icon };
    }

    const [dataFilter, setDataFilter] = useState(
        [
            {
                icon: 'icon-fl-sort-filled',
                name: 'Listings',
                active: false,
                filterType: ['putOnSale', 'cancelOrder', 'changePrice']
            },
            {
                icon: 'icon-fl-sort-filled',
                name: 'Mint',
                active: false,
                filterType: ['mintNft']
            },
            {
                icon: 'icon-fl-buy',
                name: 'Purchases',
                active: false,
                filterType: ['transfer']
            },
            {
                icon: 'icon-fl-star',
                name: 'Burns',
                active: false,
                filterType: ['burn']
            },
            {
                icon: 'icon-fl-heart-filled',
                name: 'Like',
                active: false,
                filterType: ['like', 'unLike']
            },
            {
                icon: 'icon-fl-credit-card',
                name: 'Auction',
                active: false,
                filterType: ['createReserveAuction', 'updateReserveAuction', 'cancelReserveAuction', 'finalizeReserveAuction']
            },
            // {
            //     icon: 'icon-fl-discount',
            //     name: 'Sales',
            //     active: false
            // },
            // {
            //     icon: 'icon-fl-buy',
            //     name: 'Purchases',
            //     active: false
            // },
            // {
            //     icon: 'icon-fl-credit-card',
            //     name: 'Bids',
            //     active: false
            // },
            // {
            //     icon: 'icon-fl-users-filled',
            //     name: 'Followings',
            //     active: false
            // },
        ]
    )

    const makeActive = async (index) => {
        let newArr = [...dataFilter]; // copying the old datas array
        newArr.map((ele) => ele.active = false );
        newArr[index].active = !newArr[index].active;
        if(type !== newArr[index].filterType) {
            setDataFilter(newArr);
            setClearAction(true);
            setPage(0);
            setActivityList([]);
            setType(newArr[index].filterType);
        }
        return false;
    }

    const clearFilter = () => {
        let newArr = [...dataFilter]; // copying the old datas array
        newArr.map((ele) => ele.active = false );
        setDataFilter(newArr);
        setClearAction(false);
        setType();
        return false;
    }

    const getTimeMessage = (updatedAt) => {
        const timeDiffObj = dateDiff(updatedAt, new Date());
        return timeDiffObj.value + ' ' + timeDiffObj.type + ' ago';
    }

    return (
        <section className="tf-activity s1 tf-section">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-12">
                        {activityList && activityList.slice(0,visible).map((item,index) => {
                            const { type, status, fromAddrData, userAddressData, nftData, updatedAt, trxId } = item;
                            const { tokenId, collectionAddr, name: nftName, image, blockchain = {}  } = nftData;
                            const { explorerLink = '' } = blockchain;

                            const tokenDetUrl = "/token/"+ collectionAddr + "/" + tokenId;
                            const transactionDetUrl = explorerLink+"/tx/" + trxId;
                            const timeStampMessage = getTimeMessage(updatedAt);
                            const msgObj = getMessage(type, fromAddrData, userAddressData);

                            return <div className="sc-card-activity style1" key={index}>
                                <div className="content">
                                    <div className="media">
                                        <FileCom
                                            src={image}
                                            path={'nft'}
                                        />   
                                    </div>
                                    <div className="infor">
                                        <h3>
                                            <Link to={tokenDetUrl}>{nftName}</Link>
                                        </h3>
                                        <div className="status">
                                            { msgObj.msg }
                                        </div>
                                        <div className="time">
                                            {timeStampMessage}
                                            <a target="_blank" href={transactionDetUrl} rel="noopener noreferrer"> view </a>
                                        </div>
                                    </div>
                                </div>
                                <div className={`button-active icon ${msgObj.icon}`}></div>
                            </div>
                        })}
                        {visible < activityCount && 
                        <div className="col-md-12 wrap-inner load-more text-center"> 
                            <Link 
                                to="#" 
                                id="load-more" 
                                className="sc-button loadmore fl-button pri-3 mt-10" 
                                onClick={showMoreItems}>
                                    <span>Load More</span>
                            </Link>
                        </div>
                        }
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        <div id="side-bar" className="side-bar style-2">
                            {/* <div className="widget widget-search mgbt-24">
                                <form action="#">
                                    <input type="text" placeholder="Enter your word art" required />
                                    <button><i className="icon-fl-search-filled"></i></button>
                                </form>
                            </div> */}
                            <div className="widget widget-filter style-2 mgbt-0">
                                <h3 className="title-widget">Filter</h3>
                                <ul className="box-check">
                                    {
                                        dataFilter && dataFilter.map((item,index) => (
                                            <li key={index}>
                                                <Link to="#" 
                                                    onClick={() => makeActive(index) } 
                                                    className={`box-widget-filter ${item.active ? 'active' : ''}`}>
                                                    <i className={item.icon}></i>{item.name}
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                                { 
                                    clearAction && (
                                        <Link to="#" 
                                            onClick={() => clearFilter() } 
                                            className="clear-check btn-filter style-2">
                                            Clear All Filters
                                        </Link>
                                    )
                                } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ActivityTab2;

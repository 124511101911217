// user_token
export const getAuthToken = () => {
    if (localStorage.getItem('user_token')) {
        return localStorage.getItem('user_token')
    }
    return '';
}

export const setAuthToken = (val) => {
    localStorage.setItem('user_token', val);
    return true
}

export const removeAuthToken = () => {
    localStorage.removeItem('user_token');
}

// walletConn
export const getWalletConn = () => {
    return localStorage.getItem('walletConn');
}

export const setWalletConn = (val) => {
    localStorage.setItem('walletConn', val);
    return true
}

export const removeWalletConn = () => {
    localStorage.removeItem('walletConn');
}
import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { bidCreate } from '../../../core/service/api/nft.api';
import config from '../../../core/config';
import { toastAlert } from '../../../core/lib/toastAlert';
import { tokenPayApprove, readContract, getTokenBalance, placeBid_cont } from '../../../core/contract/index';
import ConnectWalletAlert from './ConnectWalletAlert';

import { midEllipsis } from '../../../core/lib/JSHelper';

const BidModal = (props) => {

    const { item, userSelOwner = {}, ownerOfAuction = {} } = props;

    const {
        blockchain
    } = item;

    const dispatch = useDispatch();

    const accountData = useSelector(state => state.account);
    const commonData = useSelector(state => state.common);
    const chainData = useSelector(state => state.chain);
    const tokenData = useSelector(state => state.token);

    const [youwillpay, setYouwillpay] = useState(0);
    const [tokenBalance, setTokenBalance] = useState({});

    const minimumBid = ownerOfAuction.auctionDetail ? (ownerOfAuction.auctionDetail.amount) : 0;
    const auctionId = ownerOfAuction.auctionId ? ownerOfAuction.auctionId : 0;

    const {
        availQuan = 0,
    } = userSelOwner ? userSelOwner : {};

    const bidSchema = Yup.object().shape({
        biddingAmt: Yup
        .number()
        .min(minimumBid, 'Bid amount must be greater than or equal to '+minimumBid)
        .required('Bid amount is a required field')
        .test(
            'consent',
            'Bid amount must be greater than zero',
            value => {
                if(value > 0) {
                    return true
                }
                else {
                    return false
                }
            }
        ),
        quantity: Yup
        .number()
        .integer()
        .min(1,'Quantity must be greater than zero')
        // .max(availQuan,'Quantity must be less than or equal to '+availQuan)
        .required('Quantity is a required field')
        .test(
            'consent',
            'Quantity must be greater than zero',
            value => {
                if(value > 0) {
                    return true
                }
                else {
                    return false
                }
            }
        ),
    });

    const youwillpay_call = async (values) => {
        let retAmt = 0;
        if(values.biddingAmt && values.quantity && commonData && commonData.fee.buyerServiceFee) {
            retAmt = (
                        (
                            (values.biddingAmt*values.quantity)*
                            (commonData.fee.buyerServiceFee)/100
                        )
                            + 
                        (+values.biddingAmt*values.quantity)
                    ).toFixed(config.fixed.currencyBal)
        }
        setYouwillpay(retAmt)
    }

    const formSubmit = async (payload) => {
        if((accountData && accountData.balance) === 0 || (typeof accountData && accountData.balance=="undefined")){
            toastAlert(false,"Insufficient Balance", 'balance');
            return false;
        }

        const contPassData = {
            auctionId: auctionId,
            value: payload.biddingAmt,
            userAddress: accountData.address,
            contractSymbol: 'trade'
        }

        const placeBid_resp = await placeBid_cont(contPassData);
        if(placeBid_resp.status) {
            var data = {
                networkVersion : accountData.networkVersion,
                biddingAmt: payload.biddingAmt,
                quantity: payload.quantity,
                tokenRef: item._id,
                collectionId: item.collection._id,
                address: accountData.address,
            }
            const { status, message } = await bidCreate(data);
            if ((status === 'success' || status === 'error') && message) {
                toastAlert(status, message, 'userForm');
            }
            if (status === 'success') {
                props.onHide({reloadData:true});
                props.getInit();
            }
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                {
                !accountData || !accountData.address
                ?
                <ConnectWalletAlert />
                :
                <Formik
                    initialValues={{
                        biddingAmt: '',
                        quantity: 1
                    }}
                    
                    validationSchema={bidSchema}
                    validate={(values) => {
                        youwillpay_call(values);
                        const errors = {
                        };
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        formSubmit(values);
                    }}
                    render ={({
                        values,
                        errors,
                        isSubmitting,
                        handleChange
                    }) =>  (
                        <Form>
                            <div className="modal-body space-y-20 pd-40">
                                <h3>Place a Bid</h3>
                                <p className="text-center">
                                    You are about to place a bid for <span className="price color-popup">{item.name}</span> from <span className="price color-popup">{item.collection && midEllipsis(item.collection.name)}</span>
                                </p>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="biddingAmt"
                                    onChange={handleChange}
                                    autoComplete="off" placeholder={"00.00"}
                                />
                                <ErrorMessage name="biddingAmt" className="error" component="div" />

                                {item.type === 1155 && 
                                <div>
                                    <p>Enter quantity. <span className="color-popup">{availQuan} available</span></p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="quantity"
                                        onChange={handleChange}
                                        autoComplete="off"
                                        value={values.quantity}
                                    />
                                </div>
                                }
                                <ErrorMessage name="quantity" className="error" component="div" />
                                <ErrorMessage name="fund" className="error" component="div" />
                                <div className="hr"></div>

                                <div className="d-flex justify-content-between">
                                    <p> You must bid at least:</p>
                                    <p className="text-right price color-popup"> {minimumBid} {blockchain && blockchain.nativeCurrencySymbol}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p> Your balance:</p>
                                    <p className="text-right price color-popup"> {accountData && accountData.balance+' '+accountData.currency} </p>
                                </div>
                                <p className='hrClass' />
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                SUBMIT
                                </button>
                            </div>
                        </Form>
                        )}
                > 
                </Formik>
                }
            </div>
        </Modal>
    );
};

export default BidModal;

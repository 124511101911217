const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/'
    },
    {
        id: 2,
        name: 'Explore',
        links: '/explore'
    },
    {
        id: 3,
        name: 'Activity',
        links: '/activity'
    },
    {
        id: 4,
        name: 'Create Item',
        links: '/create-item/eth-erc-721'
    },
    // {
    //     id: 5,
    //     name: 'Create Item',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Single',
    //             links: '/create-item/eth-erc-721'
    //         },
    //         // {
    //         //     id: 2,
    //         //     sub: 'Multiple',
    //         //     links: '/create-item/eth-erc-1155'
    //         // }
    //     ],
    // },
    // {
    //     id: 6,
    //     name: 'Contact',
    //     links: '/contact-us'
    // }
];

export default menus;
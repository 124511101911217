import React, {
    useEffect,useState
} from 'react';
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';

import {
    getCurContract,
    addrCntChk,
    getIpfs_upload,
    mintByUser,
    chk_setapprovalforall_cont,
    cancelOrder,
    createReserveAuction,
} from "core/contract/index";

import { postMethod } from "core/service/api/common.api";
import apiService from "core/service/v1/";
import { toastAlert } from 'core/lib/toastAlert';

import ConnectWalletAlert from './ConnectWalletAlert';
import StepsBtn from 'components/separate/StepsBtn';
import * as  Constants from 'core/constant/';

const PriceModal = (props) => {

    const { item } = props;
    const accountData = useSelector(state => state.account);

    const [loader, loader_set] = useState(false);
    const [step, step_set] = useState(1);

    const handleSubmit = async() => {
        try{
            if((accountData && accountData.balance) === 0 || (typeof accountData && accountData.balance === "undefined")){
                toastAlert(false,"Insufficient Balance", 'balance');
                return false;
            }
            putonsale_click();
        }catch(err){}
    };

    const putonsale_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                loader_set(true);
                let priceResp = await cancelOrder({
                    collectionAddr: item.collection.address,
                    tokenId: item.tokenId,
                    price: 0,
                    contractSymbol: 'trade',
                    userAddress: accountData.address
                });
                loader_set(false);
                if(priceResp && priceResp.status) {
                    const postData = {
                        apiUrl: apiService.nftpricechange,
                        payload: {
                            collectionAddr: item.collection.address,
                            tokenId: item.tokenId,
                            price: 0,
                            contractSymbol: 'trade',
                            trxId: priceResp.transactionHash,
                            networkVersion: accountData.networkVersion,
                        }
                    };
                    loader_set(true);
                    const resp = await postMethod(postData);
                    loader_set(false);
                    console.log('resp : ', resp);
                    const { status, result, message } = resp;
                    if(status === 'success') {
                        step_set(2);
                        props.onHide({reloadData:true});
                        props.getInit();
                    }
                    if(message) {
                        toastAlert(status, message, 'userForm');
                    }
                }
            } else {
                loader_set(false);
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                <div className="modal-body space-y-20 pd-40">
                    {
                    !accountData || !accountData.address
                    ?
                    <ConnectWalletAlert />
                    :
                    <>
                        <h3>Cancel Order</h3>
                        <p>{Constants.REMOVE_SALE_DESC}</p>
                        <StepsBtn
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleSubmit()}
                            disabled={step!=1 || loader}
                            loader={step===1 && loader}
                            label={Constants.REMOVE_SALE_LABEL}
                        />
                    </>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default PriceModal;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createNFT } from 'core/service/api/nft.api';

import * as  Constants from 'core/constant/';
import {
    addrCntChk,
    getIpfs_upload,
    mintByUser,
    chk_setapprovalforall_cont,
    orderPlace,
    createReserveAuction,
} from "core/contract";

import { postMethod } from "core/service/api/common.api";
import apiService from "core/service/v1/";
import { toastAlert } from 'core/lib/toastAlert';
import StepsBtn from 'components/separate/StepsBtn';

const FILE_SIZE = 30000000;

const CreateitemModal = (props) => {
    const {
        show,
        collectible,
        contractDet = {},
        collectibleUpdateMulti,
        collectibleUpdate,
        errors_set,
        errors,
    } = props;

    const {
        collectionAddr = ''
    } = contractDet;

    let attributes = [];
    collectible.attributes.map((input, index) => {
        if(input.trait_type !== '' && input.value !== '') {
            attributes.push(input);
        }
    })

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // redux
    const accountData = useSelector(state => state.account);
    const commonData = useSelector(state => state.common);
    const chainData = useSelector(state => state.chain);

    const [step, step_set] = useState(1);
    const [mintResp, mintResp_set] = useState({});
    const [loader, loader_set] = useState(false);

    const mediaUpload_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                loader_set(true);
                const ipfsResp = await getIpfs_upload({
                    name: collectible.name,
                    description: collectible.description,
                    price: collectible.price,
                    ipfsimg: collectible.ipfsimg,
                    attributes: attributes
                });
                loader_set(false);

                if(ipfsResp && ipfsResp.errors) {
                    errors_set(ipfsResp.errors);
                } else if(ipfsResp && ipfsResp.ipfshash && ipfsResp.tokenURI) {
                    errors_set({});
                    await collectibleUpdateMulti([
                        {
                            name: 'ipfshash',
                            value: ipfsResp.ipfshash
                        },
                        {
                            name: 'tokenURI',
                            value: ipfsResp.tokenURI
                        }
                    ]);
                    toastAlert('success', 'Upload successfully', 'userForm');
                    step_set(2);
                }
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    const mint_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                let mintData = {
                    name: collectible.name,
                    price: collectible.price,
                    userAddress: accountData.address,
                    ipfshash: collectible.ipfshash,
                    tokenURI: collectible.tokenURI,
                    royalty: collectible.royalty,
                    supply: collectible.supply,
                    contractDet: contractDet
                }
                loader_set(true);
                let mintResp = {};
                if(mintResp.transactionHash) {
                    mintResp = mintResp;
                }
                else {
                    mintResp = await mintByUser(mintData);
                }
                loader_set(false);
                if(mintResp && mintResp.transactionHash) {
                    const {
                        events = {}
                    } = mintResp;
                    mintResp_set(mintResp);

                    const tokenId = (events.Transfer && events.Transfer.returnValues && events.Transfer.returnValues.tokenId) ? parseInt(events.Transfer.returnValues.tokenId) : 0;

                    if(tokenId > 0) {
                        await collectibleUpdate(
                            {
                                name: 'tokenId',
                                value: tokenId
                            }
                        );

                        let data = {
                            name: collectible.name,
                            description: collectible.description,
                            address: accountData.address,
                            image: collectible.ipfshash,
                            tokenURI: collectible.tokenURI,
                            tokenId: tokenId,
                            trxId: mintResp.transactionHash,
                            royalty: collectible.royalty,
                            supply: collectible.supply,
                            method: collectible.method,
                            attributes: attributes,
                            contractDet: contractDet,
                        }

                        loader_set(true);
                        const { status, message } = await createNFT(data);
                        loader_set(false);

                        if ((status === 'success' || status === 'error') && message) {
                            toastAlert(status, message, 'userForm');
                        }
                        if (status === 'success') {
                            step_set(3);
                        }
                    }
                }
                else {
                    toastAlert('success', 'Mint failed, please try again.', 'userForm');
                }
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    const approve_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                loader_set(true);
                let chkData = await chk_setapprovalforall_cont({
                    userAddress: accountData.address,
                    operator: 'trade',
                    approved: true,
                    contractSymbol: contractDet.type,

                    chainData,
                    accountData
                });
                loader_set(false);
        
                if(chkData && chkData.status) {
                    step_set(4);
                }
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    const putonsale_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                loader_set(true);
                let orderPlaceResp = await orderPlace({
                    collectionAddr: contractDet.collectionAddr,
                    tokenId: collectible.tokenId,
                    price: collectible.price,
                    contractSymbol: 'trade',
                    userAddress: accountData.address
                });
                loader_set(false);
                if(orderPlaceResp && orderPlaceResp.status) {
                    const postData = {
                        apiUrl: apiService.nftpricechange,
                        payload: {
                            collectionAddr: contractDet.collectionAddr,
                            tokenId: collectible.tokenId,
                            price: collectible.price,
                            contractSymbol: 'trade',
                            trxId: orderPlaceResp.transactionHash,
                            networkVersion: accountData.networkVersion,
                        }
                    };
                    loader_set(true);
                    const resp = await postMethod(postData);
                    loader_set(false);
                    const { status, result, message } = resp;
                    if(status === 'success') {
                        step_set(5);
                        navigate("/explore", { replace: false });
                    }
                    if(message) {
                        toastAlert(status, message, 'userForm');
                    }
                }
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    const auction_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                loader_set(true);
                let auctionResp = await createReserveAuction({
                    collectionAddr: contractDet.collectionAddr,
                    tokenId: collectible.tokenId,
                    minimumbid: collectible.minimumbid,
                    contractSymbol: 'trade',
                    userAddress: accountData.address
                });
                loader_set(false);
                if(auctionResp && auctionResp.status) {
                    const {
                        events = {}
                    } = auctionResp;
                    const ReserveAuctionCreated = (events?.ReserveAuctionCreated?.returnValues?.auctionId) ? events.ReserveAuctionCreated.returnValues : {};
                    const postData = {
                        apiUrl: apiService.createReserveAuction,
                        payload: {
                            tokenId: collectible.tokenId,
                            price: collectible.minimumbid,
                            networkVersion: accountData.networkVersion,
                            receiverAddr: contractDet.collectionAddr,
                            collectionAddr: contractDet.collectionAddr,
                            trxId: auctionResp.transactionHash,
                            ReserveAuctionCreated: ReserveAuctionCreated
                        }
                    };
                    loader_set(true);
                    const resp = await postMethod(postData);
                    loader_set(false);
                    const { status, result, message } = resp;
                    if(status === 'success') {
                        step_set(5);
                    }
                    if(message) {
                        toastAlert(status, message, 'userForm');
                    }
                }
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    useEffect(() => {
        show && mediaUpload_click();
    }, [show]);

    useEffect(() => {
        switch (step) {
            case 2:
                mint_click();
                break;
            case 3:
                if(collectible.method === "notForSale") {
                    props.onHide();
                    navigate("/token/"+collectionAddr+'/'+collectible.tokenId, { replace: false });
                }
                else {
                    approve_click();
                }
                break;
            case 4:
                if(collectible.method === "fixedPrice") {
                    putonsale_click();
                } else {
                    auction_click();
                }
                break;
            case 5:
                props.onHide();
                navigate("/token/"+collectionAddr+'/'+collectible.tokenId, { replace: false });
                break;
            default:
                break;
        }
    }, [step]);

    return (
        <Modal
            show={props.show}
            onHide={() => {
                props.onHide();
            }}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                <h3>Follow steps</h3>
                <p>{Constants.CREATEITEM_UPLOAD_DESC}</p>
                <p className='error red'>{errors.ipfsimg}</p>
                <StepsBtn
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => mediaUpload_click()}
                    disabled={step!=1 || loader}
                    loader={step===1 && loader}
                    label={Constants.CREATEITEM_UPLOAD_LABEL}
                />

                <p>{Constants.CREATEITEM_MINT_DESC}</p>
                <StepsBtn
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => mint_click()}
                    disabled={step!=2 || loader}
                    loader={step===2 && loader}
                    label={Constants.CREATEITEM_MINT_LABEL}
                />

                {collectible.method !== "notForSale" &&
                <>
                    <p>{Constants.CREATEITEM_APPROVE_DESC}</p>
                    <StepsBtn
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => approve_click()}
                        disabled={step!=3 || loader}
                        loader={step===3 && loader}
                        label={Constants.CREATEITEM_APPROVE_LABEL}
                    />

                    {(collectible.method === "fixedPrice") &&
                    <>
                        <p>{Constants.CREATEITEM_PUTONSALE_DESC}</p>
                        <StepsBtn
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => putonsale_click()}
                            disabled={step!=4 || loader}
                            loader={step===4 && loader}
                            label={Constants.CREATEITEM_PUTONSALE_LABEL}
                        />
                    </>}

                    {(collectible.method === "auction") &&
                    <>
                        <p>{Constants.CREATEITEM_AUCTION_DESC}</p>
                        <StepsBtn
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => auction_click()}
                            disabled={step!=4 || loader}
                            loader={step===4 && loader}
                            label={Constants.CREATEITEM_AUCTION_LABEL}
                        />
                    </>}
                </>}

            </div>
        </Modal>
    );
};

export default CreateitemModal;

import axios from '../axios';
import { toastAlert } from 'core/lib/toastAlert';
import { respChanges, responseChanges } from './responseModify';

export const getMethod = async (data = {}) => {
    try {
        const {
            apiUrl
        }= data;
        let respData = await axios({method: 'get', url: apiUrl});
        return respChanges(respData.data);
    }
    catch (err) {
        return {
            status: 'error',
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const postMethod = async (data = {}) => {
    try {
        const {
            apiUrl,
            payload = {},
            params = {}
        } = data;
        let respData = await axios({method: 'post', url: apiUrl, data: payload, params: params});
        return responseChanges(respData.data);
    }
    catch (err) {
        return {
            status: 'error',
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const request = async (data = {}) => {
    try {
        const { method = 'get', apiUrl = '', payload = {}, options = {} } = data;
        const { toastShow = true } = options;
        let passData = { method: method, url: apiUrl };
        if(method == 'post') { passData.data = payload; } 
        if(data.params) { passData.params = data.params; } 
        let resp = await axios(passData);
        resp = responseChanges(resp.data);
        if(resp.message && toastShow && resp.message !== 'Data retrived successfully') {
            toastAlert(resp.status, resp.message, 'request');
        }
        return resp;
    }
    catch (err) {
        console.log('request err : ', err);
        return {
            status: 'error',
            message: "Something went wrong"
        }
    }
}

import React, { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import logodarkDefault from '../../assets/images/logo/default-logo.png'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { subscribe } from 'core/service/api/all.api';
import account from 'core/redux/reducers/account';

const subscribeSchema = Yup.object().shape({
    email: Yup
        .string()
        .email('Please enter valid email')
        .required('E-Mail field is required'),
});

const accountList = [
    {
        title: "Explore",
        link: "/explore"
    }
];

const resourcesList = [
    {
        title: "FAQ",
        link: "/faq"
    },
    {
        title: "Activity",
        link: "/activity"
    },
];

const companyList = [
];

const socialList = [
    {
        icon: "fab fa-twitter",
        link:"#"
    },
    {
        icon: "fab fa-facebook",
        link: "#"
    },
    {
        icon: "fab fa-youtube",
        link: "#"
    }
];

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);

    const accountData = useSelector(state => state.account);
    const commonData = useSelector(state => state.common);

    const formSubmit = async (payload) => {
        const data = {
            payload: {
                email: payload.email
            }
        };
        await subscribe(data);
    }

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            setIsVisible(true);
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const {
        settingsData = {}
    } = commonData;

    // const {
    //     socialMedia = {},
    //     logodark: logodarkSite = logodarkDefault,
    //     logolight: logolightSite = logodarkDefault,
    // } = settingsData;

    const {
        socialMedia = {},
        logodark: logodarkSite = '',
        logolight: logolightSite = '',
    } = settingsData;

    const {
        facebook: facebookSite = '',
        youtube: youtubeSite = '',
        twitter: twitterSite = ''
    } = socialMedia;

    return (
        <>
            <footer id="footer" className="footer-light-style clearfix bg-style">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-12">
                            <div className="widget widget-logo">
                                <div className="logo-footer w-25" id="logo-footer">
                                    <Link to="/">
                                        { logodarkSite && <img className='logo-dark' src={logodarkSite} srcSet={logolightSite} alt="logo-dark" />}
                                        { logolightSite && <img className='logo-light' src={logolightSite} srcSet={logolightSite} alt="logo-light" />}
                                    </Link>
                                </div>
                                <p className="sub-widget-logo">{commonData.settingsData && commonData.settingsData.description}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-5 col-5">
                            <div className="widget widget-menu style-1">
                                <h5 className="title-widget">Marketplace</h5>
                                <ul>
                                    {
                                        accountData.address && accountData.singleUrl
                                        ? <li><Link to={accountData.singleUrl}>Create</Link></li>
                                        : <li><Link to={'/wallet-connect'}>Create</Link></li>
                                    }
                                    {
                                        accountList.map(({link, title},index) => (
                                            <li key={index}><Link to={link}>{title}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-7 col-7">
                            <div className="widget widget-menu style-2">
                                <h5 className="title-widget">Links</h5>
                                <ul>
                                    {
                                        resourcesList.map((item,index) => (
                                            <li key={index}><Link to={item.link}>{item.title}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-2 col-md-4 col-sm-5 col-5">
                            <div className="widget widget-menu fl-st-3">
                                <h5 className="title-widget">Company</h5>
                                <ul>
                                    {
                                        companyList.map((item,index) =>(
                                            <li key={index}><Link to={item.link}>{item.title}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-3 col-md-6 col-sm-7 col-12">
                            <div className="widget widget-subcribe">
                                <h5 className="title-widget">Subscribe Us</h5>
                                <div className="form-subcribe">
                                <Formik
                                initialValues={{
                                    email:''                            
                                }}
                                validationSchema={subscribeSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    formSubmit(values);  
                                }}
                                render ={({
                                    values,
                                    isSubmitting,
                                    handleChange
                                }) =>  (
                                    <Form>
                                        <input name="email"  className="email" type="text" onChange={handleChange} autoComplete="off" placeholder="info@yourmail.com"/>
                                        <ErrorMessage name="email" className="error" component="div"/>
                                        <button id="submit" name="submit" type="submit"><i className="icon-fl-send"></i></button>
                                    </Form>
                                )}>
                                </Formik>
                                </div>
                                { (facebookSite || youtubeSite || twitterSite) && <div className="widget-social style-1 mg-t32">
                                    <ul>
                                        {facebookSite && <li><a target="_blank" href={facebookSite}><i className={'fab fa-facebook'}></i></a></li>}
                                        {youtubeSite && <li><a target="_blank" href={youtubeSite}><i className={'fab fa-youtube'}></i></a></li>}
                                        {twitterSite && <li><a target="_blank" href={twitterSite}><i className={'fab fa-twitter'}></i></a></li>}
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
            {/* <div className="modal fade popup" id="popup_bid" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body space-y-20 pd-40">
                            <h3>Place a Bid</h3>
                            <p className="text-center">You must bid at least <span className="price color-popup">4.89 ETH</span>
                            </p>
                            <input type="text" className="form-control"
                                placeholder="00.00 ETH" />
                            <p>Enter quantity. <span className="color-popup">5 available</span>
                            </p>
                            <input type="number" className="form-control" placeholder="1" />
                            <div className="hr"></div>
                            <div className="d-flex justify-content-between">
                                <p> You must bid at least:</p>
                                <p className="text-right price color-popup"> 4.89 ETH </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p> Service free:</p>
                                <p className="text-right price color-popup"> 0,89 ETH </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p> Total bid amount:</p>
                                <p className="text-right price color-popup"> 4 ETH </p>
                            </div>
                            <Link to="#" className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Footer;

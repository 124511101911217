import React, {
    useEffect, useState
} from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import config from '../../../core/config';
import { nftPriceChange } from '../../../core/service/api/nft.api';
import ConnectWalletAlert from './ConnectWalletAlert';
import StepsBtn from 'components/separate/StepsBtn';
import * as  Constants from 'core/constant/';

import {
    getCurContract,
    addrCntChk,
    getIpfs_upload,
    mintByUser,
    chk_setapprovalforall_cont,
    changePrice,
    createReserveAuction,
} from "../../../core/contract/index";

import { postMethod } from "../../../core/service/api/common.api";
import apiService from "../../../core/service/v1/";
import { toastAlert } from '../../../core/lib/toastAlert';

// import { Box, Button, Card, Checkbox, Grid, Typography } from "@mui/material";

const initialFormValue = {
    price:""
}
const PriceSchema = Yup.object().shape({
    price: Yup
        .string()
        .min(0, 'Price must be greater than zero')
        .required('Price is a required field')
        .test(
            'consent',
            'Price must be greater than zero',
            value => {
                if(value > 0) {
                    return true
                }
                else {
                    return false
                }
            })
});

const PriceModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { item, userSelOwner, show } = props;
    const accountData = useSelector(state => state.account);
    const chainData = useSelector(state => state.chain);
    const commonData = useSelector(state => state.common);

    const [step, step_set] = useState(1);
    const [stepSection, stepSection_set] = useState(false);
    const [valueSubmit, valueSubmit_set] = useState({});
    const [loader, loader_set] = useState(false);

    const formSubmit = async (payload) => {
        try{
            if(accountData.balance === undefined || accountData.balance === 0){
                toastAlert(false,"Insufficient Balance", 'balance');
                return false;
            }
            valueSubmit_set(payload);
            stepSection_set(true);
        }catch(err){}
    }

    const putonsale_click = async (values) => {
        try {
            if(addrCntChk(accountData)) {
                loader_set(true);
                let priceResp = await changePrice({
                    collectionAddr: item.collection.address,
                    tokenId: item.tokenId,
                    price: values.price,
                    contractSymbol: 'trade',
                    userAddress: accountData.address
                });
                loader_set(false);
                if(priceResp && priceResp.status) {
                    const postData = {
                        apiUrl: apiService.nftpricechange,
                        payload: {
                            collectionAddr: item.collection.address,
                            tokenId: item.tokenId,
                            price: values.price,
                            contractSymbol: 'trade',
                            trxId: priceResp.transactionHash,
                            networkVersion: accountData.networkVersion,
                        }
                    };
                    loader_set(true);
                    const resp = await postMethod(postData);
                    loader_set(false);
                    console.log('resp : ', resp);
                    const { status, result, message } = resp;
                    if(status === 'success') {
                        step_set(3);
                    }
                    if(message) {
                        toastAlert(status, message, 'userForm');
                    }
                }
            } else {
                loader_set(false);
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    const approve_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                loader_set(true);
                let chkData = await chk_setapprovalforall_cont({
                    userAddress: accountData.address,
                    operator: 'trade',
                    approved: true,
                    contractSymbol: item.type,

                    chainData,
                    accountData
                });
                loader_set(false);
        
                if(chkData && chkData.status) {
                    step_set(2);
                }
            } else {
                loader_set(false);
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    useEffect(() => {
        stepSection && approve_click();
    }, [stepSection]);

    useEffect(() => {
        console.log('step : ', step);
        switch (step) {
            case 2:
                putonsale_click(valueSubmit);
                break;
            case 3:
                stepSection_set(false);
                props.onHide({reloadData:true});
                props.getInit();
                break;
            default:
                break;
        }
    }, [step]);

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                {
                !accountData || !accountData.address
                ?
                <ConnectWalletAlert />
                :
                <Formik
                    initialValues={{
                        price: '',
                        currencySymbol: item && item.blockchain && item.blockchain.nativeCurrencySymbol ? item.blockchain.nativeCurrencySymbol : ''
                    }}
                    validationSchema={PriceSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        formSubmit(values);
                    }}
                    render ={({
                        values,
                        isSubmitting,
                        handleChange
                    }) =>
                    (
                        stepSection === false ?
                        <Form>
                            <div className="modal-body space-y-20 pd-40">
                                {userSelOwner && userSelOwner.price === 0
                                ?
                                <>
                                    <h3>Put On Sale</h3>
                                    <p>Enter price. Your NFT will be placed on marketplace</p>
                                </>
                                :
                                userSelOwner && userSelOwner.price > 0 &&
                                <>
                                    <h3>Change Price</h3>
                                    {/* <p>Change price for your existing item. Next price must be less than previous. If you want increase price, you should cancel then create sale with new price</p> */}
                                    <p>Change price for your existing item. Price must be higher than zero.</p>
                                </>
                                }
                                <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    onChange={handleChange}
                                    autoComplete="off"
                                    placeholder={"00.00 "+values.currencySymbol}
                                />
                                <ErrorMessage name="price" className="error" component="div" />
                                <div className="hr"></div>
                                { userSelOwner && userSelOwner.price > 0 &&
                                <div className="d-flex justify-content-between">
                                    <p>Current Price:</p>
                                    <p className="text-right price color-popup"> {userSelOwner.price} {values.currencySymbol} </p>
                                </div>
                                }
                                <div className="d-flex justify-content-between">
                                    <p>Service fee:</p>
                                    <p className="text-right price color-popup"> {commonData && commonData.fee.buyerServiceFee}%</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>You will receive:</p>
                                    <p className="text-right price color-popup"> {((values.price) - (values.price*(commonData && commonData.fee.buyerServiceFee)/100)).toFixed(config.fixed.currencyBal)} {values.currencySymbol} </p>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                SUBMIT
                                </button>
                            </div>
                        </Form>
                        :
                        <div className="modal-body space-y-20 pd-40">
                            <h3>Follow steps</h3>
                            <p>{Constants.APPROVE_DESC}</p>
                            <StepsBtn
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => approve_click()}
                                disabled={step!=1 || loader}
                                loader={step===1 && loader}
                                label={Constants.APPROVE_LABEL}
                            />
                            <p>{Constants.PUTONSALE_DESC}</p>
                            <StepsBtn
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => putonsale_click(values)}
                                disabled={step!=2 || loader}
                                loader={step===2 && loader}
                                label={Constants.PUTONSALE_LABEL}
                            />
                        </div>
                    )}
                > 
                </Formik>
                }
            </div>
        </Modal>
    );
};

export default PriceModal;

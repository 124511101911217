import React , { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import img1 from '../assets/images/icon/connect-1.png'
// import img2 from '../assets/images/icon/connect-2.png'
// import img3 from '../assets/images/icon/connect-3.png'
import img4 from '../assets/images/icon/connect-4.png'
import binanceImg from '../assets/images/icon/binance-connect.png'
// import img5 from '../assets/images/icon/connect-5.png'
// import img6 from '../assets/images/icon/connect-6.png'
// import img7 from '../assets/images/icon/connect-7.png'
// import img8 from '../assets/images/icon/connect-8.png'
  
import { connectUserWallet } from '../core/contract/index';

const WalletConnect = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accountData = useSelector(state => state.account);
    const chainData = useSelector(state => state.chain);
    const commonData = useSelector(state => state.common);

    useEffect(() => {
        console.log('walletChk WalletConnect : ', accountData);
    }, []);

    const [data] = useState(
        [
            {
                img: img1,
                title: 'Meta Mask',
                target: 'Metamask',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
            },
            {
                img: binanceImg,
                title: 'Binance Wallet',
                target: 'Binance Wallet',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
            },
            {
                img: img4,
                title: 'Wallet Connect',
                target: 'Wallet Connect',
                description: 'Metus corrupti itaque reiciendis, provident condimentum, reprehenderit numquam, mi'
            },
            // {
            //     img: img2,
            //     title: 'Bitski',
            //     description: ' Dolor lacinia? Donec nulla, deleniti, dis arcu pharetra maecenas dapibus ante nemo! Wisi?'
            // },
            // {
            //     img: img3,
            //     title: 'Fortmatic',
            //     description: 'Potenti eleifend faucibus quo vero nibh netus suspendisse unde? Consectetuer aspernatur'
            // },
            // {
            //     img: img5,
            //     title: 'Coinbase Wallet',
            //     description: 'Sollicitudin iure conubia vivamus habitasse aptent, eligendi deserunt excepteur tellus non'
            // },
            // {
            //     img: img6,
            //     title: 'Authereum',
            //     description: 'Purus irure lacinia eiusmod inventore bibendum habitant potenti non sint rem! Felis, asper'
            // },
            // {
            //     img: img7,
            //     title: 'Kaikas',
            //     description: 'Varius culpa, aspernatur accusantium? Corporis rhoncus, voluptatibus incididunt, velit '
            // },
            // {
            //     img: img8,
            //     title: 'Torus',
            //     description: ' Soluta fuga nihil, mollitia, ad reprehenderit qui viverra culpa posuere labore inventore'
            // },
        ]
    )

    const handleConnect = async (target) => {
        if(chainData && chainData.data) {
            connectUserWallet(dispatch, {
                target,
                cliMethod: 'manual',
                chainData: chainData
            });
        }
    }

    return (
        <div>
            <Header {...props} />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Connect Wallet</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Pages</Link></li>
                                    <li>Connect Wallet</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-connect-wallet tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-2 mg-bt-12">Connect Your Wallet</h2>
                            <h5 className="sub-title ct style-1 pad-400">Choose one of available wallet providers or create a new wallet.</h5>
                        </div>
                        <div className="col-md-12">
                            <div className="sc-box-icon-inner style-2">
                                {
                                    data.map((item,index) => (
                                        <div key={index} className="sc-box-icon" onClick={() => handleConnect(item.target) }>
                                            <div className="img">
                                                <img src={item.img} alt="NFTs" />
                                            </div>
                                            {/* <h4 className="heading"><Link to="/login">{item.title}</Link> </h4> */}
                                            <h4 className="heading">{item.title}</h4>
                                            {/* <p className="content">{item.description}</p> */}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default WalletConnect;

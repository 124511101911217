import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Explore from '../components/layouts/explore/Explore';
import widgetSidebarData from '../assets/fake-data/data-widget-sidebar';
import PageHeader from '../components/common/PageHeader';
    
const ExploreMain = () => {
    return (
        <div>
            <Header />
            <PageHeader mainTitle="Explore NFTs" subTitle="Explore" />
            <Explore data={widgetSidebarData} />
            <Footer />
        </div>
    );
}

export default ExploreMain;

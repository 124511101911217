import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getSrcPath } from 'core/lib/fileHelper';
import { midEllipsis } from 'core/lib/JSHelper';

const InfoTab = (props) => {

    const { item } = props;
    const {
        blockchain,
        collection,
        attributes = []
    } = item;

    return (<>
        <div className="client-infor sc-card-product">
            {blockchain && blockchain.image && blockchain.nativeCurrencySymbol &&
            <div className="meta-info">
                <div className="author">
                    <div className="avatar">
                        <img src={getSrcPath({src:blockchain.image, path:'blockchain'})} alt="NFTs"/>
                    </div>
                    <div className="info">
                        <span>Blockchain</span>
                        <h6 className="dotString"><Link to={"/explore?chains="+blockchain.nativeCurrencySymbol}>{blockchain.nativeCurrencyName}</Link></h6>
                    </div>
                </div>
            </div>}
            {collection && collection.address &&
            <div className="meta-info">
                <div className="author">
                    <div className="avatar">
                    <img src={getSrcPath({src:item.collection.image, path:'collection'})} alt="collection"/>
                    </div>
                    <div className="info">
                        <span>Collection</span>
                        <h6>{midEllipsis(collection.name)}</h6>
                    </div>
                </div>
            </div>}
        </div>


        {attributes.length > 0 && attributes.map((attri, index) => {
            if(attri) {
                return (
                    <div className="client-infor sc-card-product">
                        <div className="meta-info">
                            <div className="author">
                                <div className="info">
                                    <span>{attri.trait_type}</span>
                                    <h6 className="dotString">{attri.value}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })}
    </>);
}

export default InfoTab;

import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { useSelector } from 'react-redux';

import { getuserProfile } from 'core/service/api/nft.api';
import { toastAlert } from 'core/lib/toastAlert';
import config from 'core/config/';

import apiService from 'core/service/v1/';
import { postMethod } from 'core/service/api/common.api';
import { getSrcPath } from 'core/lib/fileHelper';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import FollowBtn from 'components/common/FollowBtn';
import ItemBox from 'components/layouts/item/ItemBox';
import PageHeader from 'components/common/PageHeader';
import FollowModalTab from 'components/separate/FollowModalTab';
import ActivityTab2 from 'components/separate/ActivityTab2';

import * as Api from "core/service/api/all.api";

const viewUserIntial = {
    addr: '',
    customUrl: ''
};

const Author = (props) => {

    const navigate = useNavigate();

    const { paramsAddress, paramsTab, customUrl } = useParams();
    const [viewUser , viewUser_set] = useState(viewUserIntial);
    const textAreaRef = useRef(null);
    const accountData = useSelector(state => state.account);
    const [userDet, userDet_set] = useState({});
    const [nftListData, set_nftListData] = useState([]);
    const [followList, setFollowList] = useState([]);
    const [showModalName, setShowModalName] = useState('');

    const [curTab, curTab_set] = useState(paramsTab === 'created' || paramsTab === 'owned' || paramsTab === 'onsale' || paramsTab === 'like' ? paramsTab : "created");

    async function accountDataChange() {
        try{
            let viewUserUpd = Object.assign({}, viewUser);
            if(viewUserUpd.addr === '' || viewUserUpd.customUrl === '') {

                if(customUrl === 'user' && paramsAddress === undefined) {
                    navigate("/user/"+paramsTab+"/created", { replace: false });
                }

                if(accountData.address && paramsAddress === undefined && customUrl === undefined) {
                    viewUserUpd.addr = paramsAddress ? paramsAddress : accountData.address;
                } else if(paramsAddress) {
                    viewUserUpd.addr = paramsAddress;
                } else if(customUrl) {
                    viewUserUpd.customUrl = customUrl;
                }

                if(
                    (accountData.address && paramsAddress === undefined && customUrl === undefined)
                    ||
                    (paramsAddress || customUrl)
                ) {
                    viewUser_set(viewUserUpd);
                    getProfile(viewUserUpd);
                }
            }
        }catch(err){
            console.log('err : ', err);
        }
    }

    async function userDetChange() {
        try{
            if(userDet.address) {
                getMyNFTList(curTab);
            }
        }catch(err){
            console.log('err : ', err);
        }
    }

    useEffect(() => {
        if(accountData) {
            accountDataChange(accountData);
        }
    }, [accountData]);

    useEffect(() => {
        if(paramsAddress || paramsTab || customUrl) {
            accountDataChange(accountData);
        }
    }, [paramsAddress, paramsTab, customUrl]);

    useEffect(() => {
        userDetChange();
    }, [userDet]);

    const [menuTab] = useState(
        [
            {
                class: 'active',
                name: 'Created',
                val: 'created'
            },
            {
                class: '',
                name: 'Owned',
                val: 'owned'
            },
            {
                class: '',
                name: 'Onsale',
                val: 'onsale'
            },
            {
                class: '',
                name: 'Like',
                val: 'like'
            },
            {
                class: '',
                name: 'Activity',
                val: 'activity'
            }
        ]
    )

    async function getProfile(viewUserUpd) {
        try{
            let passData = {};
            if(viewUserUpd.addr) {
                passData.addr = viewUserUpd.addr;
            }
            else if(viewUserUpd.customUrl) {
                passData.customUrl = viewUserUpd.customUrl;
            }
            if(passData.addr || passData.customUrl) {
                const { data } = await getuserProfile(passData);
                if(data.address) {
                    userDet_set(data);
                }
            }
        } catch(err){}
    }

    async function getMyNFTList(selTab){
        try {
            if(userDet.address) {
                const postData = {
                    apiUrl: apiService.nftList,
                    payload: {
                        address: userDet.address,
                        tab: selTab,
                    }
                };
                const resp = await postMethod(postData);
                if(resp.status === 1 || resp.status === 'success') {
                    if(resp && resp.data) {
                        set_nftListData(resp.data);
                    }
                }
                if(resp.message) {
                    toastAlert(resp.status, resp.message, 'userForm');
                }
            }
        }catch(err){}
    }
    
    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        toastAlert(true, "Copied!", 'Copied!');
    };

    async function followUser(reqData = {}) {
        try {
            const {
                memberId:targetId
            } = reqData;

            let payload = {
                targetId,
            };
            const postData = {
                apiUrl: apiService.follow,
                payload: payload,
            };
            const { status, message } = await postMethod(postData);
            if(status === 'success' && message) {
                toastAlert(status, message, 'userForm');
                getProfile(viewUser);

                if(showModalName) {
                    showFollowUser({
                        target: showModalName,
                        userId: userDet._id
                    })
                }
            }
        }catch(err){
            console.log('err : ', err);
        }
    }

    async function showFollowUser(data = {}) {
        const {
            target, userId
        } = data;
        const apiData = {
            payload: {
                target, userId
            }
        };
        const {
            data: respData = [], status, error
        } = await Api.followList(apiData);
        const list = respData === null ? [] : respData;
        if(list && list.length > 0) {
            setFollowList(list);
            setShowModalName(target);
        }
    }

    const {
        firstName = '',
        bio = '',
        address = '',
        socialMedia = {},
        followerCount = 0,
        followingCount = 0,
        address : userDetAddress = '',
        image = {}
    } = userDet;
    
    let {
        profileImage = ''
    } = image;

    profileImage = profileImage ? profileImage : config.defaultImage.profileImage;
    
    return (
        <div className='authors-2'>
            <Header />
            <PageHeader mainTitle="Profile" subTitle="Profile" />
            {userDetAddress ?
            <section className="tf-section authors">
                <div className="themesflat-container">
                    <div className="flat-tabs tab-authors">
                        <div className="author-profile flex">
                            <div className="feature-profile">
                                <img src={getSrcPath({src:profileImage, path:'profile'})} alt="avatar" className="avatar" />
                            </div>
                            <div className="infor-profile">
                                <span>User Profile</span>
                                <h2 className="title">{firstName ? firstName : <>&nbsp;</>}</h2>
                                <p className="content">{bio}</p>
                                <form>
                                    <input type="text" className="inputcopy" ref={textAreaRef} defaultValue={address} readOnly />
                                    <button type="button" className="btn-copycode" onClick={copyToClipboard}><i className="icon-fl-file-1"></i></button>
                                </form>
                            </div>
                            <div className="widget-social style-3">
                                <ul>
                                {(socialMedia.facebook) &&
                                    <li><a href={socialMedia.facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                }
                                {(socialMedia.twitter) &&
                                    <li className="style-2"><a href={socialMedia.twitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                                }
                                {(socialMedia.discord) &&
                                    <li><a href={socialMedia.discord} target="_blank" rel="noreferrer"><i className="fab fa-discord"></i></a></li>
                                }
                                </ul>
                                {(userDetAddress) && 
                                    <>
                                        {accountData.address && userDetAddress !== accountData.address &&
                                        <div className="btn-profile">
                                            <FollowBtn
                                                followUser={followUser}
                                                userDet={userDet}
                                                followText = {'Follow'}
                                            />
                                        </div>}
                                        <h5 onClick={() => showFollowUser({target: 'follower', userId: userDet._id})}>Follower : {followerCount}</h5>
                                        <h5 onClick={() => showFollowUser({target: 'following', userId: userDet._id})}>Following : {followingCount}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <Tabs>
                            <TabList>
                                {
                                    menuTab.map((item,index) => (
                                        <Tab key={index} onClick={() => {
                                            set_nftListData([]);
                                            curTab_set(item.val);
                                            console.log('item.val : ', item.val);
                                            if(item.val !== 'activity') {
                                                getMyNFTList(item.val);
                                            }
                                        }}>{item.name}</Tab>
                                    ))
                                }
                            </TabList>

                            <div className="content-tab">
                                <div className="content-inner">
                                    <div className="row">
                                    {
                                        menuTab.map((item, index) => (
                                            <TabPanel key={index}>
                                                {
                                                    curTab === 'activity' ?
                                                    <ActivityTab2
                                                        curTab = {curTab}
                                                        userId = {userDet && userDet._id ? userDet._id : ''}
                                                    /> :
                                                    nftListData.map((item, index) => (
                                                        <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                                            <ItemBox
                                                                item={item}
                                                                nftListData={nftListData}
                                                                set_nftListData={set_nftListData}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </TabPanel>
                                        ))
                                    }
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </section> : 'We can`t find the page that you are looking for'}
            <FollowModalTab
                show={showModalName === 'follower' || showModalName === 'following'}
                showModalName={showModalName}
                onHide={() => setShowModalName('')}
                followList={followList}
                followUser={followUser}
            />
            <Footer />
        </div>
    );
}

export default Author;

import React, {
    useEffect,useState
} from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import config from '../../../core/config';
import { report } from '../../../core/service/api/nft.api';
import { toastAlert } from '../../../core/lib/toastAlert';
import { postMethod } from "../../../core/service/api/common.api";
import apiService from "../../../core/service/v1/";

const reportSchema = Yup.object().shape({
    quantity: Yup
        .number()
        .integer()
        .min(1,'Quantity must be greater than zero')
});

const ReportModal = (props) => {

    const { item } = props;
    const accountData = useSelector(state => state.account);
    const commonData = useSelector(state => state.common);

    const formSubmit = async (payload) => {
        console.log('item : ',item);
        const postData = {
            apiUrl: apiService.report,
            payload: {
                nft: item._id,
                content : payload.content,
            },
        };
        const resp = await postMethod(postData);
        if(resp.message) {
            toastAlert(resp.status, resp.message, 'userForm');
        }
        if(resp.status === 'success') {
            props.onHide({reloadData:false});
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
            <Formik
                    initialValues={{
                        content: ''
                    }}
                    validationSchema={reportSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        formSubmit(values);
                    }}
                    render ={({
                        values,
                        isSubmitting,
                        handleChange
                    }) =>  (
                        <Form>                    
                            <div className="modal-body space-y-20 pd-40">
                                <h3>Report</h3>
                                <p>Are you sure to report this token?</p>
                                <div className="d-flex justify-content-between">
                                    <textarea
                                        className="form-control" 
                                        name="content"
                                        onChange={handleChange}
                                        autoComplete="off"
                                        placeholder="Enter your report content"
                                    />
                                    <ErrorMessage name="content" className="error" component="div" />
                                </div>
                                <button type="submit" className="btn btn-primary"> Report Token </button>
                            </div>
                        </Form>
                    )}
                > 
                </Formik>
            </div>
        </Modal>
    );
};

export default ReportModal;

import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap-accordion'
import ItemBox from '../item/ItemBox';
import ItemBoxDummy from '../item/ItemBoxDummy';
import { getAllNft } from 'core/service/api/nft.api';
import { Link, useLocation } from 'react-router-dom'

import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from 'react-bootstrap';

import { CommonDataSave } from 'core/redux/action/common.action';

const methodArr = [
    {
        code: 'fixedPrice',
        value: 'Sale'
    },
    {
        code: 'notForSale',
        value: 'Not For Sale'
    },
    {
        code: 'auction',
        value: 'Auction'
    }
];

const Explore = props => {

    const { search } = useLocation();
    const dispatch = useDispatch();

    const searchKey = new URLSearchParams(search).get('searchKey');

    const filterInitObj = {
        chains: [],
            methods: [],
        status: [],
        categories: [],
            priceRange: {
                currency: 'eth',
                from: null,
                to: null
            },
            searchKey: searchKey
    };

    const data = props.data;
    const accountData = useSelector(state => state.account);
    const commonData = useSelector(state => state.common);
    const { data: chainData } = useSelector(state => state.chain);

    const [nftListData, set_nftListData] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(8);
    const [itemCount, setItemCount] = useState(0);
    const [hasMoreVal, setHasMoreVal] = useState(true);
    const [filter, setFilter] = useState(filterInitObj);
    const [timer, set_timer] = useState(0);
    const [sortBy, set_sortBy] = useState({sort: 'mintedAt', order: 'ASC', label: 'Recently created'});

    const loadNftList = async () => {
        const payload = {
            page: currentPage,
            perPage,
            filter
        };
        const query = sortBy;
        currentPage === 1 && CommonDataSave(dispatch, {loader: true, loaderCur: true}, commonData);
        const { data, count } = await getAllNft(payload, query);
        // setTimeout(() => {
            CommonDataSave(dispatch, {loader: false, loaderCur: false}, commonData);
            setItemCount(count);
            if(data.length > 0) {
                upendItems(data);
            } else {
                setHasMoreVal(false);
            }
        // }, 1000);
    }

    const upendItems = (data) => {
        const newlist = [...nftListData, ...data];
        set_nftListData(newlist);
    }

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleOnChange = (eve) => {
        let { value, name } = eve.target;
        value = parseFloat(value);
        const filterNew = {...{}, ...filter};
        const { from: filterFrom, to: filterTo } = filterNew.priceRange;
        if(value !== undefined ) {
            filterNew.priceRange = (name === 'from') ? { from: value, to: filterTo } : { from: filterFrom, to: value };
        }
        setFilter(filterNew);
        timer > 0 && clearTimeout(timer);
        const new_timer = setTimeout(() => { pageReset() }, 1000);
        set_timer(new_timer);
    }

    const handleFilterChange = (eve) => {
        // name : chains / methods
        const { checked: isChecked, value, name } = eve.target;
        const filterNew = {...{}, ...filter};
        const idx = filterNew[name].findIndex( e => e === value );
        if(isChecked && idx === -1 && value !== undefined) {
            filterNew[name].push(value);
        } else if(isChecked === false && idx > -1) {
            filterNew[name].splice(idx, 1);
        }
        setFilter(filterNew);
        pageReset();
    }

    const checkedChk = (data = {}) => {
        const { name = '', value = '' } = data;
        if(name && value) {
            if(name === 'methods') {
                return filter.methods.findIndex(e => e.toLowerCase() === value.toLowerCase()) > -1 ? 'checked' : '';
            }
        }
    }

    const pageReset = async () => {
        console.log('pageReset : ', pageReset);
        // if(currentPage === 1) {
        //     setLoadData(!loadData);
        // } else {
        //     setCurrentPage(1);
        // }
        set_nftListData([]);
        setHasMoreVal(true);
        setCurrentPage(1);
        setLoadData(!loadData);
    }

    const onClearHandle = async () => {
        setFilter(filterInitObj);
        set_sortBy({sort: 'mintedAt', order: 'ASC', label: 'Recently created'});
    }

    useEffect(async () => {
        pageReset();
    }, [sortBy]);

    useEffect(async () => {
        await loadNftList();
    }, [currentPage, loadData]);

    return (
        <section className="tf-explore tf-section">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="wrap-box explore-1 flex mg-bt-40">
                            <div className="seclect-box style-1 explore-top-filter">
                                <div className="mr-10">
                                    <Button className="btn btn-selector">
                                        <span>
                                            {itemCount > 0 ? itemCount : '0'} items found
                                        </span>
                                    </Button>
                                </div>
                                {(
                                    <div className="mr-10">
                                        <Button className="btn btn-selector" onClick={() => onClearHandle()}>
                                            <span>Clear all</span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="seclect-box style-2 box-right">
                                <div id="sort-by" className="dropdown">
                                    <Link to="#" className="btn-selector nolink">{sortBy && sortBy.label ? sortBy.label  : 'Sort by'} &nbsp; &nbsp;</Link>
                                    <ul>
                                        <li><span onClick={() => { set_sortBy({sort: 'mintedAt', order: 'ASC', label: 'Recently created'}) }}>Recently created</span></li>
                                        <li><span onClick={() => { set_sortBy({sort: 'itemPrice', order: 'ASC', label: 'Price: High to Low'}) }}>Price: High to Low</span></li>
                                        <li><span onClick={() => { set_sortBy({sort: 'itemPrice', order: 'DESC', label: 'Price: Low to High'}) }}>Price: Low to High</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-12">
                        <div id="side-bar" className="side-bar style-3">
                            <div className="widget widget-category mgbt-24 boder-bt">
                                <form action="#" id="statusForm" name='statusForm'>
                                    <div className="content-wg-category">
                                        <Accordion title='Market Type' show={true}>
                                            {
                                                methodArr && 
                                                methodArr.map((ele, index) => (<div key={index}>
                                                    <label>{ele.value}
                                                        <input
                                                            type="checkbox"
                                                            name="methods"
                                                            value={ele.code}
                                                            onChange={handleFilterChange}
                                                            checked={checkedChk({name:"methods", value:ele.code})}
                                                        />
                                                        <span className="btn-checkbox"></span>
                                                    </label><br/>
                                                </div>))
                                            }
                                        </Accordion>
                                    </div>
                                    <div className="content-wg-category">
                                        <Accordion title='Price' show={true}>
                                            <div style={{width: '45%', display: "inline-block"}}>
                                                <input type="number" name="from" className='form-control p-6' onChange={handleOnChange} value={filter.priceRange.from}/>
                                            </div>
                                            <span style={{width: '5%', fontSize: '16px', marginLeft: '3px'}}>To</span>
                                            <div style={{ float:'right', display: "inline-block", width: '45%'}}>
                                                <input type="number" name="to" className='form-control p-6' onChange={handleOnChange} value={filter.priceRange.to}/>
                                            </div>
                                        </Accordion>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-12">
                        <Fragment>
                            <InfiniteScroll
                                dataLength={nftListData.length}
                                next={ () => handleNextPage() }
                                hasMore={hasMoreVal}
                                loader={<h4>&nbsp;</h4>}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>You have seen it all item's.</b>
                                    </p>
                                }
                            >
                                <div className='explore'>
                                    <div className="box-epxlore">
                                        {
                                            // nftListData && nftListData.length === 0 && commonData.loaderCur &&
                                            // [{},{},{}].map(item => 
                                            //     <ItemBoxDummy />
                                            // )
                                        }
                                        {nftListData && nftListData.length > 0 && nftListData.map( (item, index) => {
                                            return <>
                                                <ItemBox
                                                    item={item}
                                                    nftListData={nftListData}
                                                    set_nftListData={set_nftListData}
                                                />
                                            </>
                                        })}
                                    </div>
                                </div>
                            </InfiniteScroll>
                        </Fragment>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Explore;

// import config
import axios from "core/config/axios";
import { respChanges } from "./responseModify";

export const getAuthTokenFromApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `v1/member/token/get`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const saveAuthTokenFromApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `v1/member/token/save`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// import Countdown from "react-countdown";
import CardModal from '../CardModal';
import ItemBox from '../item/ItemBox';
import { getAllNft } from '../../../core/service/api/nft.api';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const filterInitObj = {
    chains: [],
    methods: ['auction'],
    status: [],
    categories: [],
    price: {
        currency: '',
        min: '',
        max: ''
    },
    liveActions: true
};

const LiveAuction = props => {
    const data = props.data;

    const accountData = useSelector(state => state.account);

    const [modalShow, setModalShow] = useState(false);

    const [nftListData, set_nftListData] = useState([]);
    const [currentPage, set_currentPage] = useState(1);
    const [perPage, set_perPage] = useState(8);
    const [hasMoreVal, set_hasMoreVal] = useState(true);
    const [filter, setFilter] = useState(filterInitObj);

    const loadNftList = async () => {
        const payload = {
            page: currentPage,
            perPage,
            filter
        };
        const { data, count } = await getAllNft(payload);
        if(data.length > 0) {
            const newlist = [...nftListData, ...data];
            set_nftListData(newlist);
        } else {
            set_hasMoreVal(false);
        }
    }

    const handleNextPage = () => {
        set_currentPage(currentPage + 1);
    };

    useEffect(async () => {
        await loadNftList();
    }, [currentPage]);

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <h2 className="tf-title">Live Auctions</h2>
                                <div className="heading-line"></div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {nftListData && nftListData.length === 0 && <div className="tf-title"><h4>No Auction Found</h4></div>}
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                    1300: {
                                        slidesPerView: 4,
                                    },
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                {
                                    nftListData.map((item, index) => (
                                        <SwiperSlide >
                                            <div className="swiper-container show-shadow carousel auctions">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="slider-item">
                                                            <ItemBox
                                                                item={item}
                                                                nftListData={nftListData}
                                                                set_nftListData={set_nftListData}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
        </Fragment>
    );
}

LiveAuction.propTypes = {
    data: PropTypes.array.isRequired,
}

export default LiveAuction;
import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link,useParams } from 'react-router-dom';
import Countdown from "react-countdown";
import liveAuctionData from '../assets/fake-data/data-live-auction';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// core
import config from 'core/config/';
import * as Api from 'core/service/api/all.api';
import { Nftdetail } from 'core/service/api/nft.api';
import apiService from "core/service/v1/";
import { postMethod } from "core/service/api/common.api";
import { toastAlert } from 'core/lib/toastAlert';
import { midEllipsis } from 'core/lib/JSHelper';
import { getSrcPath } from 'core/lib/fileHelper';

// redux
import { CommonDataSave } from 'core/redux/action/common.action';

// components
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
// import LiveAuction from 'components/layouts/LiveAuction';

// Modal
import TransferModal from 'components/layouts/modal/TransferModal';
import BidModal from 'components/layouts/modal/BidModal';
import BidAcceptModal from 'components/layouts/modal/BidAcceptModal';
import BuyModal from 'components/layouts/modal/BuyModal';
import PriceModal from 'components/layouts/modal/PriceModal';
import CancelOrderModal from 'components/layouts/modal/CancelOrderModal';
import BurnModal from 'components/layouts/modal/BurnModal';
import ReportModal from 'components/layouts/modal/ReportModal';
import CreateReserveModal from 'components/layouts/modal/CreateReserveModal';
import CancelReserveModal from 'components/layouts/modal/CancelReserveModal';

// separate
import OwnersTab from 'components/separate/OwnersTab';
import BidTab from 'components/separate/BidTab';
import InfoTab from 'components/separate/InfoTab';
import ActivityTab from 'components/separate/ActivityTab';

import FileCom from 'components/separate/FileCom';

// contract
import { readContract } from 'core/contract/index';

const ItemDetails = (props) => {

    const dispatch = useDispatch();

    let { collectionAddr, tokenId } = useParams();

    const accountData = useSelector(state => state.account);
    // const chainData = useSelector(state => state.chain);
    // const tokenData = useSelector(state => state.token);
    const commonData = useSelector(state => state.common);

    const [priceModal, priceModal_set] = useState(false);
    const [transferModal, transferModal_set] = useState(false);
    const [createReserveModal, createReserveModal_set] = useState(false);
    const [cancelReserveModal, cancelReserveModal_set] = useState(false);
    const [bidModal, bidModal_set] = useState(false);
    const [bidAcceptModal, bidAcceptModal_set] = useState(false);
    const [buyModal, buyModal_set] = useState(false);
    const [burnModal, burnModal_set] = useState(false);
    const [reportModal, reportModal_set] = useState(false);
    const [cancelorderModal, cancelorderModal_set] = useState(false);
    const [item, item_set] = useState({});
    const [userSelOwner, userSelOwner_set] = useState({});
    const [bidlist, Bidlist_set] = useState([]);
    const [currentBid, currentBid_set] = useState({});
    const [bidLive, bidLive_set] = useState(true);
    const [likeActive, setLikeActive] = useState('');

    async function getBidList(itemData){
        try {
            const postData = {
                apiUrl: apiService.bidlist,
                payload: {
                    tokenRef: itemData._id,
                }
            };
            CommonDataSave(dispatch, {loader: true}, commonData);
            const resp = await postMethod(postData);
            CommonDataSave(dispatch, {loader: false}, commonData);
            if(resp.status === 'success') {
                if(resp.data && resp.data.list && resp.data.list.length) {
                    let curbid = resp.data.list[0];
                    currentBid_set(curbid);
                    Bidlist_set(resp.data.list);
                }
            }
            if(resp.message) {
                toastAlert(resp.status, resp.message, 'userForm');
            }
        } catch(err){
            console.log('err : ', err);
            CommonDataSave(dispatch, {loader: false}, commonData);
        }
    }

    async function LikeNft(hitItem) {
        try {
            const { _id } = hitItem;
            const { address: userAddress  } = accountData;
            if(userAddress) {
                const { like: apiUrl  } = apiService;
                const postData = {
                    apiUrl,
                    payload: {
                        targetId: _id,
                        userAddress
                    },
                };
                const resp = await postMethod(postData);
                const { status, data, message } = resp;
                if(status === 'success' && data) {
                    // const itemData = Object.assign({}, hitItem);
                    // itemData.likeCount = data.likeCount;
                    // item_set(itemData);
                    getInit();
                }
                if(message) {
                    toastAlert(status, message, 'userForm');
                }
            }
            else {
                toastAlert('error', 'Please connect wallet and continue', 'userLogin');
            }
        }
        catch(err){
            console.log('err : LikeNft : ', err);
        }
    }

    async function getNftDet() {
        const apiData = {
            payload: {
                collectionAddr,
                tokenId,
                section: 'detail'
            }
        };
        const { data } = await Api.nftDetail(apiData);
        let itemData = {};
        if(data && data[0] && data[0].owner){
            itemData = data[0];
        }
        return itemData;
    }

    async function getInit() {
        try{
            let itemData = await getNftDet();
            if(itemData.blockchain) {
                let auctionId = 0;
                let auctionDetail = {
                    seller: config.zeroAddress,
                    bidder: config.zeroAddress
                }
                const {
                    ownerOfAuction = {}
                } = itemData;
                const feeObj = {
                    method: 'getReserveAuctionIdFor',
                    contractSymbol: 'trade',
                    nftContract: collectionAddr,
                    tokenId: tokenId,
                    networkVersion: itemData.blockchain?.networkVersion
                };
                auctionId = await readContract(feeObj);
                if(auctionId) {
                    auctionId = parseInt(auctionId);
                    const feeObj = {
                        method: 'getReserveAuction',
                        contractSymbol: 'trade',
                        auctionId: auctionId,
                        networkVersion: itemData.blockchain?.networkVersion
                    };
                    auctionDetail = await readContract(feeObj);

                    if(auctionDetail.amount) {
                    }

                    if(auctionDetail === undefined) {
                        auctionDetail = {
                            seller: config.zeroAddress,
                            bidder: config.zeroAddress
                        }
                    }
                }

                let auctionDetailItem = {};
                if(ownerOfAuction.auctionDetail) {
                    auctionDetailItem =  ownerOfAuction.auctionDetail;
                    itemData.auctionId =  ownerOfAuction.auctionId;
                }

                if(auctionId !== false) {
                    const auctionDetailAmount = parseFloat(auctionDetail.amount) / 1e18;
                    if(
                        (auctionDetailItem.seller && auctionDetail.seller &&
                        auctionDetailItem.seller.toLowerCase() !== auctionDetail.seller.toLowerCase()) ||
                        (auctionDetailItem.bidder && auctionDetail.bidder &&
                        auctionDetailItem.bidder.toLowerCase() !== auctionDetail.bidder.toLowerCase()) ||
                        (auctionDetailItem.amount && auctionDetailAmount &&
                        parseFloat(auctionDetailItem.amount) !== auctionDetailAmount) ||
                        (auctionId > 0 && itemData.auctionId && auctionId !== itemData.auctionId)
                    ) {
                        console.log('updAuction')
                        const postData = {
                            apiUrl: apiService.updAuction,
                            payload: {
                                auctionDetail: auctionDetail,
                                auctionId: auctionId,
                            }
                        };
                        await postMethod(postData);
                        itemData = await getNftDet();
                    }
                }
                getBidList(itemData);
                detailChange_func(itemData);
            }
        } catch(err){
            console.log('err : getInit : ', err);
        }
    }

    async function detailChange_func(itemData) {
        if(itemData && itemData.owner) {
            const {
                ownerOfAuction = {}
            } = itemData;
            let firstPrefer = false;
            const element = itemData.owner;
            delete itemData.firstPreferDetail;
            delete itemData.accOwnerDetail;

            const { likeInfo = [] } = itemData;

            setLikeActive(likeInfo.length > 0 ? 'active' : '');

            if(element.ownerInfo && element.ownerInfo.address) {
                if(accountData.address) {
                    if(firstPrefer === false && accountData.address.toLowerCase() !== element.ownerInfo.address.toLowerCase()) {
                        firstPrefer = true;
                        itemData.firstPreferDetail = element;
                    }
                    if(accountData.address.toLowerCase() === element.ownerInfo.address.toLowerCase()) {
                        itemData.accOwnerDetail = element;
                    }
                }
            }

            if(ownerOfAuction) {
                const {
                    auctionDetail = {}
                } = ownerOfAuction;

                if(auctionDetail.seller && accountData.address && auctionDetail.seller.toLowerCase() === accountData.address.toLowerCase()) {
                    itemData.accOwnerDetail = ownerOfAuction;
                }
            }
            item_set(itemData);
        }
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            createReserveModal && createReserveModal_set(false);
            if(
                item.ownerOfAuction?.auctionDetail?.buyer?.toLowerCase() === accountData?.address
                ||
                item.ownerOfAuction?.auctionDetail?.seller?.toLowerCase() === accountData?.address
            ) {
                return <button onClick={() => bidAcceptModal_set(true)}>Claim</button>
            }
            else {
                bidLive_set(false);
                return <span>Auction Timeout</span>
            }
        } else {
            return <span>{hours}:{minutes}:{seconds}</span>;
        }
    };

    // useEffect(() => {
    //     if(item && item._id && commonData.afterChainDataGet) {
    //         setTimeout(() => {
    //             getBidList(item);
    //         }, 1000);
    //     }
    // }, [item, commonData]);

    useEffect(() => {
        console.log('accountData : itemdetail : ');
        getInit();
    }, []);

    useEffect(() => {
        detailChange_func(item);
    }, [accountData]);

    const shareNft = () => {
        navigator.clipboard.writeText(window.location.href).then(function() {
            toastAlert('success', 'NFT URL copied!', 'copyUrl');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    const getOwnerSection = (itemType, owner) => {
        return (
            <>
                {
                    itemType == 721 && owner && owner.ownerInfo && 
                    <span>Owned by :
                        <span>
                            <Link to={"/user/"+creator.address+'/created'}>
                                { midEllipsis(owner.ownerInfo.nameShow) }
                            </Link>
                        </span> 
                    </span>
                }
            </>
        );
    }

    const {
        name, type: itemType, image,
        owner = {}, creator, collection, likeCount,
        blockchain, accOwnerDetail, firstPreferDetail,
        ownerOfAuction = {}
    } = item;

    const {
        auctionDetail = {}
    } = ownerOfAuction;

    const {
        ownerInfo = {}
    } = owner;

    const {
        nameShow: nameShowOwner,
        address: addressOwner,
        image: imageOwner = {}
    } = ownerInfo;

    return (
        <div className='item-details'>
            <Header {...props} />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Item Details</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/explore">Explore</Link></li>
                                    <li>Item Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <FileCom
                                        src={image}
                                        path={'nft'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">{name}</h2>
                                    <div className="meta-item">
                                        <div className="left">
                                            { getOwnerSection(itemType, owner) }
                                            {
                                                accOwnerDetail && accOwnerDetail.availQuan > 0 &&
                                                <span className='fa fa-user'>You own {accOwnerDetail.availQuan}</span>
                                            }
                                        </div>
                                        <div className="right">
                                            {/* <a className="share" href="/item-details-01"></a> */}
                                            <span to="/login" 
                                                className={"liked heart wishlist-button mg-l-8 "+likeActive }
                                                onClick={()=>{ LikeNft(item); }} >
                                                <span className="number-like">{likeCount}</span>
                                            </span>

                                            {commonData.afterChainDataGet &&
                                            <div className="option">
                                                <div className="item-actions">
                                                    <ul>
                                                        {
                                                            accOwnerDetail && accOwnerDetail.ownerInfo && accOwnerDetail.availQuan > 0 ?
                                                            <>
                                                                {
                                                                    auctionDetail.seller === undefined ?
                                                                    <>
                                                                        <li onClick={() => { userSelOwner_set(accOwnerDetail); priceModal_set(true); }}>
                                                                            {(accOwnerDetail.price) === 0?"Put On Sale":"Change Price"}
                                                                        </li>
                                                                        {
                                                                            accOwnerDetail.price > 0 &&
                                                                            <li onClick={() => { userSelOwner_set(accOwnerDetail); cancelorderModal_set(true); }}>
                                                                                Remove from sale
                                                                            </li>
                                                                        }
                                                                        <li onClick={() => { userSelOwner_set(accOwnerDetail); createReserveModal_set(true); }}>
                                                                            Reserve Auction
                                                                        </li>

                                                                        <li onClick={() => { userSelOwner_set(accOwnerDetail); transferModal_set(true); }} >
                                                                            Transfer
                                                                        </li>
                                                                        {creator.address === accountData.address &&
                                                                        <li onClick={() => { burnModal_set(true); }}>Burn</li>}
                                                                    </>
                                                                    :
                                                                    auctionDetail.seller && 
                                                                    auctionDetail.seller?.toLowerCase() === accountData.address.toLowerCase() && 
                                                                    auctionDetail.endTime === 0 &&
                                                                    <>
                                                                        <li onClick={() => { userSelOwner_set(accOwnerDetail); createReserveModal_set(true); }}>
                                                                            Update Auction
                                                                        </li>
                                                                        <li onClick={() => { userSelOwner_set(accOwnerDetail); cancelReserveModal_set(true); }} >
                                                                            Cancel Auction
                                                                        </li>
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            accountData.address && firstPreferDetail?.ownerInfo?.address &&
                                                            <>
                                                                {
                                                                    firstPreferDetail.price > 0 && auctionDetail.bidder === undefined ?
                                                                    <li onClick={() => { userSelOwner_set(firstPreferDetail); buyModal_set(true); }}>
                                                                        Buy Now
                                                                    </li>
                                                                    :
                                                                    auctionDetail.nftContract && auctionDetail.bidder?.toLowerCase() !== accountData.address.toLowerCase() &&
                                                                    auctionDetail.seller?.toLowerCase() !== accountData.address.toLowerCase() &&
                                                                    bidLive &&
                                                                    <li onClick={() => { userSelOwner_set(firstPreferDetail); bidModal_set(true); }}>
                                                                        Place a bid
                                                                    </li>
                                                                }
                                                            </>
                                                        }
                                                        
                                                        {!accOwnerDetail &&
                                                            <li onClick={() => { reportModal_set(true); }}>
                                                                Report
                                                            </li>
                                                        }
                                                        <li onClick={() => { shareNft(); }}>
                                                            Copy link
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="client-infor sc-card-product">
                                        {creator && creator.image && creator.image.profileImage && creator.address &&
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={getSrcPath({src:creator.image.profileImage, path:'profile'})} alt="NFTs"/>
                                                </div>
                                                <div className="info">
                                                    <span>Create By</span>
                                                    <h6><Link to={"/user/"+creator.address+'/created'}>{midEllipsis(creator.nameShow)}</Link></h6>
                                                </div>
                                            </div>
                                        </div>}
                                        {addressOwner && 
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={getSrcPath({src:imageOwner.profileImage, path:'profile'})} alt="NFTs"/>
                                                </div>
                                                <div className="info">
                                                    <span>Owned By</span>
                                                    <h6><Link to={"/user/"+addressOwner+'/owned'}>{midEllipsis(nameShowOwner)}</Link></h6>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                    <p>{item.description}</p>
                                    <div className="meta-item-details style2">
                                        {
                                            currentBid.price > 0 &&
                                            <div className="item meta-price">
                                                <span className="heading">Current Bid</span>
                                                <div className="price">
                                                    <div className="price-box">
                                                        <h5>{currentBid.price}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            auctionDetail?.endTime > 0 &&
                                            <>
                                                {currentBid.price === 0 &&
                                                <div className="item count-down">
                                                    <span className="heading style-2">Reserve Price: </span>
                                                    <div className="price">
                                                        <div className="price-box">
                                                            <h5>{auctionDetail?.amount / 1e18}{' '}{blockchain.nativeCurrencySymbol}</h5>
                                                        </div>
                                                    </div>
                                                </div>}
                                                <div className="item count-down">
                                                    <span className="heading style-2">Countdown: </span>
                                                    <Countdown
                                                        date={(auctionDetail?.endTime * 1000)}
                                                        renderer={renderer}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        accountData.address &&
                                        <>
                                            {
                                                accOwnerDetail?.ownerInfo ?
                                                <div>
                                                    {
                                                    auctionDetail.seller === undefined ?
                                                        <>
                                                            <a href="javascript:void(0)" onClick={() => { userSelOwner_set(accOwnerDetail); priceModal_set(true)}} className="sc-button loadmore style bag fl-button pri-3"><span>{(accOwnerDetail.price) === 0?"Put On Sale":"Change Price"}</span></a>
                                                            <a href="javascript:void(0)" onClick={() => { userSelOwner_set(accOwnerDetail); createReserveModal_set(true)}} className="sc-button loadmore style bag fl-button pri-3"><span>Reserve Auction</span></a>
                                                        </>
                                                        :
                                                        auctionDetail.seller && auctionDetail.seller?.toLowerCase() === accountData.address.toLowerCase() && auctionDetail?.endTime === 0 &&
                                                        <>
                                                            <a href="javascript:void(0)" onClick={() => { userSelOwner_set(accOwnerDetail); createReserveModal_set(true)}} className="sc-button loadmore style bag fl-button pri-3"><span>Update Auction</span></a>
                                                            <a href="javascript:void(0)" onClick={() => { userSelOwner_set(accOwnerDetail); cancelReserveModal_set(true)}} className="sc-button loadmore style bag fl-button pri-3"><span>Cancel Auction</span></a>
                                                        </>
                                                    }
                                                </div>
                                                :
                                                firstPreferDetail?.ownerInfo?.address &&
                                                <div>
                                                    {
                                                        firstPreferDetail.price > 0 && auctionDetail.bidder === undefined ?
                                                        <a href="javascript:void(0)" onClick={() => { userSelOwner_set(firstPreferDetail); buyModal_set(true)}} className="sc-button loadmore style bag fl-button pri-3"><span>Buy Now</span></a>
                                                    :
                                                        auctionDetail.nftContract &&
                                                        auctionDetail.bidder?.toLowerCase() !== accountData.address.toLowerCase() &&
                                                        auctionDetail.seller?.toLowerCase() !== accountData.address.toLowerCase() &&
                                                        bidLive &&
                                                        <a href="javascript:void(0)" onClick={() => { userSelOwner_set(firstPreferDetail); bidModal_set(true)}} className="sc-button loadmore style bag fl-button pri-3"><span>Place a bid</span></a>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                    <div className="flat-tabs themesflat-tabs">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Owners</Tab>
                                                <Tab>Bids</Tab>
                                                <Tab>Info</Tab>
                                                <Tab>Activity</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <OwnersTab
                                                    item={item}
                                                    owner={owner}
                                                    userSelOwner_set={userSelOwner_set}
                                                    buyModal_set={buyModal_set}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <BidTab
                                                    item={item}
                                                    bidlist={bidlist}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <InfoTab
                                                    item={item}
                                                    collection={collection}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <ActivityTab
                                                    item={item}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <LiveAuction data={liveAuctionData} /> */}
            <CreateReserveModal
                show={createReserveModal}
                onHide={(data = {}) => {
                    createReserveModal_set(false)
                }}
                item={item}
                userSelOwner={userSelOwner}
                createReserveModal={createReserveModal}
                getInit={() => getInit()}
            />
            <CancelReserveModal
                show={cancelReserveModal}
                onHide={(data = {}) => {
                    cancelReserveModal_set(false)
                }}
                item={item}
                userSelOwner={userSelOwner}
                cancelReserveModal={cancelReserveModal}
                getInit={() => getInit()}
            />
            <TransferModal
                show={transferModal}
                onHide={(data = {}) => {
                    transferModal_set(false)
                }}
                item={item}
                userSelOwner={userSelOwner}
                getInit={() => getInit()}
            />
            <BidModal
                show={bidModal}
                onHide={(data = {}) => {
                    bidModal_set(false)
                }}
                item={item}
                userSelOwner={userSelOwner}
                ownerOfAuction={ownerOfAuction}
                getInit={() => getInit()}
            />
            <BidAcceptModal
                show={bidAcceptModal}
                onHide={(data = {}) => {
                    bidAcceptModal_set(false)
                }}
                item={item}
                ownerOfAuction={ownerOfAuction}
                bidlist={bidlist}
                getInit={() => getInit()}
            />
            <BuyModal
                show={buyModal}
                onHide={(data = {}) => {
                    buyModal_set(false)
                }}
                item={item}
                userSelOwner={userSelOwner}
                getInit={() => getInit()}
            />
            <PriceModal
                show={priceModal}
                onHide={(data = {}) => {
                    priceModal_set(false)
                }}
                item={item}
                userSelOwner={userSelOwner}
                getInit={() => getInit()}
            />
            <CancelOrderModal
                show={cancelorderModal}
                onHide={(data = {}) => {
                    cancelorderModal_set(false)
                }}
                item={item}
                userSelOwner={userSelOwner}
                getInit={() => getInit()}
            />
            <BurnModal
                show={burnModal}
                onHide={(data = {}) => {
                    burnModal_set(false)
                }}
                item={item}
                getInit={() => getInit()}
            />
            <ReportModal
                show={reportModal}
                onHide={(data = {}) => {
                    reportModal_set(false)
                }}
                item={item}
                getInit={() => getInit()}
            />
            <Footer />
        </div>
    );
}

export default ItemDetails;

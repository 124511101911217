import { SET_MEMBER_DATA } from '../constant';

export const MemberDataSave = (dispatch, data) => {
    try {
        dispatch({
            type: SET_MEMBER_DATA,
            data
        })
        return true;
    } catch (err) {
        return false;
    }
}
// import constant
import { SET_MEMBER_DATA } from '../constant';

const initialState = {
    firstName: '',
    nameShow: '',
    address: '',
    image: {},
    createdAt: ''
};

const member = (state = initialState, action) => {
    switch (action.type) {
        case SET_MEMBER_DATA:
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }

}

export default member;
import { SET_CHAIN_DATA } from '../constant';

const chain = (state = {loaded: false, data: []}, action) => {
    switch (action.type) {
        case SET_CHAIN_DATA:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }

}

export default chain;
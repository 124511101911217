import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function toastAlert(errorType, message, id, 
    position = 'TOP_RIGHT' //TOP_RIGHT, TOP_CENTER
) {
    id = id+' '+new Date();
    if (errorType) {
        toast[errorType](message, {
            autoClose: 2000,
            toastId: id,
            position: toast.POSITION[position],
        });
    } else {
        toast(message, {
            autoClose: 2000,
            toastId: id,
            position: toast.POSITION[position],
        });
    }
}
// import packages
import axios from 'axios';

// import lib
import config from './index';
import { getAuthToken, setAuthToken, removeAuthToken } from '../lib/localStorage';

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common['Authorization'] = getAuthToken();

export const setAuthorization = (token) => {
    setAuthToken(token);
    axios.defaults.headers.common['Authorization'] = token;
}

export const removeAuthorization = () => {
    removeAuthToken();
    delete axios.defaults.headers.common["Authorization"];
}

export default axios;
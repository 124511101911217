import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { buy } from "core/service/api/nft.api";
import config from "core/config";
import { toastAlert } from "core/lib/toastAlert";

import ConnectWalletAlert from "./ConnectWalletAlert";
import StepsBtn from "components/separate/StepsBtn";
import * as Constants from "core/constant/";

import { buyAsset_cont, chk_setapprovalforall_cont } from "core/contract/index";

const BuyModal = (props) => {
  const { item = {}, userSelOwner } = props;

  const accountData = useSelector((state) => state.account);
  const commonData = useSelector((state) => state.common);

  const [youwillpay, youwillpay_set] = useState(0);
  const [loader, loader_set] = useState(false);

  const youwillpay_cal = async (values) => {
    let retAmt = 0;
    if (
      userSelOwner.price &&
      values.quantity &&
      commonData &&
      commonData.fee.buyerServiceFee
    ) {
      retAmt = (
        (userSelOwner.price *
          values.quantity *
          commonData.fee.buyerServiceFee) /
          100 +
        +userSelOwner.price * values.quantity
      ).toFixed(config.fixed.currencyBal);
    }
    youwillpay_set(retAmt);
  };

  const buySchema = Yup.object().shape({
    quantity: Yup.number()
      .integer()
      .typeError("Quantity must specify a number")
      .min(1, "Enter valid Quantity min")
      .max(userSelOwner.availQuan, "Enter valid Quantity max")
      .required("Quantity is a required field"),
  });
  
  const formSubmit = async (values) => {
    try {
      const { balance: accountDataBal = 0 } = accountData;
      if (accountDataBal === 0 || accountDataBal < youwillpay) {
        toastAlert(false, "Insufficient Balance", "balance");
        return false;
      }
      if (item.owner && item.owner.availQuan < values.quantity) {
        toastAlert(
          false,
          "Available quantity " + item.owner.availQuan,
          "balance"
        );
        return false;
      }
      buy_click(values);
    } catch (err) {
      console.log("err : ", err);
    }
  };

  const buy_click = async (values) => {
    try {
      const contData = {
        contractSymbol: "trade",
        collectionAddr: item.collectionAddr,
        tokenId: item.tokenId,
        userAddress: accountData.address,
        unitPrice: userSelOwner.price,
        amount: userSelOwner.price * values.quantity,
      };
      loader_set(true);
      const buyContResp = await buyAsset_cont(contData);
      loader_set(false);

      if (buyContResp && buyContResp.status) {
        const buyData = {
          price: userSelOwner.price,
          tokenId: item.tokenId,
          ownerAddr: userSelOwner.ownerInfo.address,
          address: accountData.address,
          quantity: values.quantity,

          trxId: buyContResp.transactionHash,
          target: "buy",
        };
        loader_set(true);
        const { status, message } = await buy(buyData);
        loader_set(false);
        if ((status === "success" || status === "error") && message) {
          toastAlert(status, message, "userForm");
        }
        if (status === "success") {
          props.onHide({ reloadData: true });
          props.getInit();
        }
      }
    } catch (err) {
      console.log("err : ", err);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40">
        <Formik
          initialValues={{
            quantity: 1,
            currencySymbol:
              item && item.blockchain && item.blockchain.nativeCurrencySymbol
                ? item.blockchain.nativeCurrencySymbol
                : "",
          }}
          validationSchema={buySchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            formSubmit(values);
          }}
          render={({ values, errors, isSubmitting, handleChange }) => (
            <Form>
              <div className="modal-body space-y-20 pd-40">
                {!accountData || !accountData.address ? (
                  <ConnectWalletAlert />
                ) : (
                  <>
                    <h3>Checkout</h3>
                    <p className="text-center">
                      You are about to purchase a{" "}
                      <span className="color-popup">
                        {item.name} from{" "}
                        <h6 className="dotString">
                          <Link
                            to={"/user/" + item.creator.address + "/created"}
                          >
                            {userSelOwner?.ownerInfo?.nameShow}
                          </Link>
                        </h6>
                      </span>
                    </p>
                    {item.type === 1155 && (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          name="quantity"
                          onChange={handleChange}
                          defaultValue={values.quantity}
                          autoComplete="off"
                          placeholder="Please enter the quantity"
                          onBlur={() => {
                            youwillpay_cal(values);
                          }}
                        />
                        <p>
                          Enter quantity.
                          <span className="color-popup">
                            {userSelOwner.availQuan} available
                          </span>
                        </p>
                        <ErrorMessage
                          name="quantity"
                          className="error"
                          component="div"
                        />
                      </>
                    )}

                    <div className="hr"></div>
                    <div className="d-flex justify-content-between">
                      <p>Your balance</p>
                      <p className="text-right price color-popup">
                        {" "}
                        {accountData && accountData.balance}{" "}
                        {values.currencySymbol}{" "}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Service fee</p>
                      <p className="text-right price color-popup">
                        {" "}
                        {commonData && commonData.fee.buyerServiceFee}%{" "}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>You will pay</p>
                      <p className="text-right price color-popup">
                        {commonData &&
                        commonData.fee &&
                        values.quantity &&
                        Object.keys(errors).length === 0
                          ? (
                              (+userSelOwner.price *
                                values.quantity *
                                commonData.fee.buyerServiceFee) /
                                100 +
                              +userSelOwner.price * values.quantity
                            ).toFixed(config.fixed.currencyBal)
                          : 0}
                        {" " + values.currencySymbol}
                      </p>
                    </div>
                    <StepsBtn
                      type="submit"
                      className="btn btn-primary"
                      onClick={async () => {}}
                      disabled={loader}
                      loader={loader}
                      label={Constants.PURCHASE_LABEL}
                    />
                  </>
                )}
              </div>
            </Form>
          )}
        ></Formik>
      </div>
    </Modal>
  );
};

export default BuyModal;

import React,{useState,useEffect} from 'react';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { toastAlert } from 'core/lib/toastAlert';
import {
    addrCntChk,
    chk_setapprovalforall_cont,
    finalizeReserveAuction_cont
} from 'core/contract/index';

import apiService from "core/service/v1/";
import { postMethod } from "core/service/api/common.api";

import ConnectWalletAlert from './ConnectWalletAlert';
import StepsBtn from 'components/separate/StepsBtn';
import * as  Constants from 'core/constant/';

const BidAcceptModal = (props) => {

    const { show, onHide, item, bidlist, ownerOfAuction } = props;
    const accountData = useSelector(state => state.account);
    const chainData = useSelector(state => state.chain);

    const [step, step_set] = useState(1);
    const [loader, loader_set] = useState(false);

    const approve_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                loader_set(true);
                let chkData = await chk_setapprovalforall_cont({
                    userAddress: accountData.address,
                    operator: 'trade',
                    approved: true,
                    contractSymbol: item.type,

                    chainData,
                    accountData
                });
                loader_set(false);

                if(chkData && chkData.status) {
                    step_set(2);
                }
            } else {
                loader_set(false);
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    const finalizeReserveAuction_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                if(ownerOfAuction && ownerOfAuction.auctionId) {
                    const auctionId = ownerOfAuction.auctionId;
                    loader_set(true);
                    let accept_resp = await finalizeReserveAuction_cont({
                        auctionId: auctionId,
                        contractSymbol: 'trade',
                        userAddress: accountData.address
                    });
                    loader_set(false);
                    if(accept_resp && accept_resp.status) {
                        const bidId = bidlist[0]._id;
                        const postData = {
                            apiUrl: apiService.bidAccept,
                            payload: {
                                target: "cancelAuction",
                                auctionId: auctionId,
                                bidId: bidId,
                                trxId: accept_resp.transactionHash,
                                quantity: 1,
                            }
                        };
                        loader_set(true);
                        const resp = await postMethod(postData);
                        loader_set(false);
                        const { status, result, message } = resp;
                        if(status === 'success') {
                            step_set(3);
                        }
                        if(message) {
                            toastAlert(status, message, 'userForm');
                        }
                    }
                }
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    useEffect(() => {
        show && approve_click();
    }, [show]);

    useEffect(() => {
        console.log('step : ', step);
        switch (step) {
            case 2:
                finalizeReserveAuction_click();
                break;
            case 3:
                props.onHide({reloadData:true});
                props.getInit();
                break;
            default:
                break;
        }
    }, [step]);

    return (
        <Modal
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                {
                !accountData || !accountData.address
                ?
                <ConnectWalletAlert />
                :
                <div className="modal-body space-y-20 pd-40">
                    <h3>Follow steps</h3>                    
                    <p>{Constants.APPROVE_DESC}</p>
                    <StepsBtn
                        type="button"
                        className="btn btn-primary"
                        onClick={() => approve_click()}
                        disabled={step!=1 || loader}
                        loader={step===1 && loader}
                        label={Constants.APPROVE_LABEL}
                    />
                    
                    <p>{Constants.AUCTION_CLAIM_DESC}</p>
                    <StepsBtn
                        type="button"
                        className="btn btn-primary"
                        onClick={() => finalizeReserveAuction_click()}
                        disabled={step!=2 || loader}
                        loader={step===2 && loader}
                        label={Constants.AUCTION_CLAIM_LABEL}
                    />
                </div>
                }
            </div>
        </Modal>
    );
};

export default BidAcceptModal;

import React, {useState,useEffect} from 'react';
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { toastAlert } from '../../../core/lib/toastAlert';
import { transfer_cont } from '../../../core/contract/index';
import { postMethod } from "../../../core/service/api/common.api";

import apiService from "../../../core/service/v1/";

import ConnectWalletAlert from './ConnectWalletAlert';
import StepsBtn from 'components/separate/StepsBtn';
import * as  Constants from 'core/constant/';

// import { Box, Button, Card, Checkbox, Grid, Typography } from "@mui/material";

const TransferModal = (props) => {

    const { item, userSelOwner } = props;
    const accountData    = useSelector(state => state.account);

    const [loader, loader_set] = useState(false);

    const {
        availQuan = 0
    } = userSelOwner ? userSelOwner : {};

    const transferSchema = Yup.object().shape({
        receiverAddr: Yup
            .string()
            .required('Receiver Address is a required field'),
        quantity: Yup
            .number()
            .integer()
            .required('"Amount" is a required field')
            .min(1,'"Amount" must be greater than zero')
            .max(userSelOwner.availQuan, '"Amount" must be less than or equal to '+userSelOwner.availQuan)
    });

    const formSubmit = async (payload) => {
        var data = {
            from : accountData.address,
            to : payload.receiverAddr,
            tokenId : item.tokenId,
            quantity : payload.quantity,
            contractDet: {
                exchange: item.exchange,
                type: item.type,
                collectionAddr: item.collectionAddr,
                networkVersion : accountData.networkVersion
            }
        }
        loader_set(true);
        let respCont = await transfer_cont(data);
        loader_set(false);
        if(respCont.status) {
            const postData = {
                apiUrl: apiService.transfer,
                payload: {
                    tokenId: item.tokenId,
                    collectionAddr: item.collection.address,
                    receiverAddr : payload.receiverAddr,
                    quantity : payload.quantity,
                    target: "transfer",
                    trxId: respCont.transactionHash
                },
            };
            loader_set(true);
            const resp = await postMethod(postData);
            loader_set(true);
            if(resp.status === 'success') {
                props.onHide({reloadData:true});
                props.getInit();
            }
            if(resp.message) {
                toastAlert(resp.status, resp.message, 'userForm');
            }
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                {
                !accountData || !accountData.address
                ?
                <ConnectWalletAlert />
                :
                <Formik
                    initialValues={{
                        receiverAddr: '',
                        quantity: 1
                    }}
                    validationSchema={transferSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        formSubmit(values);
                    }}
                    render ={({
                        values,
                        isSubmitting,
                        handleChange
                    }) =>  (
                        <Form>
                            <div className="modal-body space-y-20 pd-40">
                                <h3>Transfer token</h3>
                                <p className="text-center">You can transfer tokens from your address to another</p>
                                <input
                                    type="text"
                                    className="form-control" 
                                    name="receiverAddr"
                                    onChange={handleChange}
                                    autoComplete="off"
                                    placeholder="Receiver Address"
                                />
                                <ErrorMessage name="receiverAddr" className="error" component="div" />
                                {item.type === 1155 && 
                                <div>
                                    <p>Enter quantity. <span className="color-popup">{userSelOwner.availQuan} available</span></p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="quantity"
                                        onChange={handleChange}
                                        autoComplete="off"
                                        placeholder="Enter the amount" 
                                    />
                                </div>
                                }
                                <ErrorMessage name="quantity" className="error" component="div" />
                                <div className="hr"></div>
                                <StepsBtn
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={async () => {}}
                                    disabled={loader}
                                    loader={loader}
                                    label={'Continue'}
                                />
                            </div>
                        </Form>
                        )}
                > 
                
                </Formik>
                }
            </div>
        </Modal>
    );
};

export default TransferModal;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { getuserProfile } from 'core/service/api/nft.api';
import FileCom from 'components/separate/FileCom';

import {
    getCurContractAbi,
    getCurContract,
    addrCntChk,
    CreateItemValidation_chk,
    checkWalCnt,
    getContratInst,
    // getWeb3Inst,
    // getWeb3UtilsInst
} from "core/contract";
import config from "core/config/index";
import { CommonDataSave } from 'core/redux/action/common.action';

import { toastAlert } from 'core/lib/toastAlert';
import { getSrcPath } from 'core/lib/fileHelper';

import CreateitemModal from 'components/layouts/modal/CreateitemModal';

import DateTimePicker from 'react-datetime-picker';

// import "react-datetime/css/react-datetime.css";
// import Datetime from 'react-datetime';

const CreateItem = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // redux
    const accountData = useSelector(state => state.account);
    const chainData = useSelector(state => state.chain);
    const commonData = useSelector(state => state.common);
    const tokenData = useSelector(state => state.token);

    const [userDet, userDet_set] = useState({});

    const [mintModal, mintModal_set] = useState(false);

    const initialFormValue = {
        method: 'fixedPrice',
        tokenId: '',
        name: '',
        description: '',
        price: '',
        minimumbid:'',
        royalty: 0,
        supply: 1,
        startDate: new Date(),
        endDate: new Date(),
        image: '',
        ipfsimg: '',
        imageName: '',
        ipfshash: '',
        tokenURI: '',
        attributes: [
            { trait_type: '', value: '' }
        ],
        createItemValid_chk: false,
        priceToken: '',
        priceTokenForMint: '',
        priceTokenForBid: '',
    }

    const [contractDet, contractDet_set] = useState({});
    const [collectible, collectible_set] = useState(initialFormValue);
    const [errors, errors_set] = useState({});

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        collectible_Change({ name, value });
    }

    const collectible_Change = (parA = {}) => {
        const collectible_cpy = Object.assign({}, collectible);
        let formData = { ...collectible_cpy, [parA.name]: parA.value };
        collectible_set(formData);
    }

    const collectibleUpdate = async (data={}) => {
        const collectible_cpy = Object.assign({}, collectible);
        let formData = { ...collectible_cpy, [data.name]: data.value };
        collectible_set(formData);
    }

    const collectibleUpdateMulti = async (data=[]) => {
        let collectible_cpy = Object.assign({}, collectible);
        for(var a = 0; a < data.length; a++) {
            collectible_cpy = { ...collectible_cpy, [data[a].name]: data[a].value };
        }
        collectible_set(collectible_cpy);
    }

    const setFormObj = (obj) => {
        const collectible_cpy = Object.assign({}, collectible);
        let formData = { ...collectible_cpy, ...obj };
        collectible_set(formData);
    } 

    const handleFormChange = (index, event) => {
        let data = [...collectible.attributes];
        data[index][event.target.name] = event.target.value;
        setFormObj({'attributes': data});
    }

    const handleAddSize = async (e) => {
        e.preventDefault();
        let data = [...collectible.attributes];
        data.push({trait_type:'', value:''});
        setFormObj({'attributes': data});
    }

    const handleRemoveSize = (index) => {
        let data = [...collectible.attributes];
        data.splice(index, 1);
        setFormObj({'attributes': data});
    }

    const handleFile = async (e) => {
        const fileAllowedFormat = /\.(jpg|jpeg|png|gif|webp|mp4|ogg|mp3|svg)$/;
        if (e.target.files[0].size > config.FILE_SIZE) {
            toastAlert('warning', "Image must be lesser than 30 MB", 'userForm')
        } else if (!fileAllowedFormat.test(e.target.files[0].name)) {
            toastAlert('warning', "Image must be select JPG,PNG,GIF,WEBP,SVG,OGG,MP4 OR MP3", 'userForm')
        } else {
            if (e.target && e.target.files) {
                const file = e.target.files[0];

                const imageName = e.target.files[0].name;

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = async function (e) {
                    await collectibleUpdateMulti([
                        {
                            name: 'image',
                            value: file
                        },
                        {
                            name: 'ipfsimg',
                            value: Buffer(reader.result)
                        },
                        {
                            name: 'imageName',
                            value: imageName
                        }
                    ]);
                }
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('handleSubmit : ');
        try {
            if(addrCntChk(accountData)) {
                collectible_Change({
                    name: 'createItemValid_chk',
                    value: true
                });
                formValid_click();
            }
        } catch (err) {
            console.log('err : ', err);
            CommonDataSave(dispatch, {loader: false}, commonData);
        }
    }

    const formValid_click = async (data = {}) => {
        console.log('formValid_click : ', data);
        try {
            const validResp = await CreateItemValidation_chk(collectible);
            CommonDataSave(dispatch, { loader: false }, commonData);
            console.log('validResp : ', validResp);

            if(validResp && Object.keys(validResp.errors).length > 0) {
                errors_set(validResp.errors);
            } else {
                errors_set({});
                if(data.target !== 'valid') {
                    mintModal_set(true);
                }
            }
        } catch (err) {
            console.log('err : ', err);
            CommonDataSave(dispatch, {loader: false}, commonData);
        }
    }

    const contractDet_upd = async (data = {}) => {
        const myArray = params.type.split("-");
        if(chainData.data && chainData.data.length > 0 && accountData && myArray && myArray.length === 3 && params && (myArray[2] === '721' || myArray[2] === '1155')) {
            const blockchainSymbol = myArray[0].toUpperCase();
            const standard = myArray[1].toUpperCase();
            const type = myArray[2];
            const chainDetail = chainData.data.find(e => (
                e.nativeCurrencySymbol.toUpperCase() === blockchainSymbol
                &&
                e.standard.toUpperCase() === standard
            ));
            if(chainDetail === undefined) {
                navigate("/", { replace: false });
            }
            else {
                const contractDet = await getCurContract({accountData, chainData});
                const curContractAbi = await getCurContractAbi();
                let contractDetails = {
                    blockchain: chainDetail,
                    collectionAddr: chainDetail[type.toString()],
                    abiJson: curContractAbi['ABI_'+chainDetail.networkVersion+'_'+type],
                    type: parseInt(type)
                }
                contractDet_set(contractDetails);
            }
        } else {
            // navigate("/", { replace: false });
        }
    }

    async function getProfile(viewUserUpd) {
        try{
            let passData = {};
            if(viewUserUpd.addr) {
                passData.addr = viewUserUpd.addr;
            }
            else if(viewUserUpd.customUrl) {
                passData.customUrl = viewUserUpd.customUrl;
            }
            if(passData.addr || passData.customUrl) {
                const { data } = await getuserProfile(passData);
                if(data.address) {
                    userDet_set(data);
                }
            }
        } catch(err){}
    }

    useEffect(async() => {
        contractDet_upd();
    }, [params, accountData, chainData]);

    useEffect(async() => {
        const {
            address: addr
        } = accountData;
        if(accountData.address) {
            getProfile({addr});
        }
    }, [accountData]);

    useEffect(async() => {
        if(collectible.createItemValid_chk === true) {
            formValid_click({ target: 'valid' });
        }
    }, [collectible]);

    useEffect(async() => {
        if(tokenData.data && tokenData.data.length > 0) {
            const value = tokenData.data[0].symbol;
            await collectibleUpdateMulti([{
                name: 'priceTokenForMint',
                value: value
            },{
                name: 'priceTokenForBid',
                value: value
            }]);
        }
    }, [tokenData]);

    const {
        image = {}
    } = userDet;

    let {
        profileImage = ''
    } = image;

    profileImage = profileImage !== '' ? profileImage : config.defaultImage.profileImage;
    profileImage = getSrcPath({src: profileImage, path: 'profile'});

    return (
        <div className='create-item'>
            <Header {...props} />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Create {contractDet.type === 721 ? 'Single' : 'Multiple'} Item</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Create-Item</Link></li>
                                    <li>{params.type}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    <div className="row">
                         <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                            <h4 className="title-create-item">
                                Preview item
                            </h4>
                            <div className="sc-card-product explode style2 mg-bt">
                                <div className="card-media">
                                    <Link to="/item-details">
                                        <FileCom
                                            src={collectible.image}
                                            path={''}
                                            imageName={collectible.imageName}
                                        />
                                    </Link>
                                    {/* <Link to="#" className="wishlist-button heart"><span className="number-like"> 0</span></Link> */}
                                    {collectible.method === 'auction' && <div className="featured-countdown">
                                        <span className="slogan"></span>
                                        <Countdown date={Date.now() + 500000000}>
                                            <span>You are good to go!</span>
                                        </Countdown>
                                    </div>}
                                </div>
                                <div className="card-title">
                                    <h5 className='curPointer'>{collectible.name}</h5>
                                </div>
                                <div className="meta-info">
                                    <div className="author">
                                        <div className="avatar">
                                            <img src={profileImage} alt="NFT Owner" />
                                        </div>
                                        <div className="info">
                                            <span>Creator</span>
                                            <h6>
                                                <h6 className='curPointer dotString'>{accountData.address}</h6>
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="tags">{contractDet?.blockchain?.nativeCurrencySymbol}</div>
                                </div>

                                <div className="card-bottom style-explode">
                                    { 
                                        (collectible.method === "fixedPrice" || collectible.method === "notForSale") ?
                                            (<div className="price">
                                                <span>Price</span>
                                                <div className="price-details">
                                                    <h5> {(collectible.method === "fixedPrice" && collectible.price > 0) ? collectible.price+ ' ' + contractDet?.blockchain?.nativeCurrencySymbol : 'Not for Sale'}</h5>
                                                </div>
                                            </div>)
                                        :
                                            (collectible.method === "auction") &&
                                                (<div className="price">
                                                    <span>Minimum Bid</span>
                                                    <div className="price-details">
                                                        <h5>{collectible.minimumbid > 0 ? collectible.minimumbid : 0} {contractDet?.blockchain?.nativeCurrencySymbol}</h5>
                                                    </div>
                                                </div>)
                                    }
                                    <Link to='#' className="view-history">View History</Link>
                                </div>
                            </div>
                         </div>
                         <div className="col-xl-9 col-lg-6 col-md-12 col-12">
                             <div className="form-create-item">
                                 <form action="#">
                                    <h4 className="title-create-item">Upload file</h4>
                                    <label className="uploadFile">
                                        <span className="filename">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</span>
                                        <input
                                            type="file"
                                            className="inputfile form-control"
                                            name="file"
                                            onChange={handleFile}
                                            // accept="image/png, image/gif, image/jpeg, image/jpg"
                                        />
                                    </label>
                                    <span className='error red'>{errors.ipfsimg}</span>
                                 </form>
                                <div className="flat-tabs tab-create-item">
                                    <h4 className="title-create-item">Put on marketplace</h4>
                                    <p>
                                        {collectible.method === "notForSale" ?
                                        "Put your new NFT on marketplace" :
                                        collectible.method === "fixedPrice" ?
                                        "Enter price to allow users instantly purchase your NFT" :
                                        collectible.method === "auction" && "Allow other users to make bids on your NFT"}
                                    </p>
                                    <Tabs>
                                        <TabList>
                                            <Tab onClick={() => collectibleUpdate({name:'method', value: 'fixedPrice'})}>
                                                <span className="icon-fl-buy"></span>Fixed Price
                                            </Tab>
                                            <Tab onClick={() => collectibleUpdate({name:'method', value: 'auction'})}>
                                                <span className="icon-fl-icon-22"></span>Open For Bids
                                            </Tab>
                                            <Tab onClick={() => collectibleUpdate({name:'method', value: 'notForSale'})}>
                                                <span className="icon-fl-tag"></span>Not For Sale
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <form action="#">
                                                <h4 className="title-create-item">Price</h4>
                                                <input
                                                    type="text"
                                                    placeholder="Item Price"
                                                    name='price'
                                                    value={collectible.price}
                                                    onChange={handleChange}
                                                />
                                                <span className='error red'>{errors.price}</span>
                                            </form>
                                        </TabPanel>
                                        <TabPanel>
                                            <form action="#">
                                                <h4 className="title-create-item">Minimum Bid</h4>
                                                <input
                                                    type="text"
                                                    placeholder="Minimum Bid"
                                                    name='minimumbid'
                                                    value={collectible.minimumbid}
                                                    onChange={handleChange}
                                                />
                                                <span className='error red'>{errors.minimumbid}</span>
                                            </form>
                                        </TabPanel>
                                        <TabPanel>
                                        </TabPanel>
                                    </Tabs>
                                    <form action="#">
                                        <h4 className="title-create-item">Title</h4>
                                        <input
                                            type="text"
                                            placeholder="Item Name"
                                            name='name'
                                            value={collectible.name}
                                            onChange={handleChange}
                                        />
                                        <span className='error red'>{errors.name}</span>

                                        <h4 className="title-create-item">Description</h4>
                                        <textarea
                                            placeholder="e.g. “This is very limited item”"
                                            name='description'
                                            value={collectible.description}
                                            onChange={handleChange}
                                        ></textarea>
                                        <span className='error red'>{errors.description}</span>

                                        <div className="row">
                                            {contractDet.type === 1155 &&
                                            <div className="col-md-12">
                                                <h4 className="title-create-item">Number Of Copies</h4>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Number Of Copies"
                                                    name='supply'
                                                    value={collectible.supply}
                                                    onChange={handleChange}
                                                />
                                                <span className='error red'>{errors.supply}</span>
                                            </div>
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5 className="title-create-item">
                                                    Size (Optional) 
                                                    <span onClick={handleAddSize} className='ml-2 icon-fl-add'></span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            collectible.attributes.map((input, index) => {
                                                return (
                                                    <div className="row" key={index}>
                                                        <div className="col-md-6">
                                                            <input
                                                                type="text"
                                                                name='trait_type'
                                                                value={input.name}
                                                                onChange={event => handleFormChange(index, event)}
                                                                placeholder="e.g. “size”"
                                                            />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <input
                                                                type="text"
                                                                name='value'
                                                                value={input.value}
                                                                onChange={event => handleFormChange(index, event)}
                                                                placeholder="e.g. M"
                                                            />
                                                        </div>
                                                        {
                                                            index > 0 && (
                                                                <div className="col-md-1">
                                                                    <span className='icon-fl-remove' onClick={() => handleRemoveSize(index)}></span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        <span className='error red'>{errors.attributes}</span>
                                    </form>
                                    <div className="mt-3">
                                        <button className="create-submit bold" onClick={handleSubmit}>Publish</button>
                                    </div>
                                </div>
                             </div>
                         </div>
                    </div>
                </div>
            </div>
            <CreateitemModal
                show={mintModal}
                onHide={(data = {}) => {
                    mintModal_set(false)
                }}
                collectible={collectible}
                contractDet={contractDet}
                collectibleUpdateMulti={collectibleUpdateMulti}
                collectibleUpdate={collectibleUpdate}
                errors={errors}
                errors_set={errors_set}
            />
            <Footer />
        </div>
    );
}

export default CreateItem;

import React,{useState,useEffect} from 'react';
import { useSelector } from 'react-redux';

const FollowBtn = (props) => {
    const accountData = useSelector(state => state.account);

    const {
        followText = 'Follow',
        userDet = {},
        followUser = () => {}
    } = props;
    const {
        address = ''
    } = userDet;

    const disabled = accountData && accountData.address.toLowerCase() === address
    return (
        !disabled ? <button
            type="button"
            className="sc-button style-1 follow curPointer"
            onClick={() => followUser({
                memberId: userDet._id
            })}
            disabled={disabled}
        >{followText}</button> : ''
    );
};

export default FollowBtn;
import React,{useState,useEffect} from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import contactUs from 'assets/images/contact-us.jpg';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import PageHeader from 'components/common/PageHeader';

import * as Api from "core/service/api/all.api";

const options = [
    { label: 'Technical issues', value: 'Technical issues' },
    { label: 'Other', value: 'Other' },
  ];

const initialFormValue = {
    name:"",
    email:"",
    message:"",
    subject:""
}
const contactSchema = Yup.object().shape({
    name: Yup
        .string()
        .matches(/^[a-zA-Z0-9]+$/, 'This field cannot contain white space and special character')
        .required('Name field is required'),
    // subject: Yup
    //     .string()
    //     .required('Subject field is required'),
    email: Yup
        .string()
        .email('Please enter valid email')
        .required('E-Mail field is required'),
    message: Yup
        .string()
        .min(10,'Minimum 10 characters')
        .max(100,'Maximum 100 characters')
        .required('Message field is required'),
});
const Contact = () => {
    const [subjectCat, subjectCat_set] = useState('');

    const formSubmit = async (payload) => {
        const data = {
            payload: {
                name:payload.name,
                email:payload.email,
                subject:subjectCat,
                message:payload.message
            }
        };
        await Api.contactUs(data);
    }
    
    const handlecatChange = (event) => {
        subjectCat_set(event.target.value);
    };
    return (
        <div>
            <Header />
            <PageHeader mainTitle="Contact Us" subTitle="Contact Us" />
            <section className="tf-contact tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="box-feature-contact">
                                <img src={contactUs} alt="Axies" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <h2 className="tf-title-heading style-2 mg-bt-12">
                                Drop Up A Message
                            </h2>
                            {/* <h5 className="sub-title style-1">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.
                            </h5> */}
                            <div className="form-inner">
                            <Formik
                                initialValues={initialFormValue}
                                validationSchema={contactSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    formSubmit(values);
                                }}
                                render ={({
                                    values,
                                    isSubmitting,
                                    handleChange
                                }) =>  (
                                    <Form>
                                        <input type="text"  tabIndex="1" aria-required="true"
                                            id="name" name="name" onChange={handleChange} 
                                            placeholder="Your Full Name" autoComplete='off'
                                        />
                                        <ErrorMessage name="name" className="error" component="div"/>
                                        <input type="text" tabIndex="2"  aria-required="true"
                                            id="email" name="email" onChange={handleChange} 
                                            placeholder="Your Email Address"  autoComplete='off'
                                        />
                                        <ErrorMessage name="email" className="error" component="div"/>
                                        <div className="row-form style-2" id="subject" name="subject">
                                            <select id='subject' name='subject' value={subjectCat} onChange={handlecatChange}>
                                                <option value="">Please Select</option>
                                                {options.map((option) => (
                                                <option value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <i className="icon-fl-down"></i>
                                        </div>
                                        <ErrorMessage name="subject" className="error" component="div"/>
                                        <textarea id="message" name="message" onChange={handleChange} tabIndex="3" aria-required="true"  placeholder="Message"></textarea>
                                        <ErrorMessage name="message" className="error" component="div"/>
                                        <button className="submit" type="submit">Send message</button>
                                    </Form>
                                )}> 
                            </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Contact;

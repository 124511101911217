import React, {
    useEffect, useState
} from 'react';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom'

import {
    addrCntChk,
    cancelReserveAuction_cont
} from "core/contract/index";

import { postMethod } from "core/service/api/common.api";
import apiService from "core/service/v1/";
import { toastAlert } from 'core/lib/toastAlert';

import ConnectWalletAlert from './ConnectWalletAlert';
import StepsBtn from 'components/separate/StepsBtn';
import * as  Constants from 'core/constant/';

const PriceModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { item, userSelOwner } = props;
    const accountData = useSelector(state => state.account);

    const [loader, loader_set] = useState(false);
    const [step, step_set] = useState(1);

    const handleSubmit = async(e) => {
        try{
            if((accountData && accountData.balance) === 0 || (typeof accountData && accountData.balance === "undefined")){
                toastAlert(false, "Insufficient Balance", 'balance');
                return false;
            }
            cancelAuction_click();
        }catch(err){}
    };

    const cancelAuction_click = async () => {
        try {
            if(addrCntChk(accountData)) {
                if(ownerOfAuction && ownerOfAuction.auctionId) {
                    const auctionId = ownerOfAuction.auctionId;
                    loader_set(true);
                    let cancelResp = await cancelReserveAuction_cont({
                        auctionId: auctionId,
                        contractSymbol: 'trade',
                        userAddress: accountData.address
                    });
                    loader_set(false);
                    if(cancelResp && cancelResp.status) {
                        const postData = {
                            apiUrl: apiService.cancelReserveAuction,
                            payload: {
                                "target":"cancelAuction",
                                "auctionId": auctionId,
                                "trxId": cancelResp.transactionHash
                            }
                        };
                        loader_set(true);
                        const resp = await postMethod(postData);
                        loader_set(false);
                        const { status, result, message } = resp;
                        if(status === 'success') {
                            step_set(2);
                            props.onHide({reloadData:true});
                            props.getInit();
                        }
                        if(message) {
                            toastAlert(status, message, 'userForm');
                        }
                    }
                }
            } else {
                loader_set(false);
            }
        } catch (err) {
            console.log('err : ', err);
            loader_set(false);
        }
    }

    const {
        ownerOfAuction
    } = item;

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                {
                !accountData || !accountData.address
                ?
                <ConnectWalletAlert />
                :
                <div className="modal-body space-y-20 pd-40">
                    <h3>Cancel Auction</h3>
                    <p>{Constants.AUCTION_CANCEL_DESC}</p>
                    <StepsBtn
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleSubmit()}
                        disabled={step!=1 || loader}
                        loader={step===1 && loader}
                        label={Constants.AUCTION_CANCEL_LABEL}
                    />
                </div>
                } 
            </div>
        </Modal>
    );
};

export default PriceModal;

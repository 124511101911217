// import lib
import { isEmpty, isStrEmpty } from './typeCheck';
import moment from 'moment';

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const firstLetterCase = (value) => {
    if (!isEmpty(value)) {
        return value.charAt(0).toUpperCase();
    }
    return ''
}

export const emailFormat = (email) => {
    try {
        if (!isEmpty(email)) {
            let domain = email.substring(email.indexOf('@'), email.indexOf('.'))
            domain = domain.substring(domain.length - 2, domain.length)
            return email.substring(0, 3) + "....@..." + domain + email.substring(email.indexOf('.'), email.length)
        }
        return ''
    } catch (err) {
        return ''
    }
}

export const cnvtBoolean = value => {
    if (typeof value === 'boolean' && value === true) return true;
    if (typeof value === 'boolean' && value === false) return false;
    if (typeof value === 'string' && value === 'true') return true;
    if (typeof value === 'string' && value === 'false') return false;
}

export const dateDiff = (startDate, endDate) => {
    let arrDate = ["seconds", "minutes", "hours", "days", "weeks", "months", "years"];
    let dateMap = arrDate.map(e => moment(endDate).diff(startDate, e));
    let index = 6 - dateMap.filter(e => e == 0).length;
    return {
        type: arrDate[index] ?? "seconds",
        value: dateMap[index] ?? 0
    };
}

export const midEllipsis = (content, data = {}) => {
    try {
        const {
            maxLen = 12,
            visibleLength = 7
        } = data;
        if (!isStrEmpty(content)) {
            return content.length > maxLen ? content.substring(0, visibleLength)+'...'+content.substring(content.length - 4) : content;
        }
        return content;
    } catch (err) {
        return '';
    }
}

export const nameOrAddrShow = (data = {}, options = {}) => {
    try {
        const {
            address = '',
            firstName = ''
        } = data;
        const content = firstName ? firstName: address;
        return midEllipsis(content, options);
    } catch (err) {
        return '';
    }
}
import React, { useRef, useState, useEffect } from 'react';
import { Link , useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import menus from "pages/menu";
import DarkMode from './DarkMode';

import logodarkDefault from 'assets/images/logo/default-logo.png';
import avt from 'assets/images/avatar/avt-2.jpg';

import { getMethod } from "core/service/api/common.api";
import apiService from "core/service/v1/";
import Web3 from 'web3';

import { web3Upd, connectUserWallet, accountDisconnect, readContract } from 'core/contract';

import Config from 'core/config';
import { setAuthorization, removeAuthorization } from 'core/config/axios';
import { getWalletConn, removeWalletConn, getAuthToken, removeAuthToken } from 'core/lib/localStorage';

import { walletDisConn, walletConn } from 'core/redux/action/account.action';
import { ChainDataSave } from 'core/redux/action/chain.action';

import { toastAlert } from 'core/lib/toastAlert';
import { copyText } from 'core/lib/common';
import { midEllipsis, dateDiff } from 'core/lib/JSHelper';
import { getSrcPath } from 'core/lib/fileHelper';

import { saveAuthTokenFromApi } from 'core/service/api/member.api';
import { CommonDataSave } from 'core/redux/action/common.action';
import { MemberDataSave } from 'core/redux/action/member.action';

import LoaderFullComp from 'components/separate/LoaderFullComp';
import { config } from 'process';

const WALLET_CONNECT = 'Wallet Connect';
const WALLET_META_MASK = 'Metamask';
const WALLET_BINANCE = 'Binance Wallet';
const CLI_METHOD_MANUAL = 'manual';
const CLI_METHOD_AUTO = 'auto';

const Header = (props) => {
    const { pathname, search } = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accountData = useSelector(state => state.account);
    const tokenData = useSelector(state => state.token);
    const chainData = useSelector(state => state.chain);
    const memberData = useSelector(state => state.member);
    const commonData = useSelector(state => state.common);
    const headerRef = useRef (null);

    const { IMG_URL } = Config;
    const {
        address: accAddress = null,
        balance: accBalance,
        currency: currency
    } = accountData;

    const connectChkCall = async () => {
        setTimeout(async () => {
            let resp = false;
            let walletConnDet = getWalletConn();
            if(walletConnDet === WALLET_META_MASK || walletConnDet === WALLET_BINANCE || walletConnDet === WALLET_CONNECT) {
                const targetWalObj = {
                    target: walletConnDet,
                    cliMethod: CLI_METHOD_AUTO,
                    chainData: chainData
                };
                resp = await connectUserWallet(dispatch, targetWalObj);
            }
            if(resp === false) {
                const walletConObj = {
                    isWalletConn: false,
                    address: '',
                    balance: 0,
                    wallet: walletConnDet,
                    networkVersion: '',
                    checked: true,
                };
                walletConn(dispatch, walletConObj);
            }
        }, 1000);
    }

    async function chainList() {
        try{
            const postData = { apiUrl: apiService.chainList };
            const resp = await getMethod(postData);
            const { status, message, result } = resp;
            CommonDataSave(dispatch, {afterChainDataGet: true});
            if(resp && status === 'success' && result) {
                ChainDataSave(dispatch, result);
            }
        } catch(err) {}
    }

    async function fetchFeeData() {
        try {
            if(accountData.address && chainData.data) {
                await web3Upd({
                    accountData,
                    chainData,
                    tokenData
                });

                let idx = chainData.data.findIndex(e => e.networkVersion === accountData.networkVersion);
                if (idx > -1) {
                    const feeObj = {
                        method: 'getFeeConfig',
                        contractSymbol: 'trade'
                    };
                    const feeResp = await readContract(feeObj);
                    const {
                        primaryFoundationFeeBasisPoints = 0,
                        secondaryFoundationFeeBasisPoints = 0,
                        secondaryCreatorFeeBasisPoints = 0
                    } = feeResp;
                    CommonDataSave(dispatch, {
                        fee: {
                            primaryFoundationFeeBasisPoints: primaryFoundationFeeBasisPoints ? primaryFoundationFeeBasisPoints / 100: 0,
                            buyerServiceFee: secondaryFoundationFeeBasisPoints ? secondaryFoundationFeeBasisPoints / 100: 0,
                            sellerServiceFee: secondaryCreatorFeeBasisPoints ? secondaryCreatorFeeBasisPoints / 100: 0
                        },
                    });
                }
            }
        }
        catch(err) {
            console.log('fetchFeeData err : ', err);
        }
    }

    const menuLeft = useRef(null);
    const btnToggle = useRef(null);
    const btnSearch = useRef(null);

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }

    const searchBtn = () => {
        btnSearch.current.classList.toggle('active');
    }

    const [activeIndex, setActiveIndex] = useState(null);
    const handleOnClick = index => {
        setActiveIndex(index); 
    };

    const [popup, setPopup] = useState(false);
    const handleOnPopup = val => {
        setPopup(val); 
    };

    const connectWallet = async (data = {}) => {
        connectUserWallet(dispatch, data);
    }

    const disconnectWallet = async (from = '') => {
        await accountDisconnect();
        await walletDisConn(dispatch, {
            isWalletConn: false,
            address: '',
            balance: 0,
            wallet: getWalletConn(),
            networkVersion: '',
            checked: true
        });
        await MemberDataSave(dispatch, { firstName: '', nameShow: '', address: '', image: {}, createdAt: '' });
        removeWalletConn();

        if(from !== 'changed') {
            toastAlert('success', "Wallet Disconnect successfully", 'DisConnectWallet');
        }

        if(getAuthToken()) {
            removeAuthorization();
            removeAuthToken();
        }
        return 'from disconnectWallet';
    }

    async function fetchSettingsData(){
        try{
            const postData = { apiUrl: apiService.siteSettings };
            const resp = await getMethod(postData);
            let { status, data } = resp;
            if(status === "success" && data) {
                if(data.siteLogo) {
                    const siteLogoSrc = IMG_URL + "/settings/" + data.siteLogo;
                    data.logodark = siteLogoSrc;
                    data.logolight = siteLogoSrc;
                }
                CommonDataSave(dispatch, {settingsData: data});
            }
        } catch(err) {}
    }

    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
        scrollTop >= 300 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 400 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };

    const getInitialize = async () => {
        console.log('getInitialize in header');
        await chainList();
        fetchSettingsData();
    }

    const afterChainDataGet_call = async(e) => {
        if(accountData.checked && commonData.afterChainDataGet === true) {
            if(accountData && accountData.address) {
                if( props && props.restrictDet && props.restrictDet.beforeSigninOnly === true) {
                    const { previousPath = null } = commonData;
                    if(previousPath){
                        navigate(previousPath, { replace: false });
                    } else {
                        navigate("/", { replace: false });
                    }
                }

                const data = { address: accountData.address };
                const resp = await saveAuthTokenFromApi(data);
                const { status, token, memberDet = {} } = resp;

                const {
                    firstName, nameShow = '', image = {}, createdAt, address
                } = memberDet;

                if(image.profileImage) {
                    memberDet.image.profileImageUrl = await getSrcPath({src: image.profileImage, path: 'profile'})
                }
                if(status) {
                    CommonDataSave(dispatch, { token: token }, commonData);
                    MemberDataSave(dispatch, { firstName, nameShow, address, image, createdAt });
                    setAuthorization(token);
                }
            } else {
                if( props && props.restrictDet && props.restrictDet.afterSigninOnly === true) {
                    navigate("/wallet-connect", { replace: false });
                }
            }
        }
    }

    const dayWiseDemoRestrict = (e) => {
        const { createdAt, firstName, email } = memberData;
        if(createdAt != '') {
            const timeDiffObj = dateDiff(new Date(createdAt), new Date());
            const { type, value } = timeDiffObj;
            const aboveDays = ["days", "weeks", "months", "years"];
            if(Config.appEnvMode == 'demo2') {
                const { demo = {} } = props;
                const { page = '' } = demo;
                if(page == 'mint' || page == 'detail') {
                    if(aboveDays.find(e => e === type) !== undefined) {
                        if(type == 'days' && (value >=2 || value <=10) && (firstName == '' || email == '')) {
                            toastAlert('info', "Please update your profile  .", 'demoNotify');
                            navigate("/edit-profile", { replace: false });
                        }
                        if(aboveDays[type] || (type == 'days' && value >10)) {
                            toastAlert('info', "Your demo time period is over, please contact admin.", 'demoEnd');
                            navigate("/", { replace: false });
                        }
                    }
                }
            }
        }
    }

    const setMenuVal = (e) => {
        const {
            singleUrl = '',
        } = accountData;
        if(menus && menus.length > 0 && singleUrl != ''){
            const menusIndex = menus.findIndex(e => e.name === 'Create Item');
            if(menusIndex > -1) {
                menus[menusIndex].links = singleUrl;
            }
        }
    }

    useEffect(() => {
        const {
            currentPath = null
        } = commonData;
        if (pathname !== currentPath) {
            CommonDataSave(dispatch, {
                previousPath: currentPath,
                currentPath: pathname
            })
        }
    }, [pathname]);

    useEffect(() => {
        const getInit = async () => {
            getInitialize();
        }
        getInit();
    }, []);

    useEffect(async() => {
        afterChainDataGet_call();
        setMenuVal();
    }, [accountData]);

    useEffect(async () => {
        if(commonData.afterChainDataGet === true) {
            await connectChkCall();
        }
    }, [chainData]);

    useEffect(async () => {
        if(accountData.address && commonData.afterChainDataGet === true) {
            await fetchFeeData();
        }
    }, [accountData]);

    useEffect(async() => {
        dayWiseDemoRestrict();
    }, [memberData]);

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const afterWalletListener = async (from = '') => {
        const walletConnVal = getWalletConn();
        if(walletConnVal === WALLET_META_MASK || walletConnVal === WALLET_BINANCE) {
            await disconnectWallet('changed');
            await connectWallet({
                target: walletConnVal,
                cliMethod: 'changed',
                chainData: chainData
            });
        }
    }

    window.addEventListener('load',  function () {
        try {
            if (window.ethereum) {
                var web3 = new Web3(window.ethereum);
                if (typeof web3 !== 'undefined') {
                    window.ethereum.on('accountsChanged', async function (accounts) {
                        afterWalletListener({})
                    })
                    window.ethereum.on('networkChanged', async function (networkId) {
                        afterWalletListener({})
                    })
                }
            }
            if(window.BinanceChain) {
                window.BinanceChain.on('accountsChanged', async function (accounts) {
                    afterWalletListener({})
                })
                window.BinanceChain.on('networkChanged', async function (networkId) {
                    afterWalletListener({})
                })
            }
        } catch(err){
            console.log('addEventListener : err : ', err);
        }
    });

    const { settingsData = {} } = commonData;

    // const {
    //     logodark: logodarkSite = logodarkDefault,
    //     logolight: logolightSite = logodarkDefault,
    // } = settingsData;

    const {
        logodark: logodarkSite = '',
        logolight: logolightSite = '',
    } = settingsData;

    const { nameShow = '', image = {} } = memberData;

    const { profileImageUrl = '' } = image;

    return (
        <header id="header_main" className="header_1 js-header" ref={headerRef}>
            {(commonData.loader || props.loader) && <LoaderFullComp />}
            {/* <LoaderFullComp /> */}
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">                              
                        <div id="site-header-inner">
                            <div className="wrap-box flex">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <Link to="/" rel="home" className="main-logo">
                                            {logodarkSite && <img className='logo-dark' src={logodarkSite} alt="nft-gaming" />}
                                            {logolightSite && <img className='logo-light' src={logolightSite} alt="nft-gaming" />}
                                        </Link>
                                    </div>
                                </div>
                                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
                                <nav id="main-nav" className="main-nav" ref={menuLeft} >
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus.map((data,index) => {
                                                const { namesub, name, links } = data;
                                                return (
                                                    <li 
                                                        key={index} 
                                                        onClick={()=> handleOnClick(index)}
                                                        className={`menu-item ${namesub ? 'menu-item-has-children' : '' } ${activeIndex === index ? 'active' : ''}`}>
                                                        <Link to={links}>{name}</Link>
                                                        {
                                                            namesub &&
                                                            (<ul className="sub-menu" >
                                                                {
                                                                    namesub.map((submenu) => {
                                                                        const { id, sub, links } = submenu;
                                                                        return (
                                                                            <li key={id} className={
                                                                                pathname === links
                                                                                ? "menu-item current-item"
                                                                                : "menu-item"
                                                                            }>
                                                                                <Link to={links}>{sub}</Link>
                                                                            </li>
                                                                        );
                                                                    })
                                                                }
                                                            </ul>)
                                                        }
                                                        
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </nav>
                                <div className="flat-search-btn flex">
                                    <div className="header-search flat-show-search" id="s1">
                                        <Link to="#" className="show-search header-search-trigger" onClick={searchBtn}>
                                            <i className="far fa-search"></i>
                                        </Link>
                                        <div className="top-search" ref={btnSearch}>
                                            <form action="/explore/" method="get" role="search" className="search-form">
                                                <input type="search" id="s" className="search-field" placeholder="Search..." name="searchKey" title="Search for" required="" />
                                                <button className="search search-submit" type="submit" title="Search">
                                                    <i className="icon-fl-search-filled"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    {
                                        accAddress
                                        ?
                                        (<div className={ accAddress ? 'connect-wal' : ''}>
                                            <div className="admin_active menu" id="header_admin">
                                                <div className="header_avatar infor-profile" >
                                                    <div className="price" onClick={ () => handleOnPopup(!popup) }>
                                                        <span>{accBalance}<strong> {currency} </strong></span>
                                                    </div>
                                                    <img
                                                        className="avatar"
                                                        src={profileImageUrl ? profileImageUrl : avt}
                                                        alt="avatar"
                                                        onClick={ () => handleOnPopup(!popup) }
                                                    />
                                                    <div className={ "avatar_popup mt-20" + ( popup ? ' visible': '' )} >
                                                        <h4>{'User Name'}</h4>
                                                        <div className="d-flex align-items-center mt-10 mg-bt-12">
                                                            <div className="info">
                                                                <p>{midEllipsis(nameShow)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center mt-20 mg-bt-12">
                                                            <div className="info">
                                                                <p>Balance</p>
                                                                <p className="style">{accBalance} {currency}</p>
                                                            </div>
                                                        </div>
                                                        <p>Wallet</p>
                                                        <div className="d-flex align-items-center justify-content-between mg-t-5 mg-bt-17">
                                                            <p>{midEllipsis(accAddress)}</p>
                                                            <Link to="#" className="ml-2 prcolor1">
                                                                <i className="fal fa-copy" onClick={() => copyText(accAddress)}></i>
                                                            </Link>
                                                        </div>
                                                        {/* <div className="d-flex align-items-center copy-text justify-content-between">
                                                            <span>{midEllipsis(accAddress)}</span>
                                                            <Link to="/" className="ml-2">
                                                                <i className="fal fa-copy"></i>
                                                            </Link>
                                                        </div> */}
                                                        {/* <div className="d-flex align-items-center mt-10">
                                                            <img
                                                                className="coin"
                                                                src={imgsun ? imgsun : ''}
                                                                alt="/"
                                                                />
                                                            <div className="info ml-10">
                                                                <p className="text-sm font-book text-gray-400">Balance</p>
                                                                <p className="w-full text-sm font-bold text-green-500 style">{accBalance} {currency}</p>
                                                            </div>
                                                        </div> */}
                                                        <div className="hr"></div>
                                                        <div className="links mt-20">
                                                            <Link to="/items/created">
                                                                <i className="fab fa-accusoft"></i> <span>My Items</span>
                                                            </Link>
                                                            <Link to="/edit-profile" className="mt-10">
                                                                <i className="fas fa-pencil-alt">&nbsp;</i> <span>Edit Profile</span>
                                                            </Link>
                                                            <Link to="#" className="mt-10" onClick={() => disconnectWallet()}>
                                                                <i className="fal fa-sign-out">&nbsp;</i> <span>Logout</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                        :
                                        (<div className="sc-btn-top mg-r-12" id="site-header">
                                            <Link to="/wallet-connect"
                                                // onClick={() => { CommonDataSave(dispatch, {beforeWalletConnect: pathname+search}); }}
                                                className="sc-button header-slider style style-1 wallet fl-button pri-1">
                                                <span>Wallet connect</span>
                                            </Link>
                                        </div>)
                                    }
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <DarkMode />
            {/* CommonData : {JSON.stringify(commonData)} */}
        </header>
    );
}

export default Header;

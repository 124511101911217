import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ItemBox from '../item/ItemBox';

import { getAllNft } from '../../../core/service/api/nft.api';

import apiService from "../../../core/service/v1/";
import { postMethod } from "../../../core/service/api/common.api";
import { toastAlert } from '../../../core/lib/toastAlert';

const filterInitObj = {
    chains: [],
    methods: [],
    status: [],
    categories: [],
    price: {
        currency: '',
        min: '',
        max: ''
    },
    todayPicks: true
};

const TodayPicks = props => {
    
    const [visible , setVisible] = useState(8);

    const data = props.data;

    const accountData = useSelector(state => state.account);

    const [modalShow, setModalShow] = useState(false);

    const [nftListData, set_nftListData] = useState([]);
    const [nftListTotalData, set_nftListTotalData] = useState(0);
    const [currentPage, set_currentPage] = useState(1);
    const [perPage, set_perPage] = useState(8);
    const [hasMoreVal, set_hasMoreVal] = useState(true);
    const [filter, setFilter] = useState(filterInitObj);

    const loadNftList = async (page = 1) => {
        const payload = {
            page: currentPage,
            perPage,
            filter,
            section: 'todayPicks'
        };
        const { data, count } = await getAllNft(payload);
        if(data.length > 0) {
            if(count !== undefined) {
                set_nftListTotalData(count);
            }
            const newlist = [...nftListData, ...data];
            set_nftListData(newlist);
        } else {
            set_hasMoreVal(false);
        }
    }

    const handleNextPage = () => {
        set_currentPage(currentPage + 1);
    };

    async function LikeNft(item) {
        try {
            const { _id: itemId } = item;
            const { address: userAddress  } = accountData;
            const { like: apiUrl  } = apiService;
            const postData = {
                apiUrl,
                payload: {
                    targetId: itemId,
                    userAddress
                },
            };
            const resp = await postMethod(postData);
            const { status, data, message } = resp; 
            if(status === 'success' && data) {
                const nftListDataNew = [...[], ...nftListData];
                const indx = nftListDataNew.findIndex(e => e._id === itemId);
                if(indx > -1) {
                    nftListDataNew[indx].likeCount = data.likeCount;
                    set_nftListData(nftListDataNew);
                }
            }
            if(message) {
                toastAlert(status, message, 'userForm');
            }
        }catch(err){
            console.log('err : LikeNft : ', err);
        }
    }

    useEffect(async () => {
        await loadNftList();
    }, [currentPage]);

    return (
        <Fragment>
            <section className="tf-section today-pick">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <h2 className="tf-title pb-11">Today's Picks</h2>
                                <div className="heading-line s1"></div>
                            </div>
                        </div>
                        {nftListData && nftListData.length === 0 && <div className="col-md-12"><div className="tf-title"><h4>No Items Found</h4></div></div>}
                        {
                            nftListData.map((item, index) => (
                                <div key={index} className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <ItemBox
                                        item={item}
                                        nftListData={nftListData}
                                        set_nftListData={set_nftListData}
                                    />
                                </div>  
                            ))
                        }
                        {
                            nftListTotalData > nftListData.length && 
                            <div className="col-md-12 wrap-inner load-more text-center mg-t-9"> 
                                <Link
                                    to="#"
                                    id="load-more"
                                    className="sc-button loadmore fl-button pri-3"
                                    onClick={() => handleNextPage()}
                                ><span>Load More</span></Link>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </Fragment>
    );
}



TodayPicks.propTypes = {
    data: PropTypes.array.isRequired,
}


export default TodayPicks;

import apiService from "core/service/v1/";
import { request } from "core/service/api/common.api";

export const contactUs = async (data) => {
  const requestData = {
    method: "post",
    apiUrl: apiService.contactUs,
    payload: data.payload,
  };
  const resp = await request(requestData);
  return resp;
};

export const subscribe = async (data) => {
  const requestData = {
    method: "post",
    apiUrl: apiService.subscribe,
    payload: data.payload,
  };
  const resp = await request(requestData);
  return resp;
};

export const nftDetail = async (data) => {
  const requestData = {
    method: "post",
    apiUrl: apiService.nftDetail,
    payload: data.payload,
  };
  const resp = await request(requestData);
  return resp;
};

export const followList = async (data) => {
  const { payload = {} } = data;
  const { target, userId } = payload;
  const requestData = {
    method: "get",
    apiUrl: apiService.followList + "/" + userId + "/" + target,
    payload: data.payload,
  };
  const resp = await request(requestData);
  return resp;
};

export const faqlist = async (data) => {
  const requestData = {
    method: "get",
    apiUrl: apiService.faqlist,
  };
  const resp = await request(requestData);
  return resp;
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getTopSellers } from '../../../core/service/api/nft.api';
import { getSrcPath } from '../../../core/lib/fileHelper';
import { midEllipsis } from '../../../core/lib/JSHelper';

const TopSeller = props => {
    const { data } = props;
    
    const [topSellers, setTopSellers] = useState([]);

    const loadTopSellers = async () => {
        const topSellerResp = await getTopSellers();
        setTopSellers( topSellerResp.data.length > 0 ? topSellerResp.data : []);
    }

    useEffect(async () => {
        await loadTopSellers();
    }, []);

    return (
        <section className="tf-section top-seller bg-home-3">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <h2 className="tf-title style2">Top Seller</h2>
                            <div className="heading-line s1"></div>
                        </div>
                    </div>
                    {topSellers && topSellers.length === 0 && <div className="col-md-12"><div className="tf-title"><h4>No Seller Found</h4></div></div>}
                    {
                        topSellers.map((item,index) => (
                            <TopSellerItem key={index} item={item} />
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

const TopSellerItem = props => {
    const { item } = props;
    const { userData, total } = item;
    const { nameShow: fName } = userData;
    const fUserLink = "/user/" + fName + "/created";
    const totalString = parseFloat(total).toFixed(6) + ' USD';
    console.log('fName::', fName);

    return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div className="sc-author-box">
                <div className="author-avatar">
                    <Link to="/author">
                        <img 
                            src={getSrcPath({src:userData.image.profileImage, path:'profile'} )} 
                            alt="Troniex" 
                            className="avatar" />
                    </Link>
                    <div className="badge"><i className="ripple"></i></div>
                </div>
                <div className="author-infor">
                    <h5 className="style2">
                        <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </h5>
                    <span className="price">{totalString}</span>
                </div>
            </div>    
        </div>
    ); 
}


export default TopSeller;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import BidModal from '../modal/BidModal';
import PriceModal from '../modal/PriceModal';
import Countdown from "react-countdown";

import apiService from "core/service/v1/";
import { postMethod } from "core/service/api/common.api";
import { toastAlert } from 'core/lib/toastAlert';
import { getSrcPath } from 'core/lib/fileHelper';
import FileCom from 'components/separate/FileCom';

const ItemBox = props => {

    const accountData = useSelector(state => state.account);
    
    const { item, nftListData, set_nftListData } = props;

    const { blockchain, type, collectionAddr, tokenId, name,
        image, likeCount, ownerOfAuction = {},
        creator, owner, feature,
        itemPrice, salePrice, auctionPrice,
    } = item;

    const userSelOwner = owner;

    const {
        auctionDetail
    } = ownerOfAuction;

    const { nativeCurrencySymbol } = blockchain;
    const { ownerAddr, price, ownerInfo, method } = owner;
    const { image: ownerImage } = ownerInfo;
    const { profileImage } = ownerImage;
    const { address: accAddress } = accountData;
    const tokenDetUrl = "/token/"+ collectionAddr + "/" + tokenId;
    const tokenDetHisUrl = tokenDetUrl + "?tab=history";
    const creatorProfileUrl = "/user/" + creator.address;
    const nftImgSrc = image;

    const [currentItem, currentItem_set] = useState({});
    const [priceModal, priceModal_set] = useState(false);
    const [bidModal, bidModal_set] = useState(false);
    const [bidLive, bidLive_set] = useState(true);

    async function getInit(hitItem) {
    }

    async function LikeNft(hitItem) {
        try {
            const { _id: itemId } = hitItem;
            const { address: userAddress  } = accountData;
            const { like: apiUrl  } = apiService;
            if(userAddress) {
                const postData = {
                    apiUrl,
                    payload: {
                        targetId: itemId,
                        userAddress
                    },
                };
                const resp = await postMethod(postData);
                console.log('resp : ', resp);
                const { status, data, message } = resp; 
                if(status === 'success' && data) {
                    const nftListDataNew = [...[], ...nftListData];
                    const indx = nftListDataNew.findIndex(e => e._id === itemId);
                    if(indx > -1) {
                        nftListDataNew[indx].likeCount = data.likeCount;
                        set_nftListData(nftListDataNew);
                    }
                }
                if(message) {
                    toastAlert(status, message, 'userForm');
                }
            }
            else {
                toastAlert('error', 'Please connect wallet and continue', 'userLogin');
            }
        } catch(err) {
            console.log('err : LikeNft : ', err);
        }
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            if(
                (accountData.address && auctionDetail.buyer && auctionDetail.buyer.toLowerCase() === accountData.address)
                ||
                (accountData.address && auctionDetail.seller && auctionDetail.seller.toLowerCase() === accountData.address)
            ) {
                return <span onClick={() => Navigate('/token/'+collectionAddr+'/'+tokenId)}>Claim</span>
            }
            else {
                bidModal_set(false);
                bidLive_set(false);
                return <span>Completed</span>
            }
        } else {
            return <span>{hours}:{minutes}:{seconds}</span>;
        }
    };

    return (
        <>
            <div className={`sc-card-product explode style2 mg-bt mr-3 ${feature ? 'comingsoon' : '' } `}>
                <div className="card-media">
                    <Link to={tokenDetUrl}>
                        <FileCom
                            src={nftImgSrc}
                            path={'nft'}
                        />
                    </Link>
                    <div className="button-place-bid">
                        {
                            ( ownerAddr && ownerAddr.toLowerCase() === accAddress.toLowerCase() ) ?
                            (<button
                                onClick={() => {
                                    currentItem_set(item);
                                    priceModal_set(true);
                                }} 
                                className="sc-button style-place-bid style bag fl-button pri-3">
                                <span>{price === 0 ? 'Put On Sale' : 'Change Price'}</span>
                            </button>)
                            :
                            accountData.address &&
                            auctionDetail &&
                            auctionDetail.nftContract &&
                            auctionDetail.bidder &&
                            auctionDetail.bidder?.toLowerCase() !== accountData.address.toLowerCase() &&
                            auctionDetail.seller?.toLowerCase() !== accountData.address.toLowerCase() &&
                            method === "auction" &
                            bidLive &&
                            (<button
                                onClick={() => {
                                    currentItem_set(item);
                                    bidModal_set(true);
                                }}
                                className="sc-button style-place-bid style bag fl-button pri-3">
                                <span>Place A Bid</span>
                            </button>)
                        }
                    </div>
                    {
                        method === "auction" && auctionDetail.endTime &&
                        (<div className="featured-countdown">
                            <span className="slogan"></span>
                            <Countdown
                                date={(auctionDetail?.endTime * 1000)}
                                renderer={renderer}
                            />
                        </div>)
                    }
                    <span 
                        onClick={ () => LikeNft(item) } 
                        className="wishlist-button heart curPointer">
                        <span className={"number-like"}>{likeCount}</span>
                    </span>
                    <div className="coming-soon">{feature}</div>
                </div>
                <div className="card-title">
                    <h5><Link to={tokenDetUrl}>"{name}"</Link></h5>
                </div>
                <div className="meta-info">
                    <Link to={creatorProfileUrl}>
                        <div className="author">
                            <div className="avatar">
                                <img src={getSrcPath({src:profileImage, path: 'profile'})} alt="profile" />
                            </div>
                            <div className="info">
                                <span>Creator</span>
                                <h6>
                                    <p className="dotString">{creator.nameShow}</p>
                                </h6>
                            </div>
                        </div>
                    </Link>
                    <div className="tags"> {nativeCurrencySymbol}</div>
                </div>
                <div className="card-bottom style-explode">
                    <div className="price">
                        <span>{method === "auction" && auctionPrice > 0 ? 'Minimum Bid' : 'Price' }</span>
                        <div className="price-details">
                            {itemPrice ?
                            <h5>{itemPrice} {nativeCurrencySymbol}</h5>:
                            <h5>Not for sale</h5>}
                        </div>
                    </div>
                    <Link to={tokenDetHisUrl} className="view-history">View History</Link>
                </div>
            </div>
            <BidModal
                show={bidModal}
                onHide={(data = {}) => {
                    bidModal_set(false)
                }}
                item={currentItem}
                userSelOwner={userSelOwner}
                ownerOfAuction={ownerOfAuction}
                getInit={() => getInit()}
            />
            <PriceModal
                show={priceModal}
                onHide={(data = {}) => {
                    priceModal_set(false)
                }}
                item={currentItem}
                userSelOwner={userSelOwner}
                getInit={() => getInit()}
            />
        </>
    );
}

export default ItemBox;

import React, { useState, } from 'react';

import { getSrcPath, getSrcTypeFromName, getSrcType } from 'core/lib/fileHelper';

import img1 from 'assets/images/box-item/image-box-6.jpg'
import { useEffect } from 'react';

const FileCom = (props) => {

    const {path, src, imageName} = props;
    const [pathSrc, setPathSrc] = useState('');
    const [mimeType, setMimeType] = useState('image');

    const srcType = async () => {
        if(src) {
            const newSrcVal = await getSrcPath({src, path});
            setPathSrc(newSrcVal);
            const type = imageName ? await getSrcTypeFromName(imageName) : await getSrcType(newSrcVal);
            setMimeType(type.mimeType);
        }
        else {
            setPathSrc(img1);
        }
    }

    useEffect(()=>{
        async function typeget() {
            await srcType();
        }
        typeget();
    }, [src])

    return (
        <>
            {
                pathSrc && <>{
                    mimeType == 'image'
                    ?
                        <img src={pathSrc} />
                    :
                        mimeType == 'video'
                        ?
                            <video width="400" controls>
                                <source src={pathSrc} type="video/mp4" />
                                Your browser does not support HTML video.
                            </video>
                            :
                            mimeType == 'audio'
                            ?
                                <audio controls>
                                    <source src={pathSrc} type="audio/mp3" />
                                    Your browser does not support the audio tag.
                                </audio>
                            :''
                }</>
            }
        </>
    )
}

export default FileCom;
import { toastAlert } from './toastAlert';

export const consoleLog = (data) => {
    console.log('--- consoleLog : ', data);
}

export const copyText = (text) => {
    navigator.clipboard.writeText(text).then(function() {
        toastAlert('info', 'Copied!', 'copyUrl');
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}
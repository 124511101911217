import React , {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import apiService from "core/service/v1/";
import { postMethod } from "core/service/api/common.api";

import { dateDiff, midEllipsis } from 'core/lib/JSHelper';
import { getSrcPath } from 'core/lib/fileHelper';

const ActivityTab = (props) => {

    const accountData = useSelector(state => state.account);
    const { item } = props;

    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [activityList, setActivityList] = useState([]);
    const [activityCount, setActivityCount] = useState([]);

    const {
        _id: itemId = ''
    } = item;

    useEffect(() => {
        if(accountData && accountData.address) {
            getActivityList();
        }
    }, [accountData, page]);

    async function getActivityList( type = null ) {
        try {
            const target = 'itemDetail';
            const targetId = itemId;
            const postData = {
                apiUrl: apiService.activitylist,
                payload: type === null ? { page, perPage, target, targetId } : { type, page, perPage, target, targetId }
            };
            const resp = await postMethod(postData);
            if(resp && resp.status && resp.data) {
                if(activityList.length > 0) {
                    let newArr = [...activityList, ...resp.data]; // copying the old datas array
                    console.log('newArr::', newArr);
                    setActivityList(newArr);
                } else {
                    setActivityList(resp.data);
                }
                setActivityCount(resp.count);
            }
        } catch(err) {
            console.log('err::', err);
        }
    }

    const [visible, setVisible] = useState(5);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 5);
        setPage(page + 1);
        getActivityList();
    }

    const getMessage = (type, fromAddrObj, toAddrObj) => {
        const { nameShow: fName } = fromAddrObj;
        const fUserLink = "/user/" + fName + "/created";

        let tName = '', tUserLink = '';
        if(toAddrObj) {
            const {nameShow: tNameVal} = toAddrObj;
            tName = tNameVal;
            tUserLink = "/user/" + tName + "/created";
        }

        let msg, icon;
        switch (type) {
            case 'mintNft':
                icon = 'icon-1';
                msg = (
                    <>
                        Token minted by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'burn':
                icon = 'icon-1';
                msg = (
                    <>
                        Token burned by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'like':
                icon = 'icon-1';
                msg = (
                    <>
                        Token like by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'unLike':
                icon = 'icon-1';
                msg = (
                    <>
                        Token unlike by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'putOnSale':
                icon = 'icon-1';
                msg = (
                    <>
                        Token listed by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'changePrice':
                icon = 'icon-2';
                msg = (
                    <>
                        Token relisted by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'cancelOrder':
                icon = 'icon-3';
                msg = (
                    <>
                        Token unlisted by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'transfer':
                icon = 'icon-4';
                msg = (
                    <>
                        Token transfered from <Link to={fUserLink}>{midEllipsis(fName)}</Link> to <Link to={tUserLink}>{midEllipsis(tName)}</Link>
                    </>
                );
                break;
            case 'purchase':
                icon = 'icon-4';
                msg = (
                    <>
                        Token purchased from <Link to={fUserLink}>{midEllipsis(fName)}</Link> to <Link to={tUserLink}>{midEllipsis(tName)}</Link>
                    </>
                );
                break;  
            case 'createReserveAuction':
                icon = 'icon-5';
                msg = (
                    <>
                        Token put in auction by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'updateReserveAuction':
                icon = 'icon-5';
                msg = (
                    <>
                        Auction price updated by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'cancelReserveAuction':
                icon = 'icon-5';
                msg = (
                    <>
                        Auction cancelled by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
            case 'finalizeReserveAuction':
                icon = 'icon-5';
                msg = (
                    <>
                        Auction completed by <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                    </>
                );
                break;
        
            default:
                icon = 'icon-5';
                msg = (
                    <Link to={fUserLink}>{midEllipsis(fName)}</Link>
                );
                break;
        }

        return { msg, icon };
    }

    const getTimeMessage = (updatedAt) => {
        const timeDiffObj = dateDiff(updatedAt, new Date());
        return timeDiffObj.value + ' ' + timeDiffObj.type + ' ago';
    }

    return (
        <ul className='bid-history-list'>
            {
                activityList && activityList.slice(0,visible).map((item,index) => {
                    const { type, status, fromAddrData, userAddressData, nftData, updatedAt, price, trxId } = item;
                    const { tokenId, collectionAddr, name: nftName, image, blockchain = {} } = nftData;
                    const { explorerLink = '', nativeCurrencySymbol, price:blockchainPrice } = blockchain;

                    const { address: fromAddr } = fromAddrData

                    // const tokenDetUrl = "/token/"+ collectionAddr + "/" + tokenId;
                    // const transactionDetUrl = explorerLink+"/tx/" + trxId;
                    const timeStampMessage = getTimeMessage(updatedAt);
                    const priceString = price ? parseFloat(price).toFixed(6) + ' '+nativeCurrencySymbol : '';
                    const priceConvertedString = price && blockchainPrice ? '$' + (parseFloat(price) * blockchainPrice).toFixed(4) + ' USD' : '';
                    const msgObj = getMessage(type, fromAddrData, userAddressData);

                    return (
                        <li key={index} >
                            <div className="content">
                                <div className="client">
                                    <div className="sc-author-box style-2">
                                        <div className="author-avatar">
                                            <Link to={'/user/'+fromAddr+'/created'}>
                                                {
                                                    fromAddrData && fromAddrData.image.profileImage &&
                                                    <img src={getSrcPath({src:fromAddrData.image.profileImage, path:'profile'})} alt="NFT`s" className="avatar" />
                                                }
                                            </Link>
                                        </div>
                                        <div className="author-infor">
                                            <div className="name">
                                                <h6>{msgObj.msg}</h6>
                                            </div>
                                            <span className="time">{timeStampMessage}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="price">
                                    {price && <h5>{priceString}</h5>}
                                    {priceConvertedString && <span>= {priceConvertedString}</span>}
                                </div>
                            </div>
                        </li>
                    )
                })
            }
            {
                visible < activityCount && 
                <div className="col-md-12 wrap-inner load-more text-center"> 
                    <Link 
                        to="#" 
                        id="load-more" 
                        className="sc-button loadmore fl-button pri-3 mt-10" 
                        onClick={showMoreItems}>
                            <span>Load More</span>
                    </Link>
                </div>
            }
        </ul>
    );
}

export default ActivityTab;

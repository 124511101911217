import React , { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Button, Modal } from "react-bootstrap";


import apiService from 'core/service/v1/';
import { postMethod } from 'core/service/api/common.api';
import { toastAlert } from 'core/lib/toastAlert';

import { getSrcPath } from 'core/lib/fileHelper';
import { dateDiff, midEllipsis, nameOrAddrShow } from 'core/lib/JSHelper';

import FollowBtn from 'components/common/FollowBtn';

const FollowModalTab = (props) => {

    const {
        show = false,
        onHide,
        followList = [],
        showModalName = '',
        followUser = () => {}
    } = props;

    return (
        <Modal
            show={show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                {
                    followList.map((data,index) => {
                        const userDet = showModalName === 'follower' ? data.follower : data.following;
                        const {
                            followerFollow = {}
                        } = data;
                        const {
                            address = '',
                            image = {},
                            followerCount = 0
                        } = userDet;
                        const {
                            profileImage = ''
                        } = image;
                        return <div className="client-infor sc-card-product" key = {index}>
                            <div className="meta-info">
                                <div className="author">
                                    <div className="avatar">
                                        <img src={getSrcPath({src:profileImage, path:'profile'})} alt="NFTs"/>
                                    </div>
                                    <div className="info">
                                        <h6><Link to={"/user/"+address+'/created'}>{nameOrAddrShow(userDet)}</Link></h6>
                                        <p>{followerCount} followers</p>
                                    </div>
                                    <div className="info">
                                        <FollowBtn
                                            followUser={followUser}
                                            userDet={userDet}
                                            followText = {followerFollow._id ? 'Un-Follow' : 'Follow'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </Modal>
    );
}

export default FollowModalTab;

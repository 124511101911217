import Web3 from "web3";
import "@metamask/legacy-web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { BscConnector } from "@binance-chain/bsc-connector";

import config from "../config";

import { toastAlert } from "core/lib/toastAlert";
import { IPFSfileAdd } from "core/lib/ipfs";
import { isEmpty, isStrEmpty } from "core/lib/typeCheck";
import { getWalletConn } from "core/lib/localStorage";

import { walletConn } from "core/redux/action/account.action";

import ABI_4_721 from "core/lib/Abi/4/721.json";
import ABI_4_trade from "core/lib/Abi/4/trade.json";

const WALLET_CONNECT = "Wallet Connect";
const WALLET_META_MASK = "Metamask";
const WALLET_BINANCE = "Binance Wallet";
const CLI_METHOD_MANUAL = "manual";
const CLI_METHOD_AUTO = "auto";

let web3 = "";
let web3Contract = { 721: "", 1155: "", trade: "", transfer: "", USDT: "" };

const windowEthereum = window.ethereum;

const providerWC = new WalletConnectProvider({
  // infuraId: config.infuraId, // Required

  rpc: {
    5: "https://goerli.infura.io/v3/",
  },
  chainId: 5,
  network: "goerli",
  qrcode: true,
  qrcodeModalOptions: {
    mobileLinks: ["metamask", "trust"],
  },
});

providerWC.networkId = 56;

const bsc = new BscConnector({ supportedChainIds: [56] });

var walletConnectAddr = "";

export const checkWalCnt = async () => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect network", "createNft");
      return false;
    } else {
      return true;
    }
  } else {
    toastAlert("error", "Please Connect network", "createNft");
    return false;
  }
};

export const getContratInst = async (abiJson, address) => {
  const inst = await new web3.eth.Contract(abiJson, address);
  return inst.methods;
};

export const addrCntChk = async (accountData) => {
  try {
    if (isEmpty(accountData)) {
      toastAlert("warning", "Kindly check wallet", "userForm");
      return false;
    } else if (!accountData.isWalletConn) {
      toastAlert("warning", "Kindly check wallet", "userForm");
      return false;
    }
    return true;
  } catch (err) {
    console.log("err : ", err, JSON.stringify(err));
    return false;
  }
};

export const getCurContract = async (data = {}) => {
  const { accountData, chainData } = data;

  if (accountData.networkVersion && chainData && chainData.data) {
    let idx = chainData.data.findIndex(
      (e) => e.networkVersion === accountData.networkVersion
    );
    if (idx > -1) {
      let retData = chainData.data[idx];
      return retData;
    }
  }
  return {};
};

export const getCurContractAbi = async () => {
  const abiList = {
    ABI_4_721: ABI_4_721,
    ABI_4_trade: ABI_4_trade,
  };
  return abiList;
};

export const web3Upd = async (data) => {
  const {
    accountData,
    chainData,
    tokenData = {},
    networkVersion = undefined,
  } = data;

  let dataWallet = "",
    contractDet = {},
    curContractAbi = {};

  if (accountData) {
    let { wallet } = accountData;
    dataWallet = wallet;

    if (dataWallet === WALLET_META_MASK) {
      web3 = new Web3(windowEthereum);
    } else if (dataWallet === WALLET_BINANCE) {
      web3 = new Web3(window.BinanceChain);
    } else if (dataWallet === WALLET_CONNECT) {
      web3 = providerWC;
      web3 = new Web3(providerWC);
    }
    contractDet = await getCurContract(data);
    curContractAbi = await getCurContractAbi();
  } else {
    contractDet = {
      networkVersion: networkVersion,
    };
  }

  let web3ContractNew = {};
  const { networkVersion: contractNetworkVer } = contractDet;
  if (
    (dataWallet === WALLET_META_MASK ||
      dataWallet === WALLET_BINANCE ||
      dataWallet === WALLET_CONNECT) &&
    contractNetworkVer
  ) {
    if (curContractAbi["ABI_" + contractNetworkVer + "_721"]) {
      web3ContractNew[721] = new web3.eth.Contract(
        curContractAbi["ABI_" + contractNetworkVer + "_721"],
        contractDet["721"]
      );
    }
    if (curContractAbi["ABI_" + contractNetworkVer + "_1155"]) {
      web3ContractNew[1155] = new web3.eth.Contract(
        curContractAbi["ABI_" + contractNetworkVer + "_1155"],
        contractDet["1155"]
      );
    }
    if (curContractAbi["ABI_" + contractNetworkVer + "_trade"]) {
      web3ContractNew["trade"] = new web3.eth.Contract(
        curContractAbi["ABI_" + contractNetworkVer + "_trade"],
        contractDet["trade"]
      );
    }
    // if (tokenData && tokenData.data) {
    //   for (let i = 0; i < tokenData.data.length; i++) {
    //     const { symbol, addr } = tokenData.data[i];
    //     if (curContractAbi['ABI_' + contractNetworkVer + '_' + symbol]) {
    //       web3ContractNew[symbol] = new web3.eth.Contract(curContractAbi['ABI_' + contractNetworkVer + '_' + symbol], addr);
    //     }
    //   }
    // }
  } else {
    web3ContractNew[721] = "";
    web3ContractNew[1155] = "";
    web3ContractNew.trade = "";
    web3ContractNew.USDT = "";
  }
  if (accountData) {
    web3Contract = web3ContractNew;
  }
  return web3ContractNew;
};

export const walletConn_success = async (
  dispatch,
  accData = {},
  chainData = {}
) => {
  const { data } = chainData;
  if (data && data.length) {
    const index = data.findIndex(
      (e) =>
        e.networkVersion.toLowerCase() === accData.networkVersion.toLowerCase()
    );
    if (index > -1) {
      let { nativeCurrencySymbol: nativeCurSym, standard } = data[index];
      nativeCurSym = nativeCurSym.toLowerCase();
      accData.chainIndex = index;
      accData.singleUrl =
        "/create-item/" + nativeCurSym + "-" + standard + "-721";
      accData.multipleUrl =
        "/create-item/" + nativeCurSym + "-" + standard + "-1155";
      walletConn(dispatch, accData);
    }
  }
};

export const connectUserWallet = async (dispatch, data = {}) => {
  try {
    const {
      target = WALLET_META_MASK,
      cliMethod = CLI_METHOD_MANUAL,
      chainData = {},
    } = data;

    if (cliMethod === CLI_METHOD_AUTO) {
      if (getWalletConn === false) {
        return false;
      }
    }

    if (chainData.data === undefined || chainData.data.length === 0) {
      // toastAlert('warning', "Please connect network", 'connectWallet');
      return false;
    } else {
      if (target === WALLET_CONNECT) {
        if (cliMethod === CLI_METHOD_MANUAL) {
          if (await providerWC.enable()) {
            web3 = new Web3(providerWC);
            const chainId = await web3.eth.getChainId();
            // await closeConnectWallet(dispatch);
            if (walletConnectAddr) {
              walletConn_success(
                dispatch,
                {
                  isWalletConn: true,
                  address: walletConnectAddr,
                  balance: 0,
                  currency: "",
                  wallet: target,
                  networkVersion: "",
                  checked: true,
                },
                chainData
              );
              if (cliMethod === CLI_METHOD_MANUAL) {
                toastAlert(
                  "success",
                  "User wallet connect successfully",
                  "connectWallet"
                );
              }
              return true;
            }
          } else {
            console.log("no");
          }
          return false;
        }
      } else if (target === WALLET_META_MASK) {
        if (!windowEthereum) {
          toastAlert(
            "warning",
            "Please Add " + target + " External",
            "ConnectWallet"
          );
          return false;
        }
        let web3 = await new Web3(windowEthereum);
        if (typeof web3 === "undefined") {
          toastAlert(
            "warning",
            "Please Add " + target + " External",
            "ConnectWallet"
          );
          return false;
        }
        if (cliMethod === CLI_METHOD_MANUAL) {
          await windowEthereum.enable();
        }
        const { web3: windowWeb3 } = window;
        const { currentProvider, eth } = windowWeb3;
        const { networkVersion, isMetaMask } = currentProvider;
        const { defaultAccount } = eth;

        if (web3.eth && defaultAccount !== null) {
          const balance = await web3.eth.getBalance(defaultAccount);
          const walletAmt = (balance / config.decimal.value).toFixed(
            config.fixed.currencyBal
          );
          const index = chainData.data.findIndex(
            (e) => e.networkVersion === networkVersion
          );

          if (index > -1) {
            if (isMetaMask === true) {
              if (windowWeb3 && eth && defaultAccount) {
                const { nativeCurrencySymbol } = chainData.data[index];
                walletConn_success(
                  dispatch,
                  {
                    address: defaultAccount,
                    balance: walletAmt,
                    currency: nativeCurrencySymbol,
                    wallet: target,
                    isWalletConn: true,
                    checked: true,
                    networkVersion: networkVersion,
                  },
                  chainData
                );
                if (cliMethod === CLI_METHOD_MANUAL) {
                  toastAlert(
                    "success",
                    "User wallet connect successfully",
                    "connectWallet"
                  );
                }
                return true;
              }
            }
          } else {
            switchNetwork(chainData);
            return true;
          }
        } else {
          // toastAlert('success', "Wallet not logged in", 'connectWallet');
        }
      } else if (target === WALLET_BINANCE) {
        try {
          if (!window.BinanceChain) {
            toastAlert(
              "warning",
              "Please Add " + target + " External",
              "ConnectWallet"
            );
            return false;
          }
          var networkVersion = await bsc.getChainId();
          const index = chainData.data.findIndex(
            (e) => e.networkVersion === networkVersion
          );
          if (index === -1) {
            toastAlert("warning", "Please connect network", "connectWallet");
            return false;
          }
          await bsc.activate();
          var accdet = await bsc.getAccount();
          if (accdet) {
            const { nativeCurrencySymbol } = data.chainData.data[index];
            // closeConnectWallet(dispatch)
            walletConn_success(
              dispatch,
              {
                isWalletConn: true,
                address: accdet,
                balance: 0,
                currency: nativeCurrencySymbol,
                wallet: target,
                networkVersion: "",
                checked: true,
              },
              chainData
            );
            return true;
          }
        } catch (err) {
          console.log("err : ", err);
          if (cliMethod === CLI_METHOD_MANUAL) {
            toastAlert("warning", "User wallet not connected", "connectWallet");
          }
          return true;
        }
      }
    }
    return false;
  } catch (err) {
    console.log("err : ", err);
    providerWC.disconnect();
  }
};

export const accountDisconnect = async () => {
  // providerWC.disconnect();
};

export const switchNetwork = async (chainData) => {
  try {
    await window.web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainData.data[0].chainId }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        const {
          chainId,
          chainName,
          rpcUrls,
          nativeCurrencyName: name,
          nativeCurrencySymbol: symbol,
          nativeCurrencyDecimals: decimals,
          blockExplorerUrls,
        } = chainData.data[0];
        await window.web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId,
              chainName,
              rpcUrls: [rpcUrls],
              blockExplorerUrls: [blockExplorerUrls],
              nativeCurrency: { name, symbol, decimals },
            },
          ],
        });
      } catch (error) {
        console.log("err : ", error.message);
        toastAlert("warning", "Please Add Mainnet Network", "ConnectWallet");
      }
    } else {
      toastAlert("warning", "Please Add Mainnet Network", "ConnectWallet");
    }
  }
};

export const sign_Cont = async (data) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect ", "authForm");
    return false;
  }

  try {
    console.log("sign_Cont : ");
  } catch (err) {
    console.log("err sign_Cont : ", err);
    return false;
  }
};

export const tryCatchOrContRetErr = async (data) => {
  if (data && typeof data === "object") {
    const { code = "", message = "" } = data;
    code && message && toastAlert("error", message, "tryCatchOrContRetErr");
  }
};

export const eventChecking = async (data = {}) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect ", "authForm");
    return false;
  }

  try {
    const curContMet = web3Contract[721].methods;
    const result = await curContMet
      .emitChecking()
      .send({ from: data.userAddress });
    return result;
  } catch (err) {
    tryCatchOrContRetErr(err);
  }
};

export const readContract = async (data = {}) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect ", "authForm");
    return false;
  }

  try {
    let result = 0;
    if (data.contractSymbol) {
      let curContMet = web3Contract[data.contractSymbol].methods;
      let { method: dataMethod } = data;

      if (curContMet == undefined) {
        await web3Upd({
          networkVersion: data.networkVersion,
        });
        curContMet = web3Contract[data.contractSymbol].methods;
      }

      if (curContMet) {
        if (dataMethod === "getFeeConfig") {
          result = await curContMet.getFeeConfig().call();
        }
        if (dataMethod === "buyerServiceFee") {
          result = await curContMet.buyerServiceFee().call();
        } else if (dataMethod === "_decimals") {
          result = await curContMet._decimals().call();
        } else if (dataMethod === "balanceOf") {
          result = await curContMet.balanceOf(data.userAddress).call();
        } else if (dataMethod === "allowance") {
          result = await curContMet
            .allowance(data.userAddress, data.contractAddress)
            .call();
        } else if (dataMethod === "fetchMyNFTs") {
          result = await curContMet.fetchMyNFTs().call();
        } else if (dataMethod === "getReserveAuctionIdFor") {
          result = await curContMet
            .getReserveAuctionIdFor(data.nftContract, data.tokenId)
            .call();
        } else if (dataMethod === "getReserveAuction") {
          result = await curContMet.getReserveAuction(data.auctionId).call();
        }
      }

      if (result) {
        if (dataMethod === "buyerServiceFee" || dataMethod === "allowance") {
          return result / 1e18;
        }

        if (dataMethod === "balanceOf") {
          const _decimals = await curContMet._decimals().call();
          const _decimalsEpower = 10 ** _decimals;
          return result / _decimalsEpower;
        }
      }
    }
    return result;
  } catch (err) {
    tryCatchOrContRetErr(err);
    return false;
  }
};

export const getTokenBalance = async (data = {}) => {
  try {
    const { accountData, tokenData } = data;

    if (tokenData.data) {
      let allBalance = {};
      for (let i = 0; i < tokenData.data.length; i++) {
        const element = tokenData.data[i];
        const getBalanceOfObj = {
          method: "balanceOf",
          contractSymbol: element.symbol,
          userAddress: accountData.address,
        };
        const getBalanceOfRes = await readContract(getBalanceOfObj);
        allBalance[element.symbol] = getBalanceOfRes;
        return allBalance;
      }
    } else {
      return;
    }
  } catch (err) {
    tryCatchOrContRetErr(err);
    return false;
  }
};

async function CreateNFTItemValidation(data) {
  const ValidateError = {};
  if (!data.name || data.name === "") {
    ValidateError.name = '"Title" field is required';
  }
  if (!data.ipfsimg || data.ipfsimg === null || data.ipfsimg === "") {
    ValidateError.ipfsimg = '"Image" field is required';
  }
  if (!data.description || data.description === "") {
    ValidateError.description = '"Description" is not allowed to be empty';
  }
  if (
    !data.supply ||
    data.supply === "" ||
    parseFloat(data.supply) <= 0 ||
    isNaN(data.supply) === true
  ) {
    ValidateError.supply = '"Supply" must be a number';
  }

  if (data.method === "fixedPrice") {
    if (
      !data.price ||
      data.price === "" ||
      parseFloat(data.price) <= 0 ||
      isNaN(data.price) === true
    ) {
      ValidateError.price = '"Price" must be a number';
    }
  } else if (data.method === "auction") {
    if (!data.minimumbid || data.minimumbid === "") {
      ValidateError.minimumbid = '"Minimum bid" must be a number';
    }
  }

  if (data.attributes && data.attributes.length > 0) {
    data.attributes.map((input, index) => {
      if (input.trait_type !== "" && input.value === "") {
        ValidateError.attributes =
          '"Attributes[' + index + '].value" not allowed to be empty';
      } else if (input.trait_type === "" && input.value !== "") {
        ValidateError.attributes =
          '"Attributes[' + index + '].key" not allowed to be empty';
      }
    });
  }
  return ValidateError;
}

export const CreateItemValidation_chk = async (data) => {
  if (checkWalCnt()) {
    const errors = await CreateNFTItemValidation(data);
    const errorsSize = Object.keys(errors).length;

    if (errorsSize !== 0) {
      // toastAlert('error', "Form validation error. Fix all mistakes and submit again", 'createNFT');
      return { errors };
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getIpfs_upload = async (data) => {
  if (checkWalCnt()) {
    if (!isStrEmpty(data.ipfsimg)) {
      const ipfshash = await IPFSfileAdd(data.ipfsimg);
      if(!ipfshash) {
        toastAlert("error", "IPFS upload have an error, Please try again", "authForm");
        return false;
      }
      const metadata = {
        name: data.name,
        description: data.description,
        image: config.ipfs.ipfsPath + ipfshash,
        attributes: data.attributes,
      };
      const metbuffer = Buffer.from(JSON.stringify(metadata));
      const tokenURI = await IPFSfileAdd(metbuffer);
      return {
        ipfshash: config.ipfs.ipfsPath + ipfshash,
        tokenURI: config.ipfs.ipfsPath + tokenURI,
      };
    } else {
      return {
        errors: { ipfsimg: "Image is a required field" },
      };
    }
  } else {
    return false;
  }
};

export const tokenPayApprove = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      if (web3Contract[data.tokenSymbol]) {
        let curContMet = web3Contract[data.tokenSymbol].methods;

        let contractDet = await getCurContract(data);
        let spenderContractAddr = contractDet[data.spenderSymbol];
        data.totalPrice = data.totalPrice.toString();

        var result = await curContMet
          .approve(
            spenderContractAddr,
            (parseFloat(data.totalPrice) * 1e18).toString()
          )
          .send({
            from: data.userAddress,
          });
        if (result && result.status) {
          toastAlert("success", "Allowance Approve success", "authForm");
          return result;
        }
      } else {
        console.log("web3Contract : ", web3Contract);
      }
    } catch (err) {
      tryCatchOrContRetErr(err);
      return false;
    }
  } else {
    return false;
  }
};

export const collectionCreate_cont = async (data) => {
  // let resp = web3Contract[data.contractDet.type].deploy(
  //   data.name,
  //   data.symbol,
  //   config.ipfs.ipfsPath
  // );
  // let response = await resp.deployed();
};

export const mintByUser = async (data) => {
  if (checkWalCnt()) {
    let result;
    const { ipfshash, contractDet, tokenURI, royalty, userAddress, supply } =
      data;

    const { abiJson, collectionAddr, type } = contractDet;

    var curContMet = await new web3.eth.Contract(abiJson, collectionAddr)
      .methods;

    if (type === 721) {
      result = await curContMet.mint(tokenURI).send({
        from: userAddress,
      });
    } else {
      result = await curContMet
        .createCollectible(tokenURI, royalty, supply)
        .send({
          from: userAddress,
        });
    }
    if (result.status) {
      return result;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const orderPlace = async (data) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect correct network", "authForm");
    return false;
  }

  try {
    let curContMet = web3Contract[data.contractSymbol].methods;
    data.price = (parseFloat(data.price) * 1e18).toString();

    let result = await curContMet
      .orderPlace(data.collectionAddr, data.tokenId, data.price)
      .send({
        from: data.userAddress,
      });
    if (result && result.status) {
      return result;
    }
  } catch (err) {
    console.log("err orderPlace : ", err);
    return false;
  }
};

export const changePrice = async (data) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect correct network", "authForm");
    return false;
  }

  try {
    let curContMet = web3Contract[data.contractSymbol].methods;
    data.price = (parseFloat(data.price) * 1e18).toString();

    let result = await curContMet
      .changePrice(data.collectionAddr, data.tokenId, data.price)
      .send({
        from: data.userAddress,
      });
    if (result && result.status) {
      return result;
    }
  } catch (err) {
    console.log("err changePrice : ", err);
    return false;
  }
};

export const cancelOrder = async (data) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect correct network", "authForm");
    return false;
  }

  try {
    let curContMet = web3Contract[data.contractSymbol].methods;
    let result = await curContMet
      .cancelOrder(data.collectionAddr, data.tokenId)
      .send({
        from: data.userAddress,
      });
    if (result && result.status) {
      return result;
    }
  } catch (err) {
    console.log("err cancelOrder : ", err);
    return false;
  }
};

export const createReserveAuction = async (data) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect correct network", "authForm");
    return false;
  }

  try {
    let curContMet = web3Contract[data.contractSymbol].methods;
    data.minimumbid = (parseFloat(data.minimumbid) * 1e18).toString();

    let result = await curContMet
      .createReserveAuction(data.collectionAddr, data.tokenId, data.minimumbid)
      .send({
        from: data.userAddress,
      });
    if (result && result.status) {
      return result;
    }
  } catch (err) {
    console.log("err createReserveAuction : createReserveAuction:  ", err);
    return false;
  }
};

export const placeBid_cont = async (data) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect correct network", "authForm");
    return false;
  }

  try {
    let curContMet = web3Contract[data.contractSymbol].methods;

    var result = await curContMet.placeBid(data.auctionId).send({
      from: data.userAddress,
      value: data.value * 1e18,
    });
    if (result && result.status) {
      return result;
    }
  } catch (err) {
    console.log("err placeBid_cont : ", err);
    return false;
  }
};

export const purchaseNow = async (data) => {
  if (checkWalCnt()) {
    let result;

    const { contractDet, tokenURI, royalty, supply, userAddress } = data;

    const { abiJson, collectionAddr, type } = contractDet;
    var curContMet = await new web3.eth.Contract(abiJson, collectionAddr)
      .methods;

    if (type === 721) {
      result = await curContMet.buyAsset(tokenURI, royalty).send({
        from: userAddress,
      });
    } else {
      result = await curContMet.buyAsset(tokenURI, royalty, supply).send({
        from: userAddress,
      });
    }
    if (result.status) {
      toastAlert("success", "Mint success", "createNft");
      return result;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const chk_setapprovalforall_cont = async (data) => {
  if (!web3) {
    return false;
  }

  if (
    typeof window.web3 === "undefined" ||
    typeof window.web3.eth === "undefined"
  ) {
    toastAlert("error", "Please Connect ", "authForm");
    return false;
  }

  try {
    const { userAddress, operator, contractSymbol, approved } = data;

    const contractDet = await getCurContract(data);
    if (contractDet) {
      const curContMet = web3Contract[contractSymbol].methods;
      const isApprForAllRes = await curContMet
        .isApprovedForAll(userAddress, contractDet[operator])
        .call({ from: userAddress });

      if (isApprForAllRes) {
        return { status: isApprForAllRes };
      } else {
        const setApprForAllRes = await curContMet
          .setApprovalForAll(contractDet[operator], approved)
          .send({ from: userAddress });
        if (setApprForAllRes && setApprForAllRes.status) {
          toastAlert("success", "NFT Approval success", "Approval");
          return setApprForAllRes;
        } else {
          return { status: false };
        }
      }
    } else {
      return { status: false };
    }
  } catch (err) {
    console.log("err chk_setapprovalforall_cont : ", err);
    return false;
  }
};

export const bidApprove_cont = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      let curContMet = web3Contract[data.contractSymbol].methods;
      let BuyingAssetType = data.BuyingAssetType === 721 ? "1" : "0";

      data.unitPrice = (parseFloat(data.unitPrice) * 1e18).toString();
      data.amount = (parseFloat(data.amount) * 1e18).toString();

      let passData = [
        data.seller,
        data.buyer,
        data.erc20Address,
        data.collectionAddr,
        BuyingAssetType,
        data.unitPrice,
        data.amount,
        data.tokenId,
        data.supply.toString(),
        data.tokenURI,
        (parseFloat(data.fee) * 1e18).toString(),
        data.quantity.toString(),
      ];

      var result = await curContMet.executeBid(passData).send({
        from: data.userAddress,
      });
      if (result && result.status) {
        return result;
      }
    } catch (err) {
      console.log("err bidApprove_cont : ", err);
      return false;
    }
  } else {
    return false;
  }
};

export const buyAsset_cont = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      const {
        contractSymbol,
        unitPrice,
        userAddress,
        collectionAddr,
        tokenId,
      } = data;
      const curContMet = web3Contract[contractSymbol].methods;
      const price = (parseFloat(unitPrice) * 1e18).toString();
      const result = await curContMet.buyNft(collectionAddr, tokenId).send({
        from: userAddress,
        value: price,
      });
      if (result && result.status) {
        return result;
      }
    } catch (err) {
      tryCatchOrContRetErr(err);
      return false;
    }
  } else {
    return false;
  }
};

export const createReserveAuction_cont = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      let curContMet = web3Contract[data.contractSymbol].methods;
      data.price = (parseFloat(data.price) * 1e18).toString();

      var result = await curContMet
        .createReserveAuction(data.collectionAddr, data.tokenId, data.price)
        .send({
          from: data.userAddress,
        });
      if (result && result.status) {
        return result;
      }
    } catch (err) {
      tryCatchOrContRetErr(err);
      return false;
    }
  } else {
    return false;
  }
};

export const updateReserveAuction_cont = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      let curContMet = web3Contract[data.contractSymbol].methods;
      data.price = (parseFloat(data.price) * 1e18).toString();

      var result = await curContMet
        .updateReserveAuction(data.auctionId, data.price)
        .send({
          from: data.userAddress,
        });
      if (result && result.status) {
        return result;
      }
    } catch (err) {
      tryCatchOrContRetErr(err);
      return false;
    }
  } else {
    return false;
  }
};

export const cancelReserveAuction_cont = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      let curContMet = web3Contract[data.contractSymbol].methods;
      var result = await curContMet.cancelReserveAuction(data.auctionId).send({
        from: data.userAddress,
      });
      if (result && result.status) {
        return result;
      }
    } catch (err) {
      tryCatchOrContRetErr(err);
      return false;
    }
  } else {
    return false;
  }
};

export const finalizeReserveAuction_cont = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      let curContMet = web3Contract[data.contractSymbol].methods;
      var result = await curContMet
        .finalizeReserveAuction(data.auctionId.toString())
        .send({
          from: data.userAddress,
        });
      if (result && result.status) {
        return result;
      }
    } catch (err) {
      tryCatchOrContRetErr(err);
      return false;
    }
  } else {
    return false;
  }
};

export const getAuctionDetail_cont = async (data) => {};

// ================
export const transfer_cont = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      let curContMet = web3Contract[data.contractDet.type].methods;

      let from = data.from;
      let to = data.to;
      let tokenId = data.tokenId;
      let quantity = data.quantity;

      if (data.contractDet.type === 721) {
        var result = await curContMet
          .safeTransferFrom(from, to, tokenId.toString())
          .send({
            from: data.from,
          });
      } else {
        var result = await curContMet
          .safeTransferFrom(
            from,
            to,
            tokenId.toString(),
            quantity.toString(),
            ""
          )
          .send({
            from: data.from,
          });
      }

      if (result && result.status) {
        return result;
      }
    } catch (err) {
      tryCatchOrContRetErr(err);
      return false;
    }
  } else {
    return false;
  }
};

export const burn_cont = async (data) => {
  if (web3) {
    if (
      typeof window.web3 === "undefined" ||
      typeof window.web3.eth === "undefined"
    ) {
      toastAlert("error", "Please Connect ", "authForm");
      return false;
    }
    try {
      let curContMet = web3Contract[data.contractDet.type].methods;
      let tokenId = data.tokenId;
      let quantity = data.quantity;

      if (data.contractDet.type === 721) {
        var result = await curContMet.burn(tokenId.toString()).send({
          from: data.from,
        });
      } else {
        var result = await curContMet
          .burn(tokenId.toString(), quantity.toString())
          .send({
            from: data.from,
          });
      }

      if (result && result.status) {
        return result;
      }
    } catch (err) {
      tryCatchOrContRetErr(err);
      return false;
    }
  } else {
    return false;
  }
};

// addEventListener
// window.addEventListener('load',  function () {
//   try {
//     if (windowEthereum) {
//       var web3 = new Web3(windowEthereum);
//       if (typeof web3 !== 'undefined') {
//         windowEthereum.on('accountsChanged', async function (accounts) {
//           if(getWalletConn() === WALLET_META_MASK) {
//             await disconnectWallet('changed');
//             connectUserWallet(dispatch, {
//               target: WALLET_META_MASK,
//               cliMethod: 'changed'
//             });
//           }
//         })
//         windowEthereum.on('networkChanged', async function (networkId) {
//           if(getWalletConn() === WALLET_META_MASK) {
//             await disconnectWallet('changed');
//             connectUserWallet(dispatch, {
//               target: WALLET_BINANCE,
//               cliMethod: 'changed'
//             });
//           }
//         })
//       }
//     }

//     if (windowEthereum) {
//       window.BinanceChain.on('accountsChanged', async function (accounts) {
//         if(getWalletConn() === WALLET_BINANCE) {
//           await disconnectWallet('changed');
//           connectUserWallet(dispatch, {
//             target: WALLET_BINANCE,
//             cliMethod: 'changed'
//           });
//         }
//       })
//       window.BinanceChain.on('networkChanged', async function (networkId) {
//         if(getWalletConn() === WALLET_BINANCE) {
//           await disconnectWallet('changed');
//           connectUserWallet(dispatch, {
//             target: WALLET_BINANCE,
//             cliMethod: 'changed'
//           });
//         }
//       })
//     }

//     if(providerWC) {
//       providerWC.on("accountsChanged", (accounts: string[]) => {
//         console.log('accountsChanged : ', accounts[0]);
//         walletConnectAddr = accounts[0];
//       });
//       // Subscribe to chainId change
//       providerWC.on("chainChanged", (chainId: number) => {
//         console.log('chainChanged : ', chainId);
//       });
//       // Subscribe to session connection
//       providerWC.on("connect", () => {
//         console.log("connect");
//       });
//       // Subscribe to session disconnection
//       providerWC.on("disconnect", (code: number, reason: string) => {
//         console.log('disconnect : ', code, reason);
//       });
//     }
//   } catch(err){}
// });

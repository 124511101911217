import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom'

const ConnectWalletAlert = (props) => {
    return (
        <>
            <p>Get started with your Ethereum wallet to sign messages and send transactions to Ethereum blockchain</p>
            <Link to='/wallet-connect' className="btn btn-primary">Wallet Connect</Link>
        </>
    )
}

export default ConnectWalletAlert;
import React, {
    useEffect,useState
} from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import config from 'core/config';
import { burn } from 'core/service/api/nft.api';
import { toastAlert } from 'core/lib/toastAlert';
import { burn_cont } from 'core/contract/index';
import { postMethod, getMethod } from "core/service/api/common.api";
import apiService from "core/service/v1/";

import ConnectWalletAlert from './ConnectWalletAlert';
import StepsBtn from 'components/separate/StepsBtn';
import * as  Constants from 'core/constant/';

const burnSchema = Yup.object().shape({
    quantity: Yup
        .number()
        .integer()
        .min(1,'Quantity must be greater than zero')
});

const BurnModal = (props) => {

    const navigate = useNavigate();

    const { item } = props;
    const accountData = useSelector(state => state.account);
    const [loader, loader_set] = useState(false);

    const formSubmit = async (payload) => {
        let quantity = item.type==721? 1 : payload.quantity;
        var data = {
            from : accountData.address,
            tokenId : item.tokenId,
            quantity : quantity,
            contractDet: {
                exchange: item.exchange,
                type: item.type,
                collectionAddr: item.collectionAddr,
                networkVersion : accountData.networkVersion
            }
        }
        loader_set(true);
        let respCont = await burn_cont(data);
        loader_set(false);
        if(respCont.status) {
            const postData = {
                apiUrl: apiService.burn,
                payload: {
                    tokenId: item.tokenId,
                    collectionAddr: item.collection.address,
                    quantity : quantity,
                    trxId: respCont.transactionHash
                },
            };
            loader_set(true);
            const resp = await postMethod(postData);
            loader_set(false);
            if(resp.message) {
                toastAlert(resp.status, resp.message, 'userForm');
            }
            if(resp.status === 'success') {
                props.onHide({reloadData:true});
                navigate("/items/created", { replace: false });
            }
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                {
                !accountData || !accountData.address
                ?
                <ConnectWalletAlert />
                :
                <Formik
                    initialValues={{
                        quantity: 1
                    }}
                    validationSchema={burnSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        formSubmit(values);
                    }}
                    render ={({
                        values,
                        isSubmitting,
                        handleChange
                    }) =>  (
                        <Form>                    
                        <div className="modal-body space-y-20 pd-40">
                            <h3>Burn</h3>
                            <p>Are you sure to burn this token? This action cannot be undone. Token will be transferred to zero address</p>
                            {item.type !== 721 && 
                            <div className="d-flex justify-content-between">
                                <input
                                    type="text"
                                    className="form-control" 
                                    name="quantity"
                                    onChange={handleChange}
                                    defaultValue ={1}
                                    autoComplete="off"
                                    placeholder="Quantity"
                                />
                                <ErrorMessage name="quantity" className="error" component="div" />
                            </div>
                             }
                                <ErrorMessage name="quantity" className="error" component="div" />
                            <StepsBtn
                                type="submit"
                                className="btn btn-primary"
                                onClick={async () => {}}
                                disabled={loader}
                                loader={loader}
                                label={Constants.BURN_NFT_LABEL}
                            />
                        </div>
                    </Form>
                    )}
                >
                </Formik>
                }
            </div>
        </Modal>
    );
};

export default BurnModal;

// package
import { Routes, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

// other
import './App.css';
import routes from './pages';
import store from './core/redux/store';

function App() {
    return (
        <Provider store={store}>
            <ToastContainer />
            <Routes >
                {
                    routes.map((data,index) => (
                        <Route 
                            onUpdate={() => window.scrollTo(0, 0)}
                            exact={true}
                            path={data.path}
                            element={data.component}
                            key={index}
                        />
                    ))
                }
            </Routes>
        </Provider>
    );
}

export default App;

const service = {
    createReserveAuction: 'v1/nft/createReserveAuction',
    cancelReserveAuction: 'v1/nft/cancelReserveAuction',
    nftpricechange: 'v1/nft/nftpricechange',
    like: 'v1/nft/like',
    mynftlist:'v1/nft/mynftlist',
    nftList:'v1/nft/list',
    nftDetail:'v1/nft/detail',
    siteSettings:'v1/nft/siteSettings',
    bidAccept:'v1/nft/bid/accept',
    transfer:'v1/nft/transfer',
    burn:'v1/nft/burn',
    report:'v1/nft/report',
    collectionList:'v1/nft/collection/list',
    updAuction:'v1/nft/updAuction',

    follow: 'v1/member/follow',
    followList: 'v1/member/follow',
    getProfile:'v1/member/profile',

    chainList:'v1/chain/list',
    tokenList:'v1/chain/token/list',

    activitylist:'v1/activity/allActivities',

    bidlist: 'v1/auction/bid/list',

    faqlist:'v1/cms/faqlist',

    contactUs:'v1/contact/us',

    subscribe:'v1/newsletter/subscribe',
}

export default service;
import { create, urlSource } from "ipfs-http-client";
import { toastAlert } from "core/lib/toastAlert";
import config from "core/config/";

export const tryCatchOrContRetErr = async (data) => {
  console.log("tryCatchOrContRetErr : ", JSON.stringify(data), data);
  if (data && typeof data === "object") {
    const { code = "", message = "" } = data;
    code && message && toastAlert("error", message, "tryCatchOrContRetErr");
  }
};

export const IPFSClient = async (data) => {
  const projectId = "2DQWyvJleHUODeMeF6Du9mdST0Y";
  const projectSecret = "8c0b49cb9b7e35513938a47cf283477c";
  // const projectId = "2E3uxm2nZUsPWX2AiDnFMQrUBi6";
  // const projectSecret = "bc6dd693e1df7ae32fce265aefe4a3b3";
  const auth =
    "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");
  const { host, port, protocol } = config.ipfs;
  const createObj = {
    host,
    port,
    protocol,
    // apiPath: "/ipfs/api/v0",
    headers: { authorization: auth },
  };
  //   const createObj = {
  //     url: config.ipfs.url,
  //   };
  const ipfs = await create(createObj);
  // const ipfs = ipfsClient(createObj);
  return ipfs;
};

export const IPFSfileAdd = async (data) => {
  let ipfsClient = await IPFSClient();
  const file = {
    path: "nftapp",
    content: data,
  };
  try {
    const result = await ipfsClient.add(file);
    if (result.cid) {
      return result.cid;
    } else {
      return false;
    }
  } catch (err) {
    tryCatchOrContRetErr(err);
    return false;
  }
};

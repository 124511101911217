import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getSrcPath } from 'core/lib/fileHelper';
import { dateDiff, midEllipsis } from 'core/lib/JSHelper';

const BidTab = (props) => {

    const accountData = useSelector(state => state.account);

    const { item, bidlist } = props;
    const {
        blockchain,
        accOwnerDetail = {}
    } = item;

    async function bidApprove(data){
        // 
    }

    const getTimeMessage = (updatedAt) => {
        const timeDiffObj = dateDiff(updatedAt, new Date());
        return timeDiffObj.value + ' ' + timeDiffObj.type + ' ago';
    }

    return (
        <ul className="bid-history-list">
            { bidlist && bidlist.length > 0 ?
            bidlist.map((bidItem, index) => {
                const {
                    bidder = {},
                    price,
                    availQuan,
                    quantity,
                    createdAt,
                    updatedAt
                } = bidItem;

                const {
                    nameShow: bidderName,
                    address: bidderAddress,
                    image: bidderImg
                } = bidder;

                const timeStampMessage = getTimeMessage(updatedAt);
                return(
                    bidItem && <li key={index} >
                    <div className="content">
                        <div className="client">
                            <div className="sc-author-box style-2">
                                <div className="author-avatar">
                                    <Link to="#">
                                        <img src={getSrcPath({src: bidderImg.profileImage, path:'profile'})} alt="NFTs" className="avatar" />
                                    </Link>
                                    <div className="badge"></div>
                                </div>
                                <div className="author-infor">
                                    <div className="name">
                                        <h6>{price} {blockchain?.nativeCurrencySymbol}/editions by <Link to={"/user/"+bidderAddress+'/created'}>{midEllipsis(bidderName)}</Link> for {availQuan}/{quantity} editions</h6>
                                    </div>
                                    <span className="time">{timeStampMessage}</span>
                                </div>
                            </div>
                        </div>
                        <div className="price">
                            {
                            // accountData.address && bidderAddress && accountData.address.toLowerCase() !== bidderAddress.toLowerCase() && accOwnerDetail && accOwnerDetail.availQuan != undefined ? <button onClick={() => {
                            //     bidApprove({
                            //         bidItem: bidItem
                            //     })
                            // }
                            // }>Approve</button> : <span></span>
                            }
                        </div>
                    </div>
                </li>
                )
            })
            :
            <span> No active bids yet. Be the first to make a bid!</span>
            }
        </ul>
    );
}

export default BidTab;

// steps - Create Item
export const CREATEITEM_UPLOAD_DESC = 'Uploading of all media assets and metadata to IPFS';
export const CREATEITEM_UPLOAD_LABEL = 'Upload';

export const CREATEITEM_MINT_DESC = 'Send transaction to create your NFT';
export const CREATEITEM_MINT_LABEL = 'Mint';

export const CREATEITEM_APPROVE_DESC = 'This transaction is conducted only once per collection';
export const CREATEITEM_APPROVE_LABEL = 'Approve';

export const CREATEITEM_PUTONSALE_DESC = 'Set fixed price';
export const CREATEITEM_PUTONSALE_LABEL = 'Put on sale';

export const CREATEITEM_AUCTION_DESC = 'Create reserve NFT an NFT contract';
export const CREATEITEM_AUCTION_LABEL = 'Auction';

export const APPROVE_DESC = 'This transaction is conducted only once per collection';
export const APPROVE_LABEL = 'Approve';

export const PUTONSALE_DESC = 'Set fixed price for your item';
export const PUTONSALE_LABEL = 'Put on sale';

export const AUCTION_DESC = 'Create a reserve auction';
export const AUCTION_LABEL = 'Reserve Auction';

export const AUCTION_CANCEL_DESC = 'Do you really want to remove your NFT from auction?';
export const AUCTION_CANCEL_LABEL = 'Cancel from auction';

export const AUCTION_CLAIM_DESC = 'Finalize your auction';
export const AUCTION_CLAIM_LABEL = 'Claim a bid';

export const REMOVE_SALE_DESC = 'Do you really want to remove your NFT from sale?';
export const REMOVE_SALE_LABEL = 'Remove from sale';

export const PURCHASE_DESC = 'Purchase Now';
export const PURCHASE_LABEL = 'Purchase Now';

export const BURN_NFT_DESC = 'Burn Token';
export const BURN_NFT_LABEL = 'Burn Token';

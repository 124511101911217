import { SET_CHAIN_DATA } from '../constant';

export const ChainDataSave = (dispatch, data) => {
    try {
        dispatch({
            type: SET_CHAIN_DATA,
            data
        })
        return true;
    } catch (err) {
        return false;
    }
}
import React from 'react';
import LoaderComp from './LoaderComp';

const StepsBtn = (props) => {
    const {
        type,
        className,
        onClick = async () => {},
        disabled,
        loader,
        label
    } = props;
    return (
        <button
            type={type}
            className={className}
            onClick={() => onClick()}
            disabled={disabled}
        >
            <span>
                <span className='loaderSpan'>{loader &&<LoaderComp />}</span>
                <span className='btnSpan'>{label}</span>
            </span>
        </button>
    )
}

export default StepsBtn;

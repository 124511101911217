import React,{useState,useEffect} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { getSrcPath } from '../core/lib/fileHelper';
import { toastAlert } from '../core/lib/toastAlert';
import { updateProfile,getuserProfile } from '../core/service/api/nft.api';
import config from '../core/config/';

const initialFormValue = {
    image: {
        profileImage: "default.png",
        coverImage: "default-1.jpg"
    },
    email: "",
    facebook: "",
    displayname: "",
    customUrl: "",
    twitter: "",
    discord: "",
    bio: ""
}
const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

const profileSchema = Yup.object().shape({
    displayname: Yup
        .string()
        .matches(/^[a-zA-Z0-9]+$/, 'This field cannot contain white space and special character'),
    customUrl: Yup
        .string()
        .matches(/^[a-zA-Z0-9]+$/, 'This field cannot contain white space and special character'),
    facebook: Yup
        .string()
        .matches(URL, 'Enter a valid url'),
    twitter: Yup
        .string()
        .matches(URL, 'Enter a valid url'),
    discord: Yup
        .string()
        .matches(URL, 'Enter a valid url'),
    email: Yup
        .string()
        .matches(URL, 'Enter a valid url'),
    bio: Yup
        .string()
        .min(10,'Minimum 10 characters')
        .max(100,'Maximum 100 characters')
});

const EditProfile = () => {

    const navigate = useNavigate();
    const accountData = useSelector(state => state.account);

    const [profileData, profileData_set] = useState(initialFormValue);

    useEffect(() => {
        if(accountData && accountData.address) {
            getProfile()
        }
    }, [accountData]);

    async function getProfile(){
        try {
            let { data } = await getuserProfile();
            if(data) {
                const {
                    socialMedia = {}
                } = data;

                const {
                    facebook: facebookNew,
                    twitter: twitterNew,
                    discord: discordNew
                } = socialMedia
                data.facebook = facebookNew;
                data.twitter = twitterNew;
                data.discord = discordNew;
                profileData_set(data)
            }
        } catch(err){}
    }

    async function reset(name){
        await profileImageUpdate({
            name: name,
            value: ''
        });
    }

    const handleFile = async (e) => {
        if (e.target && e.target.files) {
            const {
                name
            } = e.target;
            var file = e.target.files[0];
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = async function (e) {
                await profileImageUpdate({
                    name: name,
                    value: file
                });
            }
        }
    }

    const profileImageUpdate = async (data=[]) => {
        let profileData_cpy = Object.assign({}, profileData);
        profileData_cpy[data.name] = data.value;
        profileData_set(profileData_cpy);
    }

    async function changeDefaultCoverimg(img){
        await profileImageUpdate({
            name: 'coverImage',
            value: img
        });
    }

    const formSubmit = async (payload) => {
        let formData = new FormData();
        formData.append("displayname", payload.displayname);
        formData.append("email", payload.email);
        formData.append("customUrl", payload.customUrl);
        formData.append("bio", payload.bio);
        formData.append("facebook", payload.facebook);
        formData.append("twitter", payload.twitter);
        formData.append("discord", payload.discord);
        if(profileData.profileImage) {
            formData.append("profileImage", profileData.profileImage);
        }
        if(typeof profileData.coverImage == 'object') {
            formData.append("coverImage", profileData.coverImage);
        }
        else if(typeof profileData.coverImage == 'string') {
            formData.append("coverImage_default", profileData.coverImage);
        }
        var {status,message} = await updateProfile(formData);

        if ((status === 'success' || status === 'error') && message) {
            toastAlert(status, message, 'userForm');
        }
        if (status === 'success') {
            navigate("/items/created", { replace: false });
        }
    }
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Edit Profile</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Edit Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <div className="sc-card-profile text-center">
                                <div className="card-media">
                                    <img id="profileImage" src={getSrcPath({src: profileData.profileImage ? profileData.profileImage : profileData.image.profileImage, path: 'profile'})} alt="Axies" />  
                                </div>
                                <div id="upload-profile">
                                    <Link to="#" className="btn-upload">Upload New Photo</Link>
                                    <input
                                        id="tf-upload-img"
                                        type="file" 
                                        name="profileImage"
                                        onChange={handleFile} 
                                        accept="image/png, image/gif, image/jpeg, image/jpg"
                                        required="" />
                                </div>
                                <Link to="#" className="btn-upload style2" onClick={()=>reset('profileImage')}>Reset</Link>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                            <div className="form-upload-profile">
                                <h4 className="title-create-item">Upload New Cover Image</h4>
                                <div className="option-profile clearfix">
                                    <form action="#">
                                        <label className="uploadFile">
                                            <input type="file" name="coverImage" onChange={handleFile}  className="inputfile form-control" />
                                        </label>
                                        <Link to="#" className="btn-upload style2" onClick={()=>reset('coverImage')}>Reset</Link>
                                    </form>
                                    <div className="image">
                                        <img
                                            width={320}
                                            height={102}
                                            src={getSrcPath({src: profileData.coverImage ? profileData.coverImage : profileData.image.coverImage, path: 'cover'})}
                                            alt="coverImg"
                                        />
                                    </div>
                                    <div className="image style2">
                                        <img src={config.IMG_URL+'/author/cover/default-1.jpg'} alt="Axies" onClick={()=>changeDefaultCoverimg("default-1.jpg")}/>
                                        <img src={config.IMG_URL+'/author/cover/default-2.jpg'} alt="Axies" onClick={()=>changeDefaultCoverimg("default-2.jpg")}/>
                                    </div>
                                </div>
                                <Formik
                                    initialValues={profileData}
                                    validationSchema={profileSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(false);
                                        formSubmit(values);
                                    }}
                                    render ={({
                                        values,
                                        isSubmitting,
                                        handleChange
                                    }) =>  (
                                        <Form>
                                            <div className="form-infor-profile">
                                                <div className="info-account">
                                                    <h4 className="title-create-item">Account info</h4>                                    
                                                        <fieldset>
                                                            <h4 className="title-infor-account">Display name</h4>
                                                            <input type="text" name="displayname" 
                                                                    autoComplete="off" defaultValue={profileData.firstName} 
                                                                    onChange={handleChange} placeholder="Trista Francis"
                                                            />
                                                            <ErrorMessage name="displayname" className="error" component="div"/>
                                                            </fieldset>
                                                        <fieldset>
                                                            <h4 className="title-infor-account">Custom URL</h4>
                                                            <input type="text" name="customUrl"
                                                                autoComplete="off" onChange={handleChange} 
                                                                defaultValue={profileData.customUrl} placeholder="Axies.Trista Francis.com/"
                                                            />
                                                            <ErrorMessage name="customUrl" className="error" component="div"/>
                                                        </fieldset>
                                                        <fieldset>
                                                            <h4 className="title-infor-account">Email</h4>
                                                            <input type="text" name="email" 
                                                                    autoComplete="off" onChange={handleChange} 
                                                                    defaultValue={profileData.email} placeholder="Enter your email"
                                                            />
                                                            <ErrorMessage name="email" className="error" component="div"/>
                                                        </fieldset>
                                                        <fieldset>
                                                            <h4 className="title-infor-account">Bio</h4>
                                                            <textarea name="bio" tabIndex="4" rows="5" defaultValue={profileData.bio} onChange={handleChange} ></textarea>
                                                            <ErrorMessage name="bio" className="error" component="div"/>
                                                        </fieldset>
                                                </div>
                                                <div className="info-social">
                                                    <h4 className="title-create-item">Your Social media</h4>                                    
                                                        <fieldset>
                                                            <h4 className="title-infor-account">Facebook</h4>
                                                            <input type="text" name="facebook"
                                                                    onChange={handleChange} autoComplete="off" 
                                                                    defaultValue={profileData.facebook} placeholder="Facebook username"
                                                            />
                                                            <ErrorMessage name="facebook" className="error" component="div"/>
                                                            {/* <Link to="#" className="connect"><i className="fab fa-facebook"></i>Connect to face book</Link> */}
                                                        </fieldset>
                                                        <fieldset>
                                                            <h4 className="title-infor-account">Twitter</h4>
                                                            <input type="text" name="twitter" 
                                                                    onChange={handleChange}  autoComplete="off" 
                                                                    defaultValue={profileData.twitter} placeholder="Twitter username"
                                                            />
                                                            <ErrorMessage name="twitter" className="error" component="div"/>
                                                            {/* <Link to="#" className="connect"><i className="fab fa-twitter"></i>Connect to Twitter</Link> */}
                                                        </fieldset>
                                                        <fieldset>
                                                            <h4 className="title-infor-account">Discord</h4>
                                                            <input type="text" name="discord" 
                                                                    onChange={handleChange}  autoComplete="off" 
                                                                    defaultValue={profileData.discord} placeholder="Discord username"
                                                            />
                                                            <ErrorMessage name="discord" className="error" component="div"/>
                                                            {/* <Link to="#" className="connect"><i className="icon-fl-vt"></i>Connect to Discord</Link> */}
                                                        </fieldset>
                                                </div> 
                                            </div>
                                            <button className="tf-button-submit mg-t-15" type="submit">
                                                Update Profile
                                            </button>           
                                        </Form>
                                    )}
                                >
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EditProfile;

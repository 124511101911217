import React from 'react';

const LoaderFullComp = (props) => {
    return (
        // <div className="middle">
        //     <div className="bar bar1"></div>
        //     <div className="bar bar2"></div>
        //     <div className="bar bar3"></div>
        //     <div className="bar bar4"></div>
        //     <div className="bar bar5"></div>
        //     <div className="bar bar6"></div>
        //     <div className="bar bar7"></div>
        //     <div className="bar bar8"></div>
        // </div>
        <div className="wrapper">
            <h1 className="brand">
                <span>Loading</span>
                {/* <span>Art</span> */}
            </h1>
            <div className="loading-bar"></div>
        </div>
        // <div className="c-loader">
        //     <div className="loader">
        //         <span></span>
        //         <span></span>
        //         <span></span>
        //     </div>
        // </div>
    )
}

export default LoaderFullComp;

import React from 'react';
import { Link } from 'react-router-dom';

const PageHeader = (props) => {
    const { mainTitle, subTitle } = props;
    return (
        <section className="flat-title-page inner">
            <div className="overlay"></div>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-title-heading mg-bt-12">
                            <h1 className="heading text-center">{mainTitle}</h1>
                        </div>
                        <div className="breadcrumbs style2">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>{subTitle}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>                    
        </section>
    );
};

export default PageHeader;
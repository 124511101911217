export const respChanges = (data) => {
    const {
        success,
        error
    } = data;

    if(success === true) {
        data.status = 'success';
    } else if(success === false) {
        data.status = 'error'
    }
    if(typeof error === 'undefined') { data.error = {}; }
    return data;
}

export const responseChanges = (data) => {
    const {
        status,
        success,
        message,
        error
    } = data;
    if(status === 0 || success === false) {
        data.status = 'error';
    } else if(status === 1 || success === true) {
        data.status = 'success';
    } else {
        data.status = 'error'
    }
    if(message && message === 'Data retrived successfully') {
        delete data.message;
    }
    if(typeof error === 'undefined') { data.error = {}; }
    return data;
}
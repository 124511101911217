// const { APP_MODE, FRONT_URL, SECRET_KEY, INFURA_ID } = process.env;
const {
    REACT_APP_MODE,
    REACT_APP_FRONT_URL,
    REACT_APP_API_URL,
    REACT_APP_IMG_URL,
    REACT_APP_SECRET_KEY,
    REACT_APP_INFURA_ID
} = process.env;

const KEY = {
    appEnvMode: REACT_APP_MODE,
    secretOrKey: REACT_APP_SECRET_KEY,
    infuraId: REACT_APP_INFURA_ID,
    FRONT_URL: REACT_APP_FRONT_URL,
    API_URL: REACT_APP_API_URL,
    IMG_URL: REACT_APP_IMG_URL,

    defaultImage: {
        profileImage: 'default.png',
        coverImage: 'default-1.jpg'
    },

    FILE_SIZE: 30000000,
    metaTitle: 'NFT Marketplace',
    fixed: {
        usdprice: 3,
        currencyBal: 4,
    },
    decimal: {
        value: 1e18,
    },
    ipfs: {
        host : 'ipfs.infura.io',
        port: 5001,
        protocol: 'https',
        // url: "https://ipfs.infura.io:5001/api/v0"+REACT_APP_INFURA_ID+"/",
        url: "https://rinkeby.infura.io/v3/b40d311f09e7410285a90de8ae1411aa",
        infuraUrl: "https://rinkeby.infura.io/v3/b40d311f09e7410285a90de8ae1411aa",
        // ipfsPath : "https://ipfs.infura.io/ipfs/",
        publicIpfsPath : "https://ipfs.io/ipfs/",
        ipfsPath : "https://troniex-nft.infura-ipfs.io/ipfs/",
    },
    zeroAddress : "0x0000000000000000000000000000000000000000"
};

console.log('APP_MODE::', REACT_APP_MODE);

export default KEY;
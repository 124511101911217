// import config
import axios from "core/config/axios";
import { respChanges } from "./responseModify";
import moment from "moment";

export const createNFT = async (data) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/create`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getAllNft = async (
  data,
  query = { sort: "mintedAt", order: "ASC" }
) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/list`,
      params: query,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const Nftdetail = async (data, query) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/detail`,
      params: query,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const BidList = async (data, query) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/bidlist`,
      params: query,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const likeNFT = async (data) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/like`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const nftPriceChange = async (data) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/nftpricechange`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const bidCreate = async (data) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/bid/create`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const buy = async (data) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/buy`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const burn = async (data) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/nft/burn`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const updateProfile = async (data) => {
  try {
    const respData = await axios({
      method: "put",
      url: `v1/member/profile`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getuserProfile = async (data) => {
  try {
    const respData = await axios({
      method: "post",
      url: `v1/member/profile`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const siteSettings = async (data) => {
  try {
    const respData = await axios({
      method: "get",
      url: `v1/nft/siteSettings`,
      data,
    });
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getTopSellers = async (data) => {
  try {
    const respData = await axios.get(`v1/nft/topSellers`);
    return respChanges(respData.data);
  } catch (err) {
    return {
      status: "error",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

import config from "../../core/config/index";

const widgetSidebarData = [
    {
        id: 1,
        title: "Status",
        content : [
            {
                field: 'Buy Now',
                checked: 'checked'
            },
            {
                field: 'On Auctions'
            },
            {
                field: 'Has Offers'
            },
        ]
    },
    {
        id: 2,
        title: "Categories",
        content : [
            {
                field: 'Art'
            },
            {
                field: 'Music'
            },
            {
                field: 'Domain Names',
                checked: 'checked'
            },
            {
                field: 'Virtual Worlds'
            },
            {
                field: 'Trading  Cards'
            },
            {
                field: 'Collectibles',
                checked: 'checked'
            },
            {
                field: 'Sports'
            },
            {
                field: 'Utility'
            },
        ]
    },
    {
        id: 3,
        title: "Chains",
        content : [],
        //  [
        //     {
        //         field: 'Ethereum'
        //     },
        //     {
        //         field: 'Polygon'
        //     },
        //     {
        //         field: 'Klaytn'
        //     },
        // ]
    },
    {
        id: 4,
        title: "Collections",
        content : [
            {
                field: 'Abstraction'
            },
            {
                field: 'Patternlicious'
            },
            {
                field: 'Skecthify'
            },
            {
                field: 'Cartoonism'
            },
            {
                field: 'Virtuland'
            },
            {
                field: 'Papercut'
            },
        ]
    },
]

export default widgetSidebarData;
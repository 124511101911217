import axios from 'axios';

import { type } from '@testing-library/user-event/dist/type';
import config from 'core/config/index';
import { findRenderedComponentWithType } from 'react-dom/test-utils';

export const getSrcPath = (data={}) => {
    let { src, path } = data;
    try {
        if(src && typeof src !== 'string') {
            return URL.createObjectURL(src);
        } else {
            if(path == 'nft'){
                return src;
            } else if(path === 'profile' || path === 'cover') {
                path = '/author/'+path+'/'
                return config.IMG_URL + path + src;
            } else if(path === 'collection' || path === 'blockchain') {
                return config.IMG_URL + '/'+path+'/' + src;
            } else if(path === 'default') {
                return config.IMG_URL + '/'+path+'/' + src;
            }
            else {
                return src;
            }
        }
    }
    catch (err) {
        console.log('err getSrcPath : ', err);
        return src;
    }
}

export const fetchContentType = async (src) => {
    const req = await axios.head(src);
    return req.headers['content-type'];
}

export const getSrcType = async (src) => {
    const resp = await fetchContentType(src);
    const respArr = resp ? resp.split('/') : [];
    const ret = respArr[1] ? {
        mimeType: respArr[0],
        srcType: respArr[1]
    } : {};
    return ret;
}

export const getSrcTypeFromName = (src) => {
    const srcArr = src ? src.split('.') : [];
    const srcType = srcArr.length > 0 ? srcArr[srcArr.length - 1] : '';

    const imageFormat = /\.(jpg|jpeg|png|gif|webp|svg)$/;
    const videoFormat = /\.(mp4|ogg)$/;
    const audioFormat = /\.(mp3)$/;

    // opensea
    // File types supported: 
    // JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max size: 100 MB

    const mimeType = imageFormat.test(src)
    ?
        'image'
    :
        videoFormat.test(src)
        ?
            'video'
        :
        audioFormat.test(src)
        ?
            'audio'
        :
            fetchContentType(src);

    return {
        mimeType,
        srcType
    };
}
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import config from '../../core/config/';
import { getSrcPath } from '../../core/lib/fileHelper';

const OwnersTab = (props) => {

    const accountData = useSelector(state => state.account);
    const commonData = useSelector(state => state.common);

    const { item, owner={}, userSelOwner_set, buyModal_set } = props;
    const {
        blockchain,
    } = item;

    const {
        ownerInfo = {},
        availQuan = 0,
        price = 0,
        priceToken = {},
    } = owner;
    const {
        nameShow: nameShowOwner,
        address: addressOwner,
        image: profileImageOwner
    } = ownerInfo;

    const priceTokenSym = priceToken && priceToken.symbol ? priceToken.symbol : '';
    const blockchainSym = blockchain && blockchain.nativeCurrencySymbol ? blockchain.nativeCurrencySymbol : '';
    const currencySym = priceTokenSym ? priceTokenSym : blockchainSym;

    return (
        <ul className="bid-history-list">
            <li>
                <div className="content">
                    <div className="client">
                        <div className="sc-author-box style-2">
                            <div className="author-avatar">
                                <Link to="#">
                                    {
                                        profileImageOwner && profileImageOwner.profileImage &&
                                        <img src={getSrcPath({src:profileImageOwner.profileImage, path:'profile'})} alt="NFT`s" className="avatar" />
                                    }
                                </Link>
                            </div>
                            <div className="author-infor">
                                <div className="name">
                                    <h6><Link to={"/user/"+addressOwner+'/created'}>{nameShowOwner} </Link></h6>
                                </div>
                                {price === 0 && <span className="time">{availQuan} edition not for sale</span>}
                                {price > 0 && <span className="time">{availQuan} edition on sale for {price} {currencySym} each</span>}
                            </div>
                        </div>
                    </div>
                    <div className="price">
                        {accountData && accountData.checked && price > 0 && accountData.address !==  addressOwner && <button onClick={() => { userSelOwner_set(owner); buyModal_set(true)}}><span>Buy Now</span></button>}
                    </div>
                </div>
            </li>
        </ul>
    );
}

export default OwnersTab;
